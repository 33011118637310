import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { CustomFileSizePipe } from 'src/app/pipes/custom-file-size/custom-file-size.pipe';
import { FileSizeManagerService } from 'src/app/services/file-size-manager.service';
import { IShare } from 'src/app/types/group-share.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { ShareDetailsComponent } from '../share-details.component';
import { SharedDetailsService } from '../shared-details.service';

@Component({
  selector: 'app-file-size',
  templateUrl: './file-size.component.html',
  styleUrls: ['./file-size.component.scss']
})
export class FileSizeComponent extends BaseAbstractComponent implements OnInit {

  public shareDetails: IShare;
  public validations: FormGroup;
  fileSize: number = 0;
  enableFileSize: boolean = false;
  successMessage: string = '';
  isFileSizeShowError: boolean = false;
  displayError: string;
  maxFileSizeBytes: number; // highest file size(bytes) of already uploaded file in the share

  constructor(public dialogRef: MatDialogRef<ShareDetailsComponent>,
    private _sharedDetailsService: SharedDetailsService,
    private _httpApiRequestService: HttpApiRequestService,
    private formBuilder: FormBuilder,
    private _fileSizeManagerService: FileSizeManagerService,
    private _customFileSizePipe: CustomFileSizePipe) {
    super();
    this.setupSubscription();
  }

  ngOnInit() {
    this.validations = this.formBuilder.group({
      fileSize: [this.fileSize, [Validators.required]]
    });
  }

  maxFileSizeMBIsDefined(): boolean {
    return CompareUtility.isDefinedAndNotNull(this.shareDetails.maxFileSizeMB) && this.shareDetails.maxFileSizeMB > 0;
  }

  onSliderChange(value: boolean) {
    if (!value) {
      this.fileSize = null;
      this.displayError = '';
      this.isFileSizeShowError = false;
      this.updateFileSize();
    }
    this.enableFileSize = value;
    this.fileSize = 0;
  }

  addFileSize() {
    if (this.validations.get('fileSize').valid && this.validateFileSize()) {
      this.updateFileSize();
    }
  }

  private getShareSizeMB() {
    return this._customFileSizePipe.transform(this.maxFileSizeBytes);
  }

  validateFileSize(): boolean {
    this.displayError = '';
    
    // validate if value is not empty
    if (!CompareUtility.isDefinedAndNotNull(this.fileSize)) {
      this.isFileSizeShowError = true;
      this.translate.get('Settings.File_size_accordian.ErrorMessages.HigherThan0').subscribe((x: string) =>
        this.showDisplayError(x));
      return false;
    }

    // validate if value is greater than 0
    const arr = this.fileSize.toString().split('.');
    this.isFileSizeShowError = (parseInt(arr[0]) > 0 || parseInt(arr[1]) > 0) ? false : true;
    if (this.isFileSizeShowError) {
      this.translate.get('Settings.File_size_accordian.ErrorMessages.HigherThan0').subscribe((x: string) =>
        this.showDisplayError(x));
      return false;
    }

    // validate if input value is greater than highest size of uploaded file in the share
    let fileSizeBytes = this._fileSizeManagerService.formatFileSize(this.fileSize);
    if (this.maxFileSizeBytes > fileSizeBytes) {
      this.translate.get('Settings.File_size_accordian.ErrorMessages.ShareAlreadyContainsFileOfInputSize', { limit: this.getShareSizeMB()}).subscribe((x: string) =>
        this.showDisplayError(x));
      return false;
    }

    return true;
  }

  private showDisplayError(error: string) {
    this.isFileSizeShowError = true;
    this.displayError = error;
  }

  onFileSizeChanged() {
    this.validateFileSize();
  }

  updateFileSize() {
    if (this.shareDetails.maxFileSizeMB != this.fileSize) {
      this.shareDetails.maxFileSizeMB = this.fileSize;
      let model = {
        "maxFileSizeMB": this.shareDetails.maxFileSizeMB
      };
      this._httpApiRequestService.updateShareRequest(model, this.shareDetails.id).subscribe({
        next: (result) => {
          if (this.enableFileSize) {
            this.translate.get('Settings.File_size_accordian.FileSizeAddedMessage').subscribe((x: string) => this.successMessage = x);
            timer(3000).subscribe(n => this.successMessage = '');
          }
        },
        error: (error: any) => {},
        complete: () => { },
      });
    }
  }

  setupSubscription() {
    const showDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: (x: IShare) => {
        this.shareDetails = x;
        if (this.maxFileSizeMBIsDefined()) {
          this.enableFileSize = true;
          this.fileSize = this.shareDetails.maxFileSizeMB;
        }
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(showDetails);

    const shareSize = this._sharedDetailsService.onUpdatedMaxFileSize({
      next: (x: number) => {
        this.maxFileSizeBytes = x;
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(shareSize);
  }

  public omit_special_char(event: any) {
    return !(event.key === '-' || event.key === '+');
  }
}
