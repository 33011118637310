import { NgModule } from '@angular/core';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


export const httpLoaderFactory = (httpClient: HttpClient) => {
  const config$ = httpClient.get<any>( environment.basePathAuthorization + "/token").pipe(
    map((customConfig: any) => {
      return {
        authority: environment.auth0.baseDomain.concat('/op'),
        redirectUrl: window.location.origin + "/callback",
        postLogoutRedirectUri: window.location.origin + "/postlogout",
        clientId: environment.auth0.clientId,
        state: environment.auth0.state,
        scope: 'openid profile vo digitaalvlaanderen_smft',
        responseType: 'code',
        autoUserInfo: false,
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        historyCleanupOff: false,
        startCheckSession: false,
        triggerAuthorizationResultEvent: true,
        customParamsRefreshTokenRequest: {
          scope: 'openid profile',
        },
        customParamsCodeRequest: {
          client_assertion : customConfig.token,
          client_assertion_type: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',

        },
      };
    })
  );

  return new StsConfigHttpLoader(config$);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
    exports: [AuthModule],
})
export class AuthConfigACMModule {}
