export class ArrayOperationsUtility {

    public static removeDuplicates(arr: any[]) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    // Return true if Array1 includes any item of Array2
    public static isArray1IncludesArray2(arr1: string[], arr2: string[]): boolean {
       return arr1.some(x => arr2.includes(x));
    }
}
