import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  @Input() inactiveLable: string;
  @Input() activeLable: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Output() sliderChange: EventEmitter<boolean>;

  constructor() {
    this.sliderChange = new EventEmitter<boolean>();
  }

  toggleSlider() {
    this.checked = !this.checked;
    this.sliderChange.emit(this.checked);
  }

  onSliderChanged() {
    this.sliderChange.emit(this.checked);
  }
}
