import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, fromEvent, map } from 'rxjs';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { FilterOption } from 'src/app/common-ui/filter/model/filter-options.model';
import { FilterOptionsService } from 'src/app/common-ui/filter/services/filter-options.service';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { HttpProcessorService } from 'src/app/http/http-processor.service';
import { UpdateShareWithParticipantsRequest } from 'src/app/http/request-urls/shareAndFileRequestUrl';
import { ShareService } from 'src/app/myshare/services/share.service';
import { UserLoginService } from 'src/app/services/login.service';
import { SftpUrlService } from 'src/app/services/sftp-url.service';
import { TokenExchangeService } from 'src/app/services/token-exchange.service';
import { IShare, ShareType } from 'src/app/types/group-share.model';
import { ContactType, IContact, IDisplayRole, IParticipant, Role } from 'src/app/types/participant.model';
import { ArrayOperationsUtility } from 'src/app/utilities/arrayOperations.utility';
import { HttpApiNotificationService } from '../notifications/services/http-api-notification-preferences.service';
import { SharedDetailsService } from '../shared-details.service';
import { TabManagerService, Tabs } from 'src/app/services/tab-manager.service';

@Component({
  selector: 'app-shared-participants',
  templateUrl: './shared-participants.component.html',
  styleUrls: ['./shared-participants.component.scss'],
  providers: [SftpUrlService]
})
export class SharedParticipantsComponent extends BaseAbstractComponent implements AfterViewInit {

  @ViewChild(MatMenuTrigger) participantsMenu: MatMenuTrigger;
  @ViewChild('searchField') searchField: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('search') filterElementRef: ElementRef;

  shareDetails: IShare;
  participantid: string;
  searchedParticipants: IParticipant[] = [];
  width: number = parent.innerWidth;
  displayedColumns = ['searchIcon', 'searchName', 'searchEmailAddress', 'searchOrganization'];
  participantsColumns = ['icon', 'displayName', 'emails', 'organization', 'role', 'options'];
  dataSource = new MatTableDataSource<IParticipant>([]);
  roles: IDisplayRole[] = [];
  systemRoles: IDisplayRole[] = [];
  disableEditRole: boolean = false;
  roleFilterOptions: FilterOption[] = [];
  typeFilterOptions: FilterOption[] = [];
  searchKey: string = '';
  displayError: string = '';
  disableNameField: boolean = false;

  constructor(private _shareService: ShareService,
    private _sharedDetailsService: SharedDetailsService,
    private httpProcessorService: HttpProcessorService,
    private tokenExchangeService: TokenExchangeService,
    private _httpApiRequestService: HttpApiRequestService,
    private _tabManagerService: TabManagerService,
    private _loginService: UserLoginService,
    private sftpUrlService: SftpUrlService,
    private _filterOptionsService: FilterOptionsService,
    private clipboard: Clipboard,
    private _httpApiNotificationService: HttpApiNotificationService
  ) {
    super();
    this.getRoles();
    this.setupSubscription();
    this.disableEditRole = false;
  }

  ngAfterViewInit() {
    this.participantsMenu.menu.overlayPanelClass = 'paticipant-menu-panel';

    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    // wait .5s between keyups to emit current value
    // Filter participants
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this._httpApiRequestService.loadParticipants(this.shareDetails.id, result.trim()).subscribe({
            next: (result: IParticipant[]) => {
              this.dataSource.data = result;
            },
            error: (error) => { this.handleError(error); },
            complete: () => { }
          });
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      });
  }

  addParticipant(participant: IContact) {
    let request = new UpdateShareWithParticipantsRequest(this.shareDetails.id, participant.participantId, participant.entityNameOfParticipant, participant.entityIdOfParticipant, participant.additionalContact, participant.workRelationId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.dataSource.data = [];
        this._sharedDetailsService.loadParticipants(this.shareDetails.id);
        this._sharedDetailsService.loadExternalContacts(this.shareDetails.id);
        this.resetControls();
        this._httpApiRequestService.refreshActionLogs(this.shareDetails.id);
        this.updateDefaultNotifications(this.shareDetails.id, participant.participantId, Role.READER, participant.workRelationId);
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    })
  }

  public onParticipantSelected(participant: IContact) {
    this.resetControls();
    if (this.validateParticipant(participant)) {
      this.addParticipant(participant);
    }
  }

  public systemOrAdditionalContact(participant: IParticipant): boolean {
    if (participant.participantType == ContactType.SYSTEM) {
      return true;
    } else {
      return participant.additionalContact;
    }
  }

  private validateParticipant(participant: IContact): boolean {
    if (this.isParticipantAlreadyExist(participant)) {
      this.displayError = this.translate.instant('Participant.ErrorMessages.ParticipantAlreadyExist');
      return false;
    }
    return true;
  }

  private isParticipantAlreadyExist(participant: IContact): boolean {
    let result: boolean = false;
    const data: IParticipant[] = this.dataSource.data;
    for (let i = 0; i < data.length; i++) {
      if (data[i].participantId === participant.participantId && data[i].workRelationId === participant.workRelationId) {
        result = true;
      }
    }
    return result;
  }

  setupSubscription() {
    const showDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: x => {
        this.shareDetails = x;
        this.dataSource.data = [];
        this._sharedDetailsService.loadParticipants(x.id);
        this._sharedDetailsService.loadExternalContacts(x.id);
        if (!this._loginService.isLoggedInUserIsAdmin) {
          this.disableNameField = this._shareService.isCurrentUserNotAnOwnerOfAShare(this.shareDetails);
        }
        this.disableNameField = this.shareDetails.shareType == ShareType.P2P ? true : false;
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(showDetails);

    const participantsSub = this._sharedDetailsService.onParticipantLoaded({
      next: x => {
        this.processParticipants(x).forEach(participant => {
          this.dataSource.data.push(participant);
        })
        this.dataSource.data.sort(this.compare);
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.initializeFilterActions();
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(participantsSub);

    this._sharedDetailsService.onExternalContactsLoaded({
      next: x => {
        this.processExternals(x).forEach(participant => {
          this.dataSource.data.push(participant);
        });
        this.dataSource.data.sort(this.compare);
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.initializeFilterActions();
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    })
  }

  processParticipants(participants: IParticipant[]): IParticipant[] {
    let disableTrashIcon = false;
    participants.forEach(participant => {
      //Identify share creator
      if (participant.participantId === this.shareDetails.createdByUserId && participant.additionalContact === false) {
        participant.rightType = Role.SHARECREATOR;
      }
      //Identify logged in participant
      if (participant.participantId === this._loginService.loggedInUserDetails.id && participant.workRelationId === this._loginService.workRelationId) {
        participant.isLoggedInUser = true;
      }
      if (participant.isLoggedInUser && this.isContributorOrReader(participant) && !this._loginService.isLoggedInUserIsAdmin) {
        disableTrashIcon = true;
        this.disableEditRole = true;
      }
      if (participant.participantType === "SYSTEM") {
        participant.sftpUrl = this.sftpUrlService.createSftpUrl(this.shareDetails.id, participant.participantId)
      }
      participant.externalContact = false;
    });
    if (disableTrashIcon) {
      participants.forEach(participant => {
        participant.disableTrashIcon = true;
        if (participant.isLoggedInUser && this.isContributorOrReader(participant) && !this._loginService.isLoggedInUserIsAdmin) {
          participant.disableTrashIcon = false;
        }
      });
    }
    return participants;
  }

  processExternals(externals: IParticipant[]): IParticipant[] {
    externals.forEach(external => {
      let externalStatus;
      this.translate.get('Common.External').subscribe(x => externalStatus = x);
      external.externalContact = true;
      external.entityId = externalStatus;
      external.entityName = externalStatus;
      external.disableTrashIcon = true;
    })
    return externals;
  }

  resetControls() {
    this.participantid = '';
    this.displayError = '';
  }

  previousButtonClick() {
    this._tabManagerService.updateTab(Tabs.FilesTab);
  }

  configureSettings() {
    this._tabManagerService.updateTab(Tabs.SettingsTab);
  }

  isAddWorkflow(): boolean {
    return this._sharedDetailsService.isAddWorkflow;
  }

  keyup(event: any) {
    if (event.target.value.length > 2) {
      this.width = this.searchField._elementRef.nativeElement.offsetWidth - 2;
      this.searchParticipants(event.target.value);
    } else {
      this.participantsMenu.closeMenu();
    }
  }

  nameClicked(e: Event) {
    this.participantsMenu.closeMenu();
  }

  searchParticipants(field: string) {
    this._httpApiRequestService.searchParticipants(this._loginService.entityId, field.trim()).subscribe({
      next: (result: IParticipant[]) => {
        this.searchedParticipants = [];
        if (result.length > 20) {
          result.forEach(element => {
            if (this.searchedParticipants.length < 20) {
              this.searchedParticipants.push(element);
            }
          });
        } else {
          this.searchedParticipants = result;
        }
        this.participantsMenu.openMenu();
      },
      error: (error) => { this.handleError(error); }
    });
  }

  getRole(value: Role): string {
    let role: string;
    switch (value) {
      case Role.SHARECREATOR:
        this.translate.get('Participant.OWNER').subscribe((x) => role = x);
        break;
      case Role.OWNER:
        this.translate.get('Participant.OWNER').subscribe((x) => role = x);
        break;
      case Role.CONTRIBUTOR:
        this.translate.get('Participant.CONTRIBUTOR').subscribe((x) => role = x);
        break;
      case Role.READER:
        this.translate.get('Participant.READER').subscribe((x) => role = x);
        break;
      default:
        break;
    }
    return role;
  }

  getRoles() {
    this.translate.get('Participant.OWNER').subscribe((x) => this.roles.push({ id: Role.OWNER, displayValue: x }));
    this.translate.get('Participant.CONTRIBUTOR').subscribe((x) => this.roles.push({ id: Role.CONTRIBUTOR, displayValue: x }));
    this.translate.get('Participant.READER').subscribe((x) => this.roles.push({ id: Role.READER, displayValue: x }));

    this.translate.get('Participant.CONTRIBUTOR').subscribe((x) => this.systemRoles.push({ id: Role.CONTRIBUTOR, displayValue: x }));
    this.translate.get('Participant.READER').subscribe((x) => this.systemRoles.push({ id: Role.READER, displayValue: x }));
  }

  isAllowedToChangeRole(participant: IParticipant): boolean {
    let value = participant.rightType;
    if (participant.externalContact) {
      return false;
    }
    // when logged in user is also creator of the share
    if (participant.participantId === this.shareDetails.createdByUserId && participant.participantId === this._loginService.loggedInUserDetails.id && participant.workRelationId === this._loginService.workRelationId) {
      return false;
    }
    if (this._loginService.isLoggedInUserIsAdmin) {
      return true;
    }
    if (value != Role.OWNER && value != Role.SHARECREATOR) {
      return true;
    } else {
      let rights = this.dataSource.data.map(x => x.rightType);
      let assignedOwners = rights.filter(x => x == Role.OWNER || x == Role.SHARECREATOR);
      if (assignedOwners.length > 1) {
        return true;
      }
      return false;
    }
  }

  updateRole(role: string, participant: IParticipant) {
    this._httpApiRequestService.updateRole(this.shareDetails.id, participant.participantId, role, participant.workRelationId).subscribe({
      next: (result: any) => {
        this.dataSource.data = [];
        this._sharedDetailsService.loadParticipants(this.shareDetails.id);
        this._sharedDetailsService.loadExternalContacts(this.shareDetails.id);
        this._httpApiNotificationService.deleteParticipantPreferences(this.shareDetails.id, participant.participantId, participant.workRelationId).subscribe({
          next: (result: any) => {
            this.updateDefaultNotifications(this.shareDetails.id, participant.participantId, <Role>role, participant.workRelationId);
            this._httpApiRequestService.refreshActionLogs(this.shareDetails.id);
          },
          error: (error) => { this.handleError(error); }
        });
      },
      error: (error) => { this.handleError(error); }
    });
  }

  compare(a: IParticipant, b: IParticipant) {
    let roles = [Role.SHARECREATOR, Role.OWNER, Role.CONTRIBUTOR, Role.READER];
    if (roles.findIndex(x => x === a.rightType) < roles.findIndex(x => x === b.rightType)) {
      return -1;
    }
    if (roles.findIndex(x => x === a.rightType) > roles.findIndex(x => x === b.rightType)) {
      return 1;
    }
    return 0;
  }

  deleteParticipant(e: IParticipant) {
    this._httpApiRequestService.deleteParticipantFromShare(this.shareDetails.id, e.participantId, e.workRelationId).subscribe({
      next: () => {
        this.dataSource.data = [];
        this._sharedDetailsService.loadParticipants(this.shareDetails.id);
        this._sharedDetailsService.loadExternalContacts(this.shareDetails.id);
        this._httpApiRequestService.refreshActionLogs(this.shareDetails.id);
        this._httpApiNotificationService.deleteParticipantPreferences(this.shareDetails.id, e.participantId, e.workRelationId);
      },
      error: (error) => { },
      complete: () => { }
    });
  }

  isContributorOrReader(participant: IParticipant): boolean {
    let rightType = participant.rightType;
    return rightType === Role.CONTRIBUTOR || rightType === Role.READER;
  }

  copySftpUrl(participant: IParticipant) {
    this.clipboard.copy(participant.sftpUrl);
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
  }

  isParticipantSystem(participant: IParticipant) {
    return participant.participantType === "SYSTEM";
  }

  public selectAll() {
    this.dataSource.filter = 'all';
  }

  public filterActions(filterOptions: FilterOption[]) {
    this.dataSource.filter = 'Filter';
  }

  private initializeFilterActions() {
    this.roleFilterOptions = this.getRoleFilterOptions(this.dataSource.data.map(x => x.rightType));
    this.typeFilterOptions = this._filterOptionsService.getTypeFilterOptions(this.dataSource.data.map(x => x.participantType))
  }

  private getRoleFilterOptions(data: Role[]): FilterOption[] {
    let options: FilterOption[] = [];
    data = ArrayOperationsUtility.removeDuplicates(data);
    data.forEach(x => {
      if (x != Role.SHARECREATOR && x != Role.OWNER) {
        options.push({ title: this.getRole(x), value: x, activated: false })
      }
    });
    options.push({ title: this.getRole(Role.OWNER), value: Role.OWNER, activated: false })
    return options;
  }

  private getFilterPredicate() {
    return (row: IParticipant, filters: string) => {
      const matchFilter = [];

      let role = row.rightType;
      if (row.rightType != Role.SHARECREATOR) {
        role = row.rightType;
      } else {
        role = Role.OWNER;
      }
      const type = row.participantType

      const roleFilterOptions = this.roleFilterOptions.filter(x => x.activated);
      const typeFilterOptions = this.typeFilterOptions.filter(x => x.activated)

      if (roleFilterOptions.length === 0 && typeFilterOptions.length === 0) {
        return true;
      }

      let customFilterRL = false;
      let customFilterTY = false;

      if (roleFilterOptions.length > 0) {
        customFilterRL = roleFilterOptions.some(filter => filter.value === role);
        matchFilter.push(customFilterRL);
      }
      if (typeFilterOptions.length > 0) {
        customFilterTY = typeFilterOptions.some(filter => filter.value === type)
        matchFilter.push(customFilterTY)
      }
      return matchFilter.every(Boolean);
    }
  }

  @HostListener("click")
  clicked() {
    this.displayError = '';
  }

  externalContactName(element: IParticipant): string {
    return element.displayName + ' (' + element.participantId + ')';
  }

  private updateDefaultNotifications(shareId: string, userId: string, role: Role, workRelationId: string) {
    this._httpApiNotificationService.updateDefaultNotificationPreferences(shareId, userId, role, workRelationId).subscribe({
      next: (result) => { },
      error: (error: any) => { this.handleError(error); },
      complete: () => { },
    });
  }
}
