<div class="wrapper">
    <img class="closeDialogButton" (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
      alt="{{'Images.DialogCloseButton' | translate}}">
    <div class="outerContainer">
      <div class="titleContainer">{{'Dialogs.RemoveFileTypeDialog.Title'| translate}}</div>
      <div class="contentContainer">
        <mat-label>{{'Dialogs.RemoveFileTypeDialog.Message'| translate}}</mat-label>
      </div>
      <div mat-dialog-actions class="dialogActionsContainer">
        <button mat-dialog-close cdkFocusInitial class="closeButton dialogButton">
            {{'Common.Cancel'| translate}}</button>
        <button [mat-dialog-close]="true" cdkFocusInitial class="activeButton dialogButton">
          {{'Common.Remove_btn'| translate}}</button>
      </div>
    </div>
  </div>