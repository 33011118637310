<input type="file" class="file-input" (change)="fileSelected($event)" #fileUpload multiple>
<input type="file" class="file-input" (change)="folderSelected($event)" #folderUpload webkitdirectory multiple>
<div class="optionWrapper">
    <div class="uploadOptionMenu" (click)="fileUpload.click()">
        <img src="assets/images/fileIcon.png" class="fileIcon">
        <span>{{'Common.File' | translate}}</span>
    </div>
    <div>
        <div class="uploadOptionMenu" (click)="folderUpload.click()">
            <img src="assets/images/folderIcon.png" class="folderIcon">
            <span>{{'Common.Folder' | translate}}</span>
        </div>
    </div>
</div>
