<div class="wrapper">
    <div class="container">
        {{ 'Settings.Expiry_date_accordian.message'| translate }}
        <app-slider class="sliderContainer" inactiveLable="{{'Common.No'| translate}}"
            activeLable="{{'Common.Yes'| translate}}" [checked]="active" (sliderChange)="onSliderChange($event)"
            matTooltip="{{ getTooltipMessage() }}" [disabled]="disableSlider()">
        </app-slider>
    </div>
    <form class="calenderBox">
        <mat-form-field appearance="outline" class="date-picker-input" [ngClass]="disableCalender() ? 'disableInput': ''">
            <input placeholder="{{'Settings.Expiry_date_accordian.Datepicker_placeholder'| translate }}"
                matInput [min]="minDate" [max]="maxExpiryDate" [matDatepicker]="picker" [value]="date.value" (click)="picker.open()"
                (dateInput)="addEvent($event)" />
            <mat-datepicker #picker></mat-datepicker>
            <span style="cursor: pointer;">
                <img src="assets/images/calendarIcon.png" alt="{{'Images.calendarIcon'|translate}}"
                    (click)="picker.open()">
            </span>
        </mat-form-field>
    </form>
</div>