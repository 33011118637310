import { Injectable } from '@angular/core';
import { Observer, Subject, Subscription } from 'rxjs';
import { HttpProcessorService } from '../http/http-processor.service';
import { AddAdditionalContactRequest, AddParticipantRequest, DeleteAdditionalContactRequest, DeleteParticipantRequest, GetContactRequest, GetContactsRequest, GetSystemDetailRequest, SearchContactsRequest, SearchOrganizationsRequest, UpdateParticipantRequest } from '../http/request-urls/participantRequestUrl';
import { UserLoginService } from '../services/login.service';
import { TokenExchangeService } from '../services/token-exchange.service';
import { ContactType, IContact, IExternalContact } from '../types/participant.model';

@Injectable({
  providedIn: 'root'
})
export class HttpContactApiService {

  private refreshUsers$: Subject<IContact[]>;
  private refreshSystems$: Subject<IContact[]>;
  entityId: string;

  constructor(private tokenExchangeService: TokenExchangeService,
    private _loginService: UserLoginService,
    private httpProcessorService: HttpProcessorService) {
    this.refreshUsers$ = new Subject<IContact[]>();
    this.refreshSystems$ = new Subject<IContact[]>();
    this.entityId = this._loginService.entityId;
  }

  onUsersRefreshed(observe: Observer<IContact[]>): Subscription {
    return this.refreshUsers$.subscribe(observe);
  }

  onSystemsRefreshed(observe: Observer<IContact[]>): Subscription {
    return this.refreshSystems$.subscribe(observe);
  }

  // contact api's
  addParticipants(data: any): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new AddParticipantRequest(data, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => {
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  refreshUsers() {
    let request = new GetContactsRequest(this.tokenExchangeService, 0, '',this.entityId, ContactType.USER );
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.refreshUsers$.next(result);
      },
      error: (error) => {
        this.refreshUsers$.error(error);
      },
      complete: () => {}
    })
  }

  refreshSystems() {
    let request = new GetContactsRequest(this.tokenExchangeService, 0, '',this.entityId, ContactType.SYSTEM );
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.refreshSystems$.next(result);
      },
      error: (error) => {
        this.refreshSystems$.error(error);
      },
      complete: () => {}
    })
  }


  loadNextUsers(pageNumber: number, name: string) {
    let obs$ = new Subject<IContact[]>();
    let request = new GetContactsRequest(this.tokenExchangeService, pageNumber, name, this.entityId, ContactType.USER);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => {
        this.refreshUsers$.error(error);
      },
      complete: () => {}
    });
    return obs$;
  }

  loadNextSystems(pageNumber: number, filter: string) {
    let obs$ = new Subject<IContact[]>();
    let request = new GetContactsRequest(this.tokenExchangeService, pageNumber, filter, this.entityId, ContactType.SYSTEM);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => {
        this.refreshSystems$.error(error);
      },
      complete: () => {}
    });
    return obs$;
  }

  deleteSystem(id: string, workRelationId:string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new DeleteParticipantRequest(id, workRelationId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => {
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  updateParticipant(id: string, data: any): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new UpdateParticipantRequest(id, data, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => {
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  getSystemDetails(participantId: string) {
    let obs$ = new Subject<any>();
    let request = new GetSystemDetailRequest(participantId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => { 
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  searchContacts(searchKey: string, entityName: string) {
    let obs$ = new Subject<any>();
    let request = new SearchContactsRequest(this.tokenExchangeService, searchKey, entityName);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => { 
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  searchOrganizations(value: string) {
    let obs$ = new Subject<any>();
    let request = new SearchOrganizationsRequest(value, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => { 
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  addUserContact(value: any) {
    let obs$ = new Subject<any>();
    let request = new AddAdditionalContactRequest(value, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => { 
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  deleteAdditionalContact(participantId: string, workRelationId: string) {
    let obs$ = new Subject<any>();
    let request = new DeleteAdditionalContactRequest(participantId, workRelationId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => { 
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  getContact(participantId: string, workRelationId: string) {
    let obs$ = new Subject<IContact>();
    let request = new GetContactRequest(this.tokenExchangeService, participantId, workRelationId);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => { 
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }
}
