import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../types/service-locator';
import { UserLoginService } from './login.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { RemovedFromShareDialogComponent } from '../dialogs/removed-from-share-dialog/removed-from-share-dialog.component';
import { DialogModel, ErrorDialogModel } from '../types/dialog-model';
import { DialogBoxComponent } from '../dialogs/dialog-box/dialog-box.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  public translate: TranslateService;
  public dialog: MatDialog;

  constructor(private loginService: UserLoginService, private readonly injector: Injector) {
    this.dialog = ServiceLocator.injector.get(MatDialog);
  }

  public handleError(error: HttpErrorResponse) {
    // Hanle error codes here
    if (error.status === HttpStatusCode.ExpectationFailed) {
      this.loginService.navigateToNoRightsPage();
    } else if (error.status === HttpStatusCode.Unauthorized) {
      this.loginService.logout();
    } else if (error.status === HttpStatusCode.Forbidden) {
      this.showUserNotAuthorizedDialog();
    }
  }

  private showUserNotAuthorizedDialog(): void {
    this.translate = this.injector.get(TranslateService);
    let data = new DialogModel();
    this.translate.get('Dialogs.UserNotAuthorizedDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.UserNotAuthorizedDialog.Message').subscribe((x: string) => data.content = x);
    this.dialog.open(RemovedFromShareDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '225px',
      width: '450px',
      disableClose: true
    });
  }

  public showDefaultErrorDialog(error: HttpErrorResponse) {
    this.translate = this.injector.get(TranslateService);
    let data = new ErrorDialogModel();
    let date = new Date();
    this.translate.get('Dialogs.ErrorDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.ErrorDialog.Message').subscribe((x: string) => data.content = x);
    data.user = this.loginService.loggedInUserDetails.name.givenName + ' ' + this.loginService.loggedInUserDetails.name.familyName;
    data.application = 'SMFT';
    data.code = error.status == 0 ? 504 : error.status;
    data.time = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    this.dialog.open(DialogBoxComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '375px',
      width: '510px',
      disableClose: true
    });
  }
}
