import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenExchangeService } from '../services/token-exchange.service';

@Component({
  selector: 'app-invite-code',
  templateUrl: './invite-code.component.html'
})
export class InviteCodeComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private _tokenExchangeService: TokenExchangeService) {

  }

  ngOnInit(): void {
    // save token to session storage
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    this._tokenExchangeService.storeInviteToken(token);
    this.router.navigateByUrl('/shares');
  }

}
