<div class="wrapper">
    <img class="closeDialogButton" mat-icon-button (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
        alt="{{'Images.DialogCloseButton' | translate}}">
    <mat-tab-group [disableRipple]="true" animationDuration="0ms" [(selectedIndex)]="selectedIndex">
        <mat-tab label="{{'Common.System' | translate}}">
            <app-system></app-system>
        </mat-tab>
        <mat-tab label="{{'Contacts.Details.TabTitle' | translate}}">
            <app-details></app-details>
        </mat-tab>
    </mat-tab-group>
</div>