import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BaseAbstractComponent } from '../base-abstract/base-abstract.component';
import { UserLoginService } from '../services/login.service';
import { TabManagerService, Tabs } from '../services/tab-manager.service';
import { SharedDetailsService } from '../share-details/shared-details.service';
import { IShare, IShareWorkflow, Workflow } from '../types/group-share.model';
import { Role } from '../types/participant.model';
import { UserRole } from '../types/user-roles.model';

@Component({
  selector: 'app-share-details',
  templateUrl: './share-details.component.html',
  styleUrls: ['./share-details.component.scss']
})
export class ShareDetailsComponent extends BaseAbstractComponent implements AfterViewInit {

  shareName: string;
  shareModel: IShare;
  selectedIndex: number;
  uploadingFlag: boolean = false;
  isGuestUser: boolean;

  constructor(_dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private shareWorkflow: IShareWorkflow,
    public dialogRef: MatDialogRef<ShareDetailsComponent>,
    private _sharedDetailsService: SharedDetailsService,
    private _loginService: UserLoginService,
    private _tabManagerService: TabManagerService) {
    super();
    this._sharedDetailsService.isAddWorkflow = this.shareWorkflow.workFlow === Workflow.ADD;
    this._sharedDetailsService.fileToUpload = this.shareWorkflow.files;
    this.selectedIndex = this.shareWorkflow.selectedTabIndex;
    this.initializeShareModel(shareWorkflow.share);
    this.isGuestUser = (this._loginService.loggedInUserRole() === UserRole.GUESTUSER) || (this._loginService.loggedInUserRole() === UserRole.EXTERNAL_USER);
  }

  initializeShareModel(share: IShare) {
    this.shareModel = share;
    this.shareName = share.shareName;
  }

  ngAfterViewInit() {
    this._sharedDetailsService.updateSharedDetails(this.shareModel);
    this.setupSubscription();
  }
  
  private setupSubscription() {
    const tabSub = this._tabManagerService.onTabUpdated({
      next: (x: Tabs) => { this.selectedIndex = x },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
    this.subscriptions.push(tabSub);

    const shareNameChangedSubscription = this._sharedDetailsService.onShareNameUpdated({
      next: (x) => {
        this.shareName = x;
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(shareNameChangedSubscription);
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  isCurrentUserAnOwnerOfAShareOrAdmin(value: IShare): boolean {
    return value.rightTypeOfCurrentUser === Role.OWNER || this._loginService.isLoggedInUserIsAdmin;
  }

  public isEditWorkflow(): boolean {
    return !this._sharedDetailsService.isAddWorkflow;
  }

  selectedTabChange(event: MatTabChangeEvent) {
    this._sharedDetailsService.updateTabChnaged(event.index);
  }
}
