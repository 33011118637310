<div class="wrapper">
  <img class="closeDialogButton" (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
    alt="{{'Images.DialogCloseButton' | translate}}">
  <div class="outerContainer">
    <div class="contentWrapper">
      <div class="titleContainer">{{data.title}}</div>
      <div class="contentContainer">
        <mat-label>{{data.content}}</mat-label>
      </div>
      <div class="shareNamesContainer" *ngIf="data.shares?.length > 0; else elseBlock">
        <div *ngFor="let name of data.shares">
          <li>{{name}}</li>
        </div>
      </div>
      <ng-template #elseBlock>
        <div class="noActiveSharesContainer">
          <li>{{'Dialogs.DeleteSystemDialog.NoActiveShares'| translate}}</li>
        </div>
      </ng-template>
    </div>
    <div mat-dialog-actions class="dialogActionsContainer">
      <button [mat-dialog-close]="true" cdkFocusInitial class="activeButton deleteButton">
        {{'Dialogs.DeleteButton'| translate}}</button>
    </div>
  </div>
</div>