<div class="quickShareWrapper">
    <label class="shareFilesLable">{{'Sidebar.Select_files' | translate}}</label>
    <app-transfer></app-transfer>
    <!-- Upload files area end -->
    <label class="shareWithLable">{{'Sidebar.Share_lable'|translate}}</label>
    <form class="inputBox">
        <mat-form-field #searchField appearance="fill">
            <input matInput type="text" autocomplete="off" [(ngModel)]="this.shareName" name="first"
                placeholder="{{'Sidebar.Share_placeholder'|translate}}" maxlength="100" />
        </mat-form-field>
    </form>
    <div class="shareFilesbtnContainer" [ngClass]="!isNameValid() ? 'disableClass': ''">
        <button class="shareBtns" (click)="shareFiles()">
            <img id="attachmentIcon" src="assets/images/attachment.png" alt="{{'Images.AttachmentIcon'|translate}}">
            <label>{{'Sidebar.Create_share_btn'|translate}}</label>
        </button>
    </div>
</div>