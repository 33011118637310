import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { SelectOptions } from 'src/app/common-ui/single-select/model/single-select.model';
import { PersonDataService } from 'src/app/services/person-data.service';
import { AddPersonDialogModel } from 'src/app/types/dialog-model';
import { Contact } from 'src/app/types/participant.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.scss'],
  providers: [PersonDataService]
})
export class AddPersonDialogComponent extends BaseAbstractComponent {

  public languageOptions: SelectOptions[] = [];

  constructor(public dialogRef: MatDialogRef<AddPersonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddPersonDialogModel,
    public _personService: PersonDataService) {
    super();
    this._personService.initialiseData(data);
    this.initializeLanguages();
  }

  ngOnInit() {
    this._personService.initialiseValidators();
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  public validateDisplayName() {
    return this._personService.personForm.get('name').hasError('required') && (this._personService.personForm.controls['name'].touched || this._personService.personForm.controls['name'].dirty);
  }

  public validateEmail() {
    return (this._personService.personForm.get('emailAddress').hasError('required') || 
    this._personService.personForm.get('emailAddress').hasError('userExists') ||
    this._personService.personForm.get('emailAddress').hasError('email')) && 
    (this._personService.personForm.controls['emailAddress'].touched || this._personService.personForm.controls['emailAddress'].dirty);
  }

  public validateLanguage() {
    return this._personService.personForm.get('languageCode').hasError('required') && (this._personService.personForm.controls['languageCode'].touched || this._personService.personForm.controls['languageCode'].dirty);
  }

  private initializeLanguages() {
    environment.locale.map(x => x.name).forEach(x => {
      this.languageOptions.push({ title: x, value: x });
    });
  }

  public savePersonDetails() {
    this._personService.savePersonDetails().subscribe({
      next: (result) => {
        if (result) {
          let contact = new Contact();
          contact.displayName = result.name;
          contact.email = result.email;
          contact.participantId = result.externalContactId;
          contact.externalContact = true;
          this.dialogRef.close(contact);
        }
      },
      error: (err) => {
        this.handleError(err);
      }
    })
  }
}
