import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenExchangeService } from '../../services/token-exchange.service';

export enum requestType {
    Get,
    Post,
    Put,
    Delete
}

export interface IRequest {
    url: string;
    type: requestType;
    body: any;
    options: Object;
    headers: HttpHeaders;
    getUrl(): string;
    processRequest(): void;
    processResponse(result: Observable<any>): Observable<any>;
}

export class Request implements IRequest {
    url: string;
    body: any;
    options: Object;
    type: requestType;
    headers: HttpHeaders;

    constructor(url: string, tokenExchangeService: TokenExchangeService) {
        this.url = url;
        this.headers = new HttpHeaders()
            .set('Authorization', 'Bearer ' + tokenExchangeService.getJwt() + ";" + tokenExchangeService.getAccessToken());
    }
    processResponse(result: Observable<any>): Observable<any> {
        return result;
    }
    getUrl(): string {
        return this.url;
    }
    processRequest(): void {
    }
}

export class AbstractRequest extends Request {
    constructor(tokenExchangeService: TokenExchangeService) {
        super(environment.basePath, tokenExchangeService);
    }
}

export class ParticipantAbstractRequest extends Request {
    constructor(tokenExchangeService: TokenExchangeService) {
        super(environment.baseParticipantPath, tokenExchangeService);
    }
}

export class AuthorizationAbstractRequest extends Request {
    constructor(tokenExchangeService: TokenExchangeService) {
        super(environment.basePathAuthorization, tokenExchangeService);
    }
}

export class NotificationAbstractRequest extends Request {
    constructor(tokenExchangeService: TokenExchangeService) {
        super(environment.baseNotificationPath, tokenExchangeService);
    }
}

export class ExternalsAbstractRequest extends Request {
    constructor(tokenExchangeService: TokenExchangeService) {
        super(environment.baseExternalsPath, tokenExchangeService)
    }
}
