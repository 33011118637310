import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseAbstractComponent } from '../base-abstract/base-abstract.component';
import { ContactOperation, ISystem } from '../types/participant.model';
import { AddContactComponent } from './add-contact/add-contact.component';
import { HttpContactApiService } from './http-contact-api.service';

@Injectable()
export class SystemDataService extends BaseAbstractComponent implements OnDestroy {

  public systemData: ISystem;
  public systemForm: FormGroup;

  constructor(private _httpContactApiService: HttpContactApiService,
    public dialogRef: MatDialogRef<AddContactComponent>,
    private formBuilder: FormBuilder) {
    super();
  }

  initialiseValidators() {
    this.systemForm = this.formBuilder.group({
      systemName: [
        this.systemData.systemName,
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ],
      sshKey: [
        this.systemData.sshKey,
        {
          validators: [Validators.required, this.keyFormatValidator()],
          updateOn: 'blur'
        }
      ],
      description: [
        this.systemData.description,
        {
          validators: [Validators.maxLength(255)]
        }
      ],
      personName: [
        this.systemData.contactPersonName,
        {
          validators: [Validators.required],
        }
      ],
      personEmail: [
        this.systemData.contactPersonEmail,
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ]
    });
  }

  initialiseData(data: ISystem) {
    this.systemData = data;
  }

  getSystemData(): ISystem {
    let data = this.systemData;
    data.contactperson = { "name": this.systemData.contactPersonName, "email": this.systemData.contactPersonEmail }
    return data;
  }

  saveSystemDetails() {
    this.systemForm.markAllAsTouched();
    if (this.systemForm.valid) {
      switch (this.systemData.systemOperation) {
        case ContactOperation.Add:
          this.addParticipant();
          break;
        case ContactOperation.edit:
          this.updateParticipant();
          break;
        default:
          break;
      }
    }
  }

  addParticipant() {
    this._httpContactApiService.addParticipants(this.getSystemData()).subscribe({
      next: (result: any) => {
        this.dialogRef.close(result);
      },
      error: (error) => { this.handleError(error); }
    });
  }

  updateParticipant() {
    this._httpContactApiService.updateParticipant(this.systemData.participantId, this.getSystemData()).subscribe({
      next: (result: any) => {
        this.dialogRef.close(result);
      },
      error: (error) => { this.handleError(error); },
    });
  }

  keyFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      if (!value) {
        return null;
      }
      const keyFormat = 'ssh-rsa ';
      const formatValid = value.slice(0, 8).toLocaleLowerCase() === keyFormat && value.length >= 9;
      return !formatValid ? { format: true } : null;
    }
  }
}
