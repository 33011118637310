import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../component/snack-bar.component';
import { RequestQueue } from '../model/request.model';

@Injectable({
  providedIn: 'root'
})
/** Handle single snack bar instance and update data when ongoing request is closed.
 *  Manage queue of requests
 *  Pass unique id to manage request queue
 */
export class SnackBarService {

  private _requestQueue: RequestQueue[] = [];

  constructor(private _snackBar: MatSnackBar) { }

  public openSnackBar(id: string, data: string) {
    this.addRequest(id, data);
    // open snack bar instance only once
    if (!this._snackBar._openedSnackBarRef) {
      this.open(data);
    }
  }

  public closeSnackBarRequest(requestId: string) {
    this.closeRequest(requestId);
    // update snack bar display
    if (this.allRequestsClosed()) {
      this.dissmissSnackBar();
    } else {
      let nextRequest = this._requestQueue.find(x => x.active === true);
      this._snackBar._openedSnackBarRef.instance.data = nextRequest.data;
    }
  }

  private open(message: string) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: message
    });
  }

  private dissmissSnackBar() {
    this._requestQueue = [];
    this._snackBar.dismiss();
  }

  private addRequest(shareId: string, data: string): void {
    this._requestQueue.push({ id: shareId, data: data, active: true });
  }

  private closeRequest(shareId: string): void {
    if (this._requestQueue.length > 0) {
      let request = this._requestQueue.find(x => x.id === shareId);
      request.active = false;
    }
  }

  private allRequestsClosed(): boolean {
    return this._requestQueue.every(x => x.active === false);
  }
}
