import { Injectable } from '@angular/core';
import { HttpProcessorService } from '../http/http-processor.service';
import { Observable, Subject, concatMap, of } from 'rxjs';
import { GetTokenRequest, ACMIDMFlowRequest, TokenRequest } from '../http/request-urls/authorizationRequestUrl';
import { TokenExchangeService } from './token-exchange.service';
import cryptoRandomString from 'crypto-random-string';
import { Params, UrlSerializer } from '@angular/router';
import * as crypto from 'crypto-js';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenExchangeRequestService {

  constructor(private _httpProcessorService: HttpProcessorService,
    private uriSerializer: UrlSerializer,
    private _tokenExchangeService: TokenExchangeService,
    private oidcSecurityService: OidcSecurityService) { }

  public getToken(): Observable<string> {
    let obs$: Subject<string> = new Subject<string>();
    let request = new GetTokenRequest(this._tokenExchangeService);
    this._httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  public handleAcmIdmFlow(firstToken: string, tokenResponse: any): Observable<string> {
    let obs$: Subject<string> = new Subject<string>();
    const state = this.generateState();
    const code_verifier = cryptoRandomString({ length: 64 });
    let request = new ACMIDMFlowRequest(firstToken, state, this.generateCodeChallenge(code_verifier), this._tokenExchangeService);
    this._httpProcessorService.handleRequest(request).pipe(
      concatMap((secondResponse) => {
        const redirectParams: Params = this.extractRedirectParams(secondResponse);
        return of(redirectParams);
      }),
      concatMap((redirectParams) => {
        let request = new TokenRequest(tokenResponse.token, redirectParams['code'], code_verifier, this._tokenExchangeService);
        return this._httpProcessorService.handleRequest(request);
      }),
      concatMap((result) => {
        return of(result.access_token);
      })
    ).subscribe(access_token => { obs$.next(access_token);});
    return obs$;
  }

  public getTokenFlow(firstToken: string): Observable<string> {
    let obs$: Subject<string> = new Subject<string>();
    this.getToken().pipe(
      concatMap((secondToken) => { return this.handleAcmIdmFlow(firstToken, secondToken); }),
      concatMap((acmResponse) => { return of(acmResponse); })
    ).subscribe(x => { console.log('token is exchanged'); obs$.next(x); });
    return obs$;
  }

  public exchangeToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken().pipe(
      concatMap(firstToken => {
        if (environment.isTest === true) {
          return this.getTokenFlow(firstToken);
        } else {
          return of(firstToken);
        }
      })
    );
  }

  private handleAccessTokenCall(token: string, code: string, codeVerifier: string): Observable<string> {
    let obs$: Subject<string> = new Subject<string>();
    let request = new TokenRequest(token, code, codeVerifier, this._tokenExchangeService);
    this._httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result.access_token);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  private generateState(): string {
    return cryptoRandomString({ length: 28 });
  }

  private generateCodeChallenge(codeVerifier: string): string {
    const codeVerifierHash = crypto.SHA256(codeVerifier).toString(crypto.enc.Base64);
    return codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }

  private extractRedirectParams(redirectResponse: string): Params {
    const uri = redirectResponse.substring(redirectResponse.indexOf("callback?"), redirectResponse.indexOf("\">"))
      .replace("&amp;", "&");
    return this.uriSerializer.parse(uri).queryParams;
  }
}
