export interface IRoleDetails {
  name: string,
  translationString: string,
  allowedToOwner: boolean,
  allowedToContributor: boolean,
  allowedToReader: boolean
}


export let roleInformation = [{
  name: '',
  translationString: 'RoleInformation.Right_delete_share',
  allowedToOwner: true,
  allowedToContributor: false,
  allowedToReader: false
},
{
  name: '',
  translationString: 'RoleInformation.Right_edit_settings',
  allowedToOwner: true,
  allowedToContributor: false,
  allowedToReader: false
},
{
  name: '',
  translationString: 'RoleInformation.Right_participant',
  allowedToOwner: true,
  allowedToContributor: false,
  allowedToReader: false
},
{
  name: '',
  translationString: 'RoleInformation.Right_files',
  allowedToOwner: true,
  allowedToContributor: true,
  allowedToReader: false
},
{
  name: '',
  translationString: 'RoleInformation.Right_download_files',
  allowedToOwner: true,
  allowedToContributor: true,
  allowedToReader: true
}];
