import { Injectable } from '@angular/core';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { FileFactoryService } from 'src/app/services/FileOperationFactory/file-factory.service';
import { IShare, IFileFactoryManager, IFileUploadSubscription } from 'src/app/types/group-share.model';
import { Role } from 'src/app/types/participant.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  private _fileFactoryManager: IFileFactoryManager[] = [];
  private _subscriptions: IFileUploadSubscription[] = [];

  constructor(private _httpApiRequestService: HttpApiRequestService) { }

  private setupSubscription(manager: IFileFactoryManager) {
    const uploadFinished = manager.fileFactoryService.onUploadFinish({
      next: (status: boolean) => {
        this.removeFileFactory(manager.shareId);
        this._httpApiRequestService.refreshShare(manager.shareId);
        this.removeSubscription(manager.shareId);
      },
      error: err => {
        this.removeFileFactory(manager.shareId);
        this.removeSubscription(manager.shareId);
      },
      complete() { }
    });
    this._subscriptions.push({ shareId: manager.shareId, subscription: uploadFinished });
  }

  private removeSubscription(id: string) {
    let index = this._subscriptions.findIndex(x => x.shareId === id);
    this._subscriptions[index].subscription.unsubscribe();
    this._subscriptions.splice(index, 1);
  }

  isCurrentUserNotAnOwnerOfAShare(value: IShare): boolean {
    return value.rightTypeOfCurrentUser === Role.READER || value.rightTypeOfCurrentUser === Role.CONTRIBUTOR;
  }

  isFileFactoryExist(id: string) {
    return this._fileFactoryManager.some(x => x.shareId === id);
  }

  storeFileFactory(id: string, factoryInstance: FileFactoryService) {
    let manager = { shareId: id, fileFactoryService: factoryInstance };
    this._fileFactoryManager.push(manager);
    this.setupSubscription(manager);
  }

  getFileFactory(id: string): FileFactoryService {
    if (this._fileFactoryManager.length > 0) {
      let instance = this._fileFactoryManager.find(x => x.shareId === id);
      return CompareUtility.isDefinedAndNotNull(instance) ? instance.fileFactoryService : new FileFactoryService();
    }
    return new FileFactoryService();
  }

  removeFileFactory(id: string) {
    if (this._fileFactoryManager.length > 0) {
      let index = this._fileFactoryManager.findIndex(x => x.shareId === id);
      if (index >= 0) {
        this._fileFactoryManager.splice(index, 1);
      }
    }
  }
}
