import * as JSZip from 'jszip';
import { Observer, Subject, Subscription } from "rxjs";
import { SharedDetailsService } from "src/app/share-details/shared-details.service";
import { ServiceLocator } from "src/app/types/service-locator";
import { Folder } from "../../sidebar/transfer/model/transfer.model";
import { IFile } from "../../types/file-model";
import { BrowserOperationService, OperationTypes } from "../browser-operation.service";
import { FileManager, UploadStatus } from "./file-manager.model";

export class FileFactoryService {

  folders: Folder[] = [];
  public compressFolderFinish$: Subject<IFile>;
  private initiateFileUpload$: Subject<IFile[]>;
  private finishFileUpload$: Subject<boolean>;
  private filesFactory: IFile[] = [];
  private subs: Subscription[] = [];
  private _browserOperationService: BrowserOperationService;
  private _sharedDetailsService: SharedDetailsService;
  public isFileUploadInProgress: boolean = false;

  constructor() {
    this.initiateFileUpload$ = new Subject<IFile[]>();
    this.finishFileUpload$ = new Subject<boolean>();
    this.compressFolderFinish$ = new Subject<IFile>();
    this._browserOperationService = ServiceLocator.injector.get(BrowserOperationService);
    this._sharedDetailsService = ServiceLocator.injector.get(SharedDetailsService);
  }

  initiateUpload(files: IFile[], shareId: string) {
    // start upload operation for each file
    this.filesFactory = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      file.setShareId(shareId);
      file.startUpload();
      this.filesFactory.push(file);
    }
    this._browserOperationService.addOperation(OperationTypes.FileUpload);
    this.isFileUploadInProgress = true;
    this.setupSubscription();
    this.initiateFileUpload$.next(this.filesFactory);
  }

  onUploadInitiated(observe: Observer<IFile[]>): Subscription {
    return this.initiateFileUpload$.subscribe(observe);
  }

  onUploadFinish(observe: Observer<boolean>): Subscription {
    return this.finishFileUpload$.subscribe(observe);
  }

  onCompressFolderFinish(observe: Observer<IFile>): Subscription {
    return this.compressFolderFinish$.subscribe(observe);
  }

  uploadFinish() {
    const status = this.filesFactory.every(x => x.uploadStatus$.getValue() === UploadStatus.FINISHED);
    if (status) {
      this._browserOperationService.finishOperation(OperationTypes.FileUpload);
      this.isFileUploadInProgress = false;
      this.finishFileUpload$.next(status);
    }
  }

  setupSubscription() {
    this.filesFactory.forEach(fileManager => {
      const sub = fileManager.uploadStatus$.subscribe({
        next: (res) => {
          if (res === UploadStatus.FINISHED) {
            this.uploadFinish();
          }
        },
        error: (error) => { },
        complete: () => { }
      });
      this.subs.push(sub);
    });
  }

  getFileManagerFromFile(flowFile: File): IFile {
    let factory = new FileManager();
    factory.file = flowFile;
    factory.fileName = flowFile.name;
    factory.creationDateTime = new Date();
    factory.size = flowFile.size;
    factory.uniqueIdentifier = crypto.randomUUID();
    factory.fileSizeMB = 0;
    return factory;
  }

  createOrUpdateFolder(flowFile: File) {
    let filePath = flowFile.webkitRelativePath;
    let folderName = filePath.split('/')[0];
    let folders = this.folders.map((x) => x.folderName);
    if (folders.includes(folderName)) {
      let existingFolder = this.folders.find(
        (x) => x.folderName === folderName
      );
      existingFolder.files.push({ file: flowFile, relativePath: filePath });
    } else {
      let newFolder = new Folder();
      newFolder.folderName = folderName;
      newFolder.files.push({ file: flowFile, relativePath: filePath });
      this.folders.push(newFolder);
    }
  }

  compressFolder() {
    this.folders.forEach((folder) => {
      let jszip = new JSZip();
      folder.files.forEach((res) => {
        let path = res.relativePath.split('/');
        path.shift();
        jszip.file(path.join('/'), res.file);
      });
      jszip.generateAsync({ type: 'blob' }).then((x) => {
        let compressedFolder = this.addFolderToFileList(x, folder.folderName);
        this.compressFolderFinish$.next(compressedFolder);
        folder.compressingFinished = true;
      })
    });
    this.folders = [];
  }

  addFolderToFileList(value: Blob, folderName: string) {
    let zipFile = new File([value], folderName.concat('.zip'));
    return this.getFileManagerFromFile(zipFile);
  }

  getUploadingFiles() {
    return this.filesFactory;
  }
}
