<span class="optionIconContainer">
    <img #matMenuTrigger src="assets/images/downArrow.png" mat-icon-button [matMenuTriggerFor]="menu" [ngClass]="!someComplete() ? '' : 'hideIcon'">
    <img #matMenuTrigger src="assets/images/yellowDownArrow.png" mat-icon-button [matMenuTriggerFor]="menu" [ngClass]="!someComplete()? 'hideIcon' : ''">
</span>

<mat-menu #menu="matMenu">
    <div class="optionWrapper">
        <mat-checkbox #menuItems class="optionMenu"
        [(ngModel)]="selectAll"
        (click)="$event.stopPropagation()"
        (change)="onAllSelect($event)">{{'Common.Select_all' | translate}}</mat-checkbox>

        <mat-checkbox #menuItems class="optionMenu"
        *ngFor="let item of filterOption; let i = index;"
        [(ngModel)]="item.activated"
        (click)="$event.stopPropagation()"
        (change)="onOptionSelect()">{{ item.isTitleTranslated ? item.title : item.title | translate }}</mat-checkbox>
    </div>
</mat-menu>