import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { PICK_FORMATS, PickDateAdapterService } from 'src/app/services/pick-date-adapter.service';
import { SftpUrlService } from 'src/app/services/sftp-url.service';
import { IShare, ShareType } from 'src/app/types/group-share.model';
import { HttpApiRequestService } from '../../http/http-api-request.service';
import { ShareDetailsComponent } from '../share-details.component';
import { SharedDetailsService } from '../shared-details.service';
import { TabManagerService, Tabs } from 'src/app/services/tab-manager.service';

@Component({
  selector: 'app-shared-settings',
  templateUrl: './shared-settings.component.html',
  styleUrls: ['./shared-settings.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
    SftpUrlService
  ]
})
export class SharedSettingsComponent extends BaseAbstractComponent implements OnInit {

  public shareDetails: IShare;
  public shareName: string = '';
  public shareValidations: FormGroup;
  public sftpUrl: string;
  public nameChange: boolean;
  public tenantName: string = '';
  public shareId: string = '';
  public disableExpiryDate: boolean = false;

  constructor(public dialogRef: MatDialogRef<ShareDetailsComponent>,
    private _sharedDetailsService: SharedDetailsService,
    private _httpApiRequestService: HttpApiRequestService,
    private _tabManagerService: TabManagerService,
    private formBuilder: FormBuilder,
    private clipboard: Clipboard,
    private dateAdapter: DateAdapter<any>,
    private sftpUrlService: SftpUrlService) { 
    super();
    this.setupSubscription();
    this.dateAdapter.setLocale(this.translate.currentLang);
  }

  ngOnInit() {
    this.shareValidations = this.formBuilder.group({
      name: [this.shareName, [Validators.required]]
    });
  }

  previousButtonClick() {
    this._tabManagerService.updateTab(Tabs.ParticipantTab);
  }

  notificationPreferencesClick() {
    this._tabManagerService.updateTab(Tabs.NotificationsTab);
  }

  isAddWorkflow(): boolean {
    return this._sharedDetailsService.isAddWorkflow;
  }

  setupSubscription() {
    const showDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: (x: IShare) =>  {
        this.shareDetails = x;
        this.shareName = x.shareName;
        this.tenantName = x.tenantName;
        this.shareId = x.id;
        this.disableExpiryDate = this.shareDetails.shareType == ShareType.P2P;
        this.createSftpUrl();
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(showDetails);
  }

  nameChanged() {
    if(this.shareDetails.shareName !== this.shareName.trim() && !this.shareValidations.get('name').hasError('required')){
      this.shareDetails.shareName = this.shareName.trim();
      setTimeout(() => { this.nameChange = false; }, 5000);
      this.nameChange = true;
      let model = {
        "shareName": this.shareDetails.shareName,
      };
      this._httpApiRequestService.updateShareRequest(model, this.shareDetails.id).subscribe({
        next: (result) => {
          this._sharedDetailsService.updateSharedName(this.shareDetails.shareName);
        },
        error: (error) => { this.handleError(error); },
        complete: () => { },
      });
    }
  }

  createSftpUrl() {
    //TODO Use sftpLinkService with correct userId
    const participantId = '<username>';
    this.sftpUrl = this.sftpUrlService.createSftpUrl(this.shareDetails.id, participantId);
  }

  copySftpUrl() {
    this.clipboard.copy(this.sftpUrl);
  }
}
