<div class="wrapper">
  <img class="closeDialogButton" (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
    alt="{{'Images.DialogCloseButton' | translate}}">
  <div class="outerContainer">
    <div class="titleContainer">{{data.title}}</div>
    <div class="contentContainer">
      <!-- Name of contact -->
      <form class="inputBox" [formGroup]="_personService.personForm" [ngClass]="validateDisplayName() ? 'showError': '' ">
        <mat-form-field appearance="outline" floatLabel="always" hideRequiredMarker="true">
          <mat-label class="floatingLabel">{{'Contacts.General.Label' | translate}}</mat-label>
          <input name="name" matInput formControlName="name" [(ngModel)]="_personService.personData.name"
            autocomplete="off" maxlength="50" placeholder="{{'Dialogs.AddPersonDialog.ContactNamePlaceholder' | translate}}" />
          <mat-error class="errorMessage" *ngIf="_personService.personForm.get('name').hasError('required')">
            {{'ErrorMessages.RequiredField' | translate}}
          </mat-error>
        </mat-form-field>
      </form>

      <!--E-mail address -->
      <form class="inputBox" [formGroup]="_personService.personForm" [ngClass]="validateEmail() ? 'showError': '' ">
        <mat-form-field class="inputWrapper" appearance="outline" floatLabel="always" hideRequiredMarker="true">
          <mat-label class="floatingLabel">{{'Contacts.Table.Column_email' | translate}}</mat-label>
          <input type="email" name="emailAddress" ngModel email='true' matInput formControlName="emailAddress"
            [(ngModel)]="_personService.personData.emailAddress" autocomplete="off"
            placeholder="{{'Contacts.Email.Placeholder' | translate}}" />
          <mat-error class="errorMessage" *ngIf="_personService.personForm.get('emailAddress').hasError('required')">
            {{'ErrorMessages.RequiredField' | translate}}
          </mat-error>
          <mat-error class="errorMessage" *ngIf="_personService.personForm.get('emailAddress').hasError('email')">
            {{'ErrorMessages.Email' | translate}}
          </mat-error>
          <mat-error class="errorMessage" *ngIf="_personService.personForm.get('emailAddress').hasError('userExists')">
            {{'ErrorMessages.UserAlreadyExists' | translate}}
          </mat-error>
        </mat-form-field>
      </form>

      <!-- Language -->
      <form class="selectBox" [formGroup]="_personService.personForm" [ngClass]="validateLanguage() ? 'showError': '' ">
        <mat-form-field class="inputWrapper" appearance="outline" floatLabel="always" hideRequiredMarker="true">
          <mat-label class="floatingLabel">{{'Common.Language' | translate}}</mat-label>
          <mat-select name="languageCode" formControlName="languageCode" placeholder="{{'Dialogs.AddPersonDialog.LanguagePlaceholder' | translate}}"
            [(ngModel)]="_personService.personData.languageCode">
            <mat-option class="font-typo" *ngFor="let option of languageOptions" value='{{option.value}}'>{{option.title}}</mat-option>
          </mat-select>
          <mat-error class="errorMessage" *ngIf="_personService.personForm.get('languageCode').hasError('required')">
            {{'ErrorMessages.RequiredField' | translate}}
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <!-- Footer buttons -->
    <div mat-dialog-actions class="dialogActionsContainer">
      <button mat-dialog-close cdkFocusInitial class="leaveButton">
        {{'Common.Cancel'| translate}}</button>
      <button cdkFocusInitial class="activeButton deleteButton" (click)="savePersonDetails()">
        {{'Common.Save'| translate}}</button>
    </div>
  </div>
</div>