import { Injectable } from '@angular/core';
import { DialogModel } from '../types/dialog-model';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { UserLoginService } from './login.service';
import { SignOutDialogComponent } from '../dialogs/sign-out/sign-out-dialog.component';

export enum OperationTypes {
  FileUpload = 'FileUpload'
}

@Injectable({
  providedIn: 'root'
})
export class BrowserOperationService {

  // Add only mandetory operations which want to prevent browser activities
  private _operationQueue: OperationTypes[] = [];

  constructor(private translate: TranslateService,
    private _userLoginService: UserLoginService,
    private dialog: MatDialog) { }

  public addOperation(value: OperationTypes) {
    this._operationQueue.push(value);
  }

  public finishOperation(value: OperationTypes) {
    let index = this._operationQueue.findIndex(op => op === value);
    this._operationQueue.splice(index, 1);
  }

  public preventBroswserOperations(): boolean {
    return this._operationQueue.length > 0;
  }

  public openDialog(): void {
    let data = new DialogModel();
    this.translate.get('Dialogs.SignOutDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.SignOutDialog.Message').subscribe((x: string) => data.content = x);
    const dialogRef = this.dialog.open(SignOutDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '225px',
      width: '450px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this._userLoginService.logout();
      }
    });
  }
}
