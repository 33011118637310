<div class="quickShareWrapper">
    <label class="shareFilesLable">{{'Sidebar.Select_files' | translate}}</label>
    <app-transfer></app-transfer>
    <label class="shareWithLable">{{'Sidebar.Share_with' | translate}}</label>
    <form class="inputBox">
        <mat-form-field #searchField class="searchForm" appearance="fill" (click)="nameClicked($event)"
            [matMenuTriggerFor]="participantMenu">
            <input class="searchKey" #search name="participantid" matInput placeholder="{{'Sidebar.Contactname_placeholder' | translate }}"
                [(ngModel)]="participantName" maxlength="50" autocomplete="off" textOverflow [textValue]="participantName" [disabled]="participantSelected">
            <img *ngIf="participantSelected" class="closeDialogButton" (click)="resetParticipant()"
                src="assets/images/closeDialogButton.png" alt="{{'Images.DialogCloseButton' | translate}}">
        </mat-form-field>
    </form>
    <mat-menu #participantMenu="matMenu">
        <table mat-table [dataSource]="searchedParticipants" class="searchedParticipantsTable"
            *ngIf="searchedParticipants.length > 0">
            <ng-container matColumnDef="searchIcon">
                <td mat-cell *matCellDef="let element">
                    <span class="iconContainer">
                        <div [ngSwitch]="element.type">
                            <img *ngSwitchCase="'SYSTEM'" class="icon-container"
                                src="assets/images/systemActiveIcon.png" matTooltip="{{'Common.System' | translate}}">
                            <img *ngSwitchCase="'USER'" class="icon-container" src="assets/images/activeParticipant.png"
                                matTooltip="{{'Common.User' | translate}}">
                        </div>
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="searchName">
                <td mat-cell *matCellDef="let element"> {{element.displayName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="searchEmailAddress">
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>
            <ng-container matColumnDef="searchOrganization">
                <td mat-cell *matCellDef="let element"> {{element.entityNameOfParticipant}} </td>
            </ng-container>

            <tr mat-row style="cursor: pointer;" *matRowDef="let row; columns: displayedColumns;"
                (click)="addParticipant(row)">
            </tr>
        </table>
        <div>
            <div class="NoResultFound" [style.width.px]="329">
                <mat-label>{{'Common.NoResultFound_txt' | translate}}</mat-label>
                <img class="icon-container addPersonIcon" id="addPerson" src="assets/images/addPerson_black.png" (click)="addPerson()" matTooltip=" {{'Sidebar.Invite'| translate}}">
            </div>
        </div>
    </mat-menu>
    <div class="shareFilesbtnContainer" [ngClass]="!isNameValid() ? 'disableClass': ''">
        <button class="shareBtns" (click)="shareFiles()">
            <img id="attachmentIcon" src="assets/images/attachment.png" alt="{{'Images.AttachmentIcon'|translate}}">
            <label>{{'Common.Share_file_btn'|translate}}</label>
        </button>
    </div>
</div>