import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from '../material.module';
import { CommonAppModule } from "../common.app.module";

import { ShareDetailsComponent} from './share-details.component';
import { SharedFilesComponent } from './shared-files/shared-files.component';
import { SharedParticipantsComponent } from './shared-participants/shared-participants.component';
import { SharedSettingsComponent } from './shared-settings/shared-settings.component';
import { AppTranslationModule } from '../app-translation.module';
import { RoleInformationComponent } from './role-information/role-information.component';
import { CommonUIModule } from '../common-ui/common-ui.module';
import { FileSizeComponent } from './file-size/file-size.component';
import { FileTypeComponent } from './file-type/file-type.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { ActionLogComponent } from './action-log/action-log.component';
import { NotificationsComponent } from './notifications/component/notifications.component';
import { ExpiryDateComponent } from './expiry-date/expiry-date.component';

@NgModule({
  declarations: [
    ShareDetailsComponent,
    SharedFilesComponent,
    SharedParticipantsComponent,
    SharedSettingsComponent,
    RoleInformationComponent,
    FileSizeComponent,
    FileTypeComponent,
    ActionLogComponent,
    NotificationsComponent,
    ExpiryDateComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CommonAppModule,
    AppTranslationModule,
    CommonUIModule,
    SharedPipesModule
  ],
  providers: [DatePipe]
})
export class SharedDetailsModule { }
