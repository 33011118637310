import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider/slider.component';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { AppTranslationModule } from '../app-translation.module';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { SingleSelectComponent } from './single-select/component/single-select.component';
import { FilterComponent } from './filter/component/filter.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SnackBarComponent } from './snack-bar/component/snack-bar.component';
import { UploadFileMenuComponent } from './upload-file-menu/upload-file-menu.component';
import { TextOverflowDirective } from '../directives/text-overflow.directive';

@NgModule({
  declarations: [
    SliderComponent,
    MultipleSelectComponent,
    ErrorMessageComponent,
    SingleSelectComponent,
    FilterComponent,
    ProgressBarComponent,
    SnackBarComponent,
    UploadFileMenuComponent,
    TextOverflowDirective
  ],
  imports: [
    FormsModule,
    CommonModule,
    MaterialModule,
    AppTranslationModule,
    ReactiveFormsModule
  ],
  exports: [
    SliderComponent,
    ErrorMessageComponent,
    SingleSelectComponent,
    FilterComponent,
    ProgressBarComponent,
    UploadFileMenuComponent,
    TextOverflowDirective
  ]
})
export class CommonUIModule { }
