import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { IRequest, requestType } from './request-urls/requestUrl.model';

@Injectable({
  providedIn: 'root'
})
export class HttpProcessorService {

  constructor(private http: HttpHandlerService) { }

  handleRequest(request: IRequest): Observable<any> {
    switch (request.type) {
      case requestType.Get:
        return request.processResponse(this.http.get(request));
      case requestType.Post:
        return request.processResponse(this.http.post(request));
      case requestType.Put:
        return request.processResponse(this.http.put(request));
      case requestType.Delete:
        return request.processResponse(this.http.delete(request));
      default:
        throw new Error("Invalid request type");
    }
  }
}

