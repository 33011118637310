import { NgModule } from '@angular/core';
import { CustomFileSizePipe } from './custom-file-size/custom-file-size.pipe';
@NgModule({
    declarations: [
        CustomFileSizePipe
    ],
    exports:[
        CustomFileSizePipe
    ],
    providers: [CustomFileSizePipe] 
})
export class SharedPipesModule { }