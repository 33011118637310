import { AfterViewInit, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { HttpProcessorService } from 'src/app/http/http-processor.service';
import { CreateShareRequest } from 'src/app/http/request-urls/shareAndFileRequestUrl';
import { DateTimeService } from 'src/app/services/date-time.service';
import { FileSizeManagerService } from 'src/app/services/file-size-manager.service';
import { UserLoginService } from 'src/app/services/login.service';
import { TokenExchangeService } from 'src/app/services/token-exchange.service';
import { HttpApiNotificationService } from 'src/app/share-details/notifications/services/http-api-notification-preferences.service';
import { ShareDetailsComponent } from 'src/app/share-details/share-details.component';
import { IFile } from 'src/app/types/file-model';
import { IShare, ShareType, ShareWorkflow, Workflow } from 'src/app/types/group-share.model';
import { Role } from 'src/app/types/participant.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { HttpApiRequestService } from '../../http/http-api-request.service';
import { TransferService } from '../transfer/service/transfer.service';

@Component({
  selector: 'app-group-share',
  templateUrl: './group-share.component.html',
  styleUrls: ['./group-share.component.scss'],
  providers: [TransferService]})

export class GroupShareComponent extends BaseAbstractComponent implements AfterViewInit {

  public shareName: string = '';
  public files: IFile[] = [];
  public groupShareModel: IShare;
  public uploadFiles$: Subject<IFile[]>;
  uploadingFlag: boolean = false;

  constructor(private httpProcessorService: HttpProcessorService,
    private _httpApiRequestService: HttpApiRequestService,
    private tokenExchangeService: TokenExchangeService,
    private dateTimeService: DateTimeService,
    private _fileSizeManagerService: FileSizeManagerService,
    private _httpApiNotificationService: HttpApiNotificationService,
    private _loginService: UserLoginService,
    private _transferService: TransferService) {
    super();
    this.uploadFiles$ = new Subject<IFile[]>();
  }

  ngAfterViewInit() {
    this.setupSubscription();
  }

  public isNameValid() {
    if(this.uploadingFlag) {
      return false;
    }
    return CompareUtility.isNotEmpty(this.shareName) && this.validateFiles();
  }

  public isFileSelected(): boolean {
    return this.files.length > 0;
  }

  public shareFiles() {
    this.uploadingFlag = true;
    let model = {
      "shareName": this.shareName.trim(),
      "expirationDate": this.dateTimeService.getExpirationDate(),
      "shareType": ShareType.GROUP
    };
    let request = new CreateShareRequest(model, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (share: IShare) => {
        this.createDefaultNotifications(share);
      },
      error: (error) => {
        this.handleError(error);
      },
      complete: () => { }
    });
  }

  private resetGroupModel() {
    this.shareName = '';
    this.files = [];
    this.groupShareModel = null;
    this._transferService.clearTransfer();
  }

  private loadShare(share: IShare) {
    this._httpApiRequestService.refreshMyShares();
    this.uploadingFlag = false;
    let shareModel: ShareWorkflow = new ShareWorkflow();
    share.rightTypeOfCurrentUser = Role.OWNER;
    shareModel.share = share;
    shareModel.workFlow = Workflow.ADD;
    this.dialog.open(ShareDetailsComponent, {
      panelClass: 'dialog-container',
      data: shareModel,
    });
    this.resetGroupModel();
  }

  private loadShareAndUploadFiles(share: IShare) {
    this._httpApiRequestService.refreshMyShares();
    this.uploadingFlag = false;
    let shareModel: ShareWorkflow = new ShareWorkflow();
    share.rightTypeOfCurrentUser = Role.OWNER;
    shareModel.share = share;
    shareModel.workFlow = Workflow.ADD;
    shareModel.files = this.files;
    this.dialog.open(ShareDetailsComponent, {
      panelClass: 'dialog-container',
      data: shareModel,
    });
    this.resetGroupModel();
  }

  private setupSubscription() {
    const transferSubscription = this._transferService.onFilesSelected({
      next: (files: IFile[]) => {
        this.files = files;
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(transferSubscription);
  }

  private getSizeUploaded() {
    let totalSize: number = 0;
    this.files.forEach((x) => {
      totalSize = totalSize + x.size;
    });
    return totalSize;
  }

  private createDefaultNotifications(share: IShare) {
    this._httpApiNotificationService.updateDefaultNotificationPreferences(share.id, this._loginService.getUserId(), share.rightTypeOfCurrentUser, this._loginService.getWorkRelationId()).subscribe({
      next: (result) => {
        if (this.files.length > 0) {
          this.groupShareModel = share;
          this.loadShareAndUploadFiles(this.groupShareModel);
        } else {
          this.loadShare(share);
        }
      },
      error: (error: any) => {
        this.uploadingFlag = false;
        this.resetGroupModel();
        this.handleError(error);
      },
      complete: () => { },
    });
  }

  public validateFiles(): boolean {
    return this._fileSizeManagerService.isFileSizeLessThanGB(this.getSizeUploaded());
  }
}
