<div class="wrapper" (resized)="onResized($event)" (click)="onClickEvent($event)">
    <img class="closeDialogButton" mat-icon-button mat-dialog-close src="assets/images/closeDialogButton.png"
        alt="{{'Images.DialogCloseButton' | translate}}">
    <div class="userContainer">
        <div class="contentWrapper">
            <div #header class="header">
                <div class="headerWrapper">
                    <div class="titleContainer">
                        <span class="nameInputBox">{{'Contacts.User_contact.Title' | translate}}</span>
                    </div>
                    <app-search-user></app-search-user>
                    <div class="searchContainer">
                        <input #search type="text" name="addedUserSearchKey" [(ngModel)]="addedUserSearchKey"
                            placeholder="{{'Contacts.User_contact.Added_contact_search_bar_placeholder' | translate}}"
                            autocomplete="off" maxlength="25" />
                    </div>
                </div>
            </div>
            <section class="table-container mat-elevation-z8" [ngStyle]="{'height': contentHeight }">
                <table mat-table [dataSource]="dataSource" matSort>
                    <!-- Icon Column -->
                    <ng-container matColumnDef="icon" class="iconColumn">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <img class="icon-container" src="assets/images/activeParticipant.png"
                                matTooltip="{{'Common.User' | translate}}">
                        </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Contacts.Table.Column_name' |
                            translate }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="pointer">
                            <div textOverflow [textValue]="element.displayName">{{element.displayName}}</div>
                        </td>
                    </ng-container>
                    <!-- E-mail address Column -->
                    <ng-container matColumnDef="emailId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Contacts.Table.Column_email' |
                            translate}}
                        </th>
                        <td mat-cell *matCellDef="let element" class="pointer"> {{element.email}} </td>
                    </ng-container>
                    <!-- Organization column -->
                    <ng-container matColumnDef="organization">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Common.Organization' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let element" class="pointer"> {{element.entityNameOfParticipant}} </td>
                    </ng-container>
                    <!-- Options Column -->
                    <ng-container matColumnDef="options">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <img src="assets/images/trash.png" matTooltip="{{'Common.Trash_btn' | translate}}"
                                mat-icon-button (click)="removeContact(element)">
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </section>
            <div #footer class="addPersonButtonContainer">
                <div class="error-wrapper">
                    <app-error-message [displayError]="displayError"></app-error-message>
                </div>
                <button cdkFocusInitial class="activeButton" (click)="addPersons()">
                    {{'Contacts.User_contact.Add_person_button'| translate}}</button>
            </div>
        </div>
    </div>
</div>