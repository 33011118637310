<aside class="sidebar">
    <!-- Show tabs when user role is User, Admin user -->
    <mat-tab-group *ngIf="getUserRole('User') || getUserRole('Admin user')" [disableRipple]="true" animationDuration="0ms"
        [ngClass]="this.tab === 0 ? 'quickShareTab': 'groupShareTab'" (selectedTabChange)="selectedTabChange($event)">
        <mat-tab class="cardTitle">
            <ng-template mat-tab-label>
                <div textOverflow [textValue]="'Sidebar.Quick_Share'| translate">{{'Sidebar.Quick_Share'| translate}}
                </div>
            </ng-template>
            <app-quick-share></app-quick-share>
        </mat-tab>
        <mat-tab class="cardTitle">
            <ng-template mat-tab-label>
                <div textOverflow [textValue]="'Sidebar.Group_Share'| translate">{{'Sidebar.Group_Share'| translate}}
                </div>
            </ng-template>
            <app-group-share></app-group-share>
        </mat-tab>
    </mat-tab-group>
    
    <!-- All shares buttons ADMIN Screen-->
    <div class="allSharesButtonsContainer" *ngIf="getUserRole('Admin')">
        <div class="buttonWrapper" (click)="allSharesButtonClick()" [ngClass]="this.activeButton ? 'selectButton': ''">
            <img src="assets/images/share.png" class="buttonIcon">
            <div textOverflow [textValue]="'Sidebar.AllShares_btn'| translate">{{'Sidebar.AllShares_btn'|translate}}
            </div>
        </div>
        <div class="buttonWrapper" (click)="contactButtonClick()" [ngClass]="!this.activeButton ? 'selectButton': ''">
            <img src="assets/images/contactsPassive.png" class="buttonIcon">
            <div textOverflow [textValue]="'Sidebar.Contacts'| translate">{{'Sidebar.Contacts'|translate}}</div>
        </div>
    </div>

    <!-- My shares buttons ADMIN_AS_USER screen -->
    <div class="mySharesButtonsContainer" *ngIf="getUserRole('Admin user')">
        <div class="buttonWrapper" (click)="mySharesButtonClick()" [ngClass]="this.activeButton ? 'selectButton': ''">
            <img src="assets/images/share.png" class="buttonIcon">
            <div textOverflow [textValue]="'Sidebar.MyShares_btn'| translate">{{'Sidebar.MyShares_btn'|translate}}</div>
        </div>
    </div>

    <!-- My shares buttons GUEST_USER screen -->
    <div class="mySharesGButtonsContainer" *ngIf="getUserRole('Guest user') || getUserRole('External user')">
        <div class="buttonWrapper" (click)="mySharesButtonClick()" [ngClass]="this.activeButton ? 'selectButton': ''">
            <img src="assets/images/share.png" class="buttonIcon">
            <div textOverflow [textValue]="'Sidebar.MyShares_btn'| translate">{{'Sidebar.MyShares_btn'|translate}}</div>
        </div>
    </div>

    <!-- My shares buttons USER screen -->
    <div class="mySharesButtonsContainer" *ngIf="getUserRole('User')">
        <div class="buttonWrapper" (click)="mySharesButtonClick()" [ngClass]="this.activeButton ? 'selectButton': ''">
            <img src="assets/images/share.png" class="buttonIcon">
            <div textOverflow [textValue]="'Sidebar.MyShares_btn'| translate">{{'Sidebar.MyShares_btn'|translate}}</div>
        </div>
        <div class="buttonWrapper" (click)="contactButtonClick()" [ngClass]="!this.activeButton ? 'selectButton': ''">
            <img src="assets/images/contactsPassive.png" class="buttonIcon">
            <div textOverflow [textValue]="'Sidebar.Contacts'| translate">{{'Sidebar.Contacts'|translate}}</div>
        </div>
    </div>
</aside>