import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DialogBoxComponent } from '../dialogs/dialog-box/dialog-box.component';
import { DialogModel } from '../types/dialog-model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ServiceLocator } from '../types/service-locator';
import { ErrorHandlerService } from '../services/error-handler.service';

@Component({
  selector: 'app-base-abstract',
  templateUrl: './base-abstract.component.html'
})
export class BaseAbstractComponent implements OnDestroy {

  public subscriptions: Subscription[] = [];
  public translate: TranslateService;
  public locale: string;
  public dateFormat: string;
  public dialog: MatDialog;
  public _errorHandlerService: ErrorHandlerService;
  
  constructor() {
    this.dialog = ServiceLocator.injector.get(MatDialog);
    this.translate = ServiceLocator.injector.get(TranslateService);
    this._errorHandlerService = ServiceLocator.injector.get(ErrorHandlerService);
    this.locale = this.translate.currentLang;
    this.dateFormat = this.translate.instant('DateFormat');
    this.addSubscription();
  }

  handleSuccess(result: string) {
    let data = new DialogModel();
    data.title = 'Success';
    data.content = result;
    this.dialog.open(DialogBoxComponent, {
      data
    });
  }

  public handleError(error: HttpErrorResponse) {
    this._errorHandlerService.showDefaultErrorDialog(error);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private addSubscription() {
    const localeSub = this.translate.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.locale = event.lang;
        this.dateFormat = this.translate.instant('DateFormat');
      }
    });
    this.subscriptions.push(localeSub);
  }
}
