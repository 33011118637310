<div class="outer-container">
    <div class="header">
        <div class="titleContainer contentWrapper">
            <form class="inputBox nameContainer" [formGroup]="shareValidations"
                [ngClass]="shareValidations.get('name').hasError('required') ? 'showError': '' ">
                <mat-form-field #searchField appearance="outline" floatLabel="always" hideRequiredMarker="true">
                    <mat-label class="floatingLabel">{{'Participant.Name' | translate}}</mat-label>
                    <input name="name" matInput placeholder="{{'Settings.Name_placeholder' | translate }}"
                        autocomplete="off" formControlName="name" [(ngModel)]="this.shareName" (change)="nameChanged()"
                        maxlength="100" />
                    <mat-error class="errorMessage" *ngIf="shareValidations.get('name').hasError('required')">
                        {{'ErrorMessages.RequiredField' | translate}}
                    </mat-error>
                    <mat-hint *ngIf="nameChange" class="nameChangeMessage">
                        {{'Settings.Name_Change' | translate}}
                    </mat-hint>
                </mat-form-field>
            </form>
            <div class="tooltip" matTooltip="{{'Settings.ShareId.Tooltip' | translate}}" matTooltipPosition="above">
                <form class="inputBox shareIdContainer">
                    <mat-form-field #searchField appearance="outline" floatLabel="always" hideRequiredMarker="true">
                        <mat-label class="floatingLabel">{{'Settings.ShareId.Label' | translate}}</mat-label>
                        <input class="organizationInput" matInput [value]="shareId" autocomplete="off" />
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="tooltip" matTooltip="{{'Settings.Organization.Tooltip' | translate}}" matTooltipPosition="above">
            <form class="inputBox organizationContainer">
                <mat-form-field #searchField appearance="outline" floatLabel="always" hideRequiredMarker="true">
                    <mat-label class="floatingLabel">{{'Settings.Organization.Label' | translate}}</mat-label>
                    <input class="organizationInput" matInput [value]="tenantName" autocomplete="off" />
                </mat-form-field>
            </form>
        </div>
        <section class="accordian-container">

            <mat-accordion class="accordian">
                <!--Expiry date expansion panel-->
                <mat-expansion-panel [ngClass]="disableExpiryDate? 'disableClass': ''">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="title">{{'Settings.Expiry_date_accordian.title'| translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-expiry-date></app-expiry-date>
                </mat-expansion-panel>

                <!--File type expansion panel-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="title">{{'Settings.File_types_accordian.title'| translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-file-type></app-file-type>
                </mat-expansion-panel>

                <!--File size expansion panel-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="title">{{'Settings.File_size_accordian.title'|
                            translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-file-size></app-file-size>
                </mat-expansion-panel>

                <!-- Sftp expansion panel -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="title">{{'Settings.Sftp_accordian.title'| translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <p class="sftpUrl">{{'Settings.Sftp_accordian.Message_sftp' | translate}}</p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </section>
    </div>

    <div class="dialogActionsContainer" *ngIf="isAddWorkflow()">
        <button class="closeButton" (click)="previousButtonClick()">
            {{'Common.Previous_btn'| translate}}</button>
        <button cdkFocusInitial class="activeButton" (click)="notificationPreferencesClick()">
            {{'Common.NotificationPreferencesButton'| translate}}</button>
    </div>
</div>