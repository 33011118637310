<div class="wrapper">
    <img class="closeDialogButton" (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
        alt="{{'Images.DialogCloseButton' | translate}}">
    <div class="outerContainer">
        <div class="contentWrapper">
            <div class="titleContainer">{{data.title}}</div>
            <div class="contentContainer">
                <mat-label>{{data.content}}</mat-label>
            </div>
            <div class="fileNamesContainer">
                <div *ngFor="let name of data.fileNames">
                    <li>{{name}}</li>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="dialogActionsContainer">
            <button [mat-dialog-close]="true" cdkFocusInitial class="yesButton">
                {{'Common.Download'| translate}}</button>
        </div>
    </div>
</div>