import { ContactOperation } from "./participant.model";

export class DialogModel {
  
  title: string;
  content: string;

  constructor() {
    this.title = '';
    this.content = '';
  }
}

export class RightsDialogModel extends DialogModel{
  shares: string[];

  constructor() {
    super();
  }
}

export class FileDialogModel extends DialogModel {

  fileNames: string[];

  constructor() {
      super();
  }
}

export class ErrorDialogModel extends DialogModel {
  
  user: string;
  application: string;
  code: number;
  time: string;

  constructor() {
    super();
  }
}

export class AddPersonDialogModel extends DialogModel {
  name: string;
  emailAddress: string;
  languageCode: string;
  externalContactId: string;
  operation: ContactOperation;

  constructor() {
    super();
  }
}
