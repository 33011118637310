import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { HttpContactApiService } from 'src/app/contacts/http-contact-api.service';
import { UserDataService } from 'src/app/contacts/services/user-data.service';
import { IContact } from 'src/app/types/participant.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent extends BaseAbstractComponent {

  @ViewChild('userMenuTrigger') userMenu: MatMenuTrigger;
  @ViewChild('organizationMenuTrigger') organizationMenu: MatMenuTrigger;
  @ViewChild('searchField') searchField: any;
  readonly userSearchedTable = ['icon', 'name', 'emailId', 'organization'];
  selectedOrganization: string = this.translate.instant('Common.All_text');
  width: number = parent.innerWidth;
  organizationSearchKey: string = '';
  userSearchKey: string = '';
  organizations: string[] = [];
  searchedUsers: IContact[] = [];
  showError: boolean = false;

  constructor(private _httpContactApiService: HttpContactApiService,
    private _userDataService: UserDataService) {
    super();
    this.setupSubscription();
  }

  public nameClicked(e: Event) {
    this.userMenu.closeMenu();
  }

  public onOragnizationSelected(organization: string) {
    this.selectedOrganization = organization;
    this.organizations = [];
    this.organizationSearchKey = '';
    this.organizationMenu.closeMenu();
  }

  public keyup(event: any) {
    if (event.target.value.length > 2) {
      this._httpContactApiService.searchOrganizations(event.target.value.trim()).subscribe({
        next: (result: string[]) => {
          this.organizations = result;
        },
        error: (error) => {
          this.handleError(error);
        }
      });
    }
  }

  public clearFilter(event: Event) {
    event.stopPropagation();
    this.resetOrganization();
    this.organizationMenu.closeMenu();
  }

  public keyUpUser(event: any) {
    if (event.target.value.length > 2) {
      this.width = this.searchField._elementRef.nativeElement.offsetWidth - 2;
      this.searchUser(event.target.value);
    } else {
      this.userMenu.closeMenu();
    }
  }

  public onUserSelected(value: IContact) {
    this._httpContactApiService.getContact(value.participantId, value.workRelationId).subscribe({
      next: (result: IContact) => {
        if (CompareUtility.isDefinedAndNotNull(result)) {
          this.displayError();
        } else {
          this._userDataService.addUser(value);
          this.userSearchKey = '';
          this.userMenu.closeMenu();
        }
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) {
          this._userDataService.addUser(value);
          this.userSearchKey = '';
          this.userMenu.closeMenu();
        } else {
          this.handleError(error);
        }
      }
    });
  }

  private displayError() {
    this.showError = true;
    this._userDataService.showError(true);
  }

  private searchUser(field: string) {
    let entityName = this.selectedOrganization !== this.translate.instant('Common.All_text') ? this.selectedOrganization : '';
    this._httpContactApiService.searchContacts(field.trim(), entityName).subscribe({
      next: (result: IContact[]) => {
        this.searchedUsers = result;
        this.userMenu.openMenu();
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  private resetOrganization() {
    this.organizationSearchKey = '';
    this.organizations = [];
    this.selectedOrganization = this.translate.instant('Common.All_text');
  }

  private setupSubscription() {
    const displayError = this._userDataService.onDisplayError({
      next: (value) => {
        this.showError = value;
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
    this.subscriptions.push(displayError);
  }
}
