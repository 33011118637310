<div class="outerContainer">
    <p>{{'Settings.File_size_accordian.message'| translate}}</p>
    <app-slider class="slider" inactiveLable="{{'Common.No'| translate}}" activeLable="{{'Common.Yes'| translate}}"
        (sliderChange)="onSliderChange($event)" [checked]="enableFileSize"></app-slider>
    <div class="fileSizeWrapper" [ngClass]="enableFileSize === false ? 'disable': ''">
        <form class="inputBox fileSizeInput" [formGroup]="validations"
            [ngClass]="isFileSizeShowError ? 'showError': '' ">
            <mat-form-field appearance="outline">
                <input matInput type="number" (keypress)="omit_special_char($event)" formControlName="fileSize" [(ngModel)]="fileSize" autocomplete="off" min="0"
                    (change)="onFileSizeChanged()" style="text-align: right;" />
            </mat-form-field>
        </form>
        <p>MB</p>
        <button class="addButton" (click)="addFileSize()">{{'Common.Add_btn'| translate}}</button>
    </div>
    <app-error-message [displayError]="displayError"></app-error-message>
    <p class="successMessage">{{successMessage}}</p>
</div>
