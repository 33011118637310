import { Injectable } from '@angular/core';
import { FilterOption } from '../model/filter-options.model';
import { ContactType } from 'src/app/types/participant.model';
import { ArrayOperationsUtility } from 'src/app/utilities/arrayOperations.utility';

@Injectable({
  providedIn: 'root'
})
export class FilterOptionsService {

  public getTypeFilterOptions(data: string[]): FilterOption[] {
    let options: FilterOption[] = [];
    data = ArrayOperationsUtility.removeDuplicates(data);
    data.forEach(x => {
      if (x == ContactType.SYSTEM) {
        options.push({ title: 'Common.System', value: x, activated: false, isTitleTranslated: false });
      } else if (x == ContactType.USER) {
        options.push({ title: 'Common.User', value: ContactType.USER, activated: false, isTitleTranslated: false });
      }
    });
    return options;
  }
}
