import {BehaviorSubject, exhaustMap, Subject} from "rxjs";
import {FileChunk, IFile, IFileStatus, UploadedChunk} from "src/app/types/file-model";
import {FileApiOperationService} from "./file-api-operation.service";
import {ServiceLocator} from "src/app/types/service-locator";
import * as fileType from "file-type";

export enum UploadStatus {
    START = 'Start',
    INPROGRESS = 'In progress',
    FINISHED = 'Finished',
    FAILED = 'Failed',
    PAUSE = 'Paused',
    NONE= 'None'
}

export class FileManager implements IFile {

    public id: string; // file id
    public fileName: string;
    public creationDateTime: Date;
    public fileSizeMB: number;
    public size: number;
    public uniqueIdentifier: string;
    public status: IFileStatus;

    public uploadStatus$: BehaviorSubject<UploadStatus>;
    public progressCount$: Subject<number>;

    private _file: File;
    private _fileApiOperationService: FileApiOperationService;
    private chunkSize: number = 12582912;
    private _documentSlices: FileChunk[] = [];

    constructor() {
        this._fileApiOperationService = ServiceLocator.injector.get(FileApiOperationService);
        this.progressCount$ = new Subject<number>();
        this.uploadStatus$ = new BehaviorSubject<UploadStatus>(UploadStatus.NONE);
    }

    setShareId(id: string) {
        this._fileApiOperationService.shareId = id;
    }

    startUpload(): void {
        this.preUpload().pipe(
            exhaustMap((res) => {
                return this.uploadFile(this._documentSlices);
            })
        ).subscribe();
    }

    preUpload(): Subject<any> {
        let obs$ = new Subject<string>();
        this.updateFileUploadStatus(UploadStatus.START);
        this.sliceFile(this._file);
        this.checkMimeType(this._file).then(mimeType => {
          this._fileApiOperationService.getFileId(this.fileName, this._file.size, mimeType).subscribe({
            next: (response: any) => {
              this.id = response.fileId;
              obs$.next(response.fileId);
            },
            error: (error) => {
              obs$.error(error);
              this.handleError();
            },
            complete: () => { }
          });
        });

        return obs$;
    }

    uploadFile(chunkArray: FileChunk[]): Subject<boolean> {
        let obs$ = new Subject<boolean>();
        this.updateFileUploadStatus(UploadStatus.INPROGRESS);
        this.uploadChunk(chunkArray);
        return obs$;
    }

    postUpload(): Subject<any> {
        let obs$ = new Subject<any>();
        this._fileApiOperationService.chunkedFileUploadFinish(this.id, this.fileName).subscribe({
            next: (response: any) => {
                obs$.next(response);
                this.updateFileUploadStatus(UploadStatus.FINISHED);
            },
            error: (error) => {
                obs$.error(error);
                this.handleError();
            },
            complete: () => { }
        });
        return obs$;
    }

    resumeUpload(): void {
        if(this.allChunksUploaded()){
            this.postUpload();
        } else {
            this._fileApiOperationService.getUploadedChunks(this.id).subscribe({
                next: (response: UploadedChunk[]) => {
                    let chunkArray: FileChunk[] = [];
                    response.forEach(uploadedChunk => {
                        chunkArray.push(this._documentSlices.filter(chunk => chunk.chunkNumber == uploadedChunk.chunkNumber)[0]);
                    });
                    chunkArray = this._documentSlices.filter(chunk => !chunkArray.includes(chunk));
                    this.uploadFile(chunkArray);
                }
            })
        }
    }

    updateProgress() {
        const uploadedChunks = this._documentSlices.filter(x => x.isChunkDataUploaded === true);
        const progress = Math.ceil((uploadedChunks.length / this._documentSlices.length) * 100);
        this.progressCount$.next(progress);
    }

    postChunkUpload() {
        this.updateProgress();
        const status = this.allChunksUploaded();
        if (status) {
            this.postUpload();
        }
    }

    sliceFile(file: File): void {
        let fileSize = file.size;
        let chunkNumber = 0;
        while (((chunkNumber + 1) * this.chunkSize) < fileSize) {
            let fileSliceChunk = new FileChunk();
            fileSliceChunk.data = file.slice(chunkNumber * this.chunkSize, (chunkNumber + 1) * this.chunkSize);
            fileSliceChunk.size = this.chunkSize;
            fileSliceChunk.chunkNumber = chunkNumber;
            this._documentSlices.push(fileSliceChunk);
            chunkNumber++;
        }
        let lastFileSliceChunk = new FileChunk();
        lastFileSliceChunk.data = file.slice(chunkNumber * this.chunkSize, fileSize);
        lastFileSliceChunk.size = lastFileSliceChunk.data.size;
        lastFileSliceChunk.chunkNumber = chunkNumber;
        this._documentSlices.push(lastFileSliceChunk);
    }

    public set file(value : File) {
        this._file = value;
    }

    public updateFileUploadStatus(value: UploadStatus) {
        this.uploadStatus$.next(value);
    }

    public handleError() {
        this.updateFileUploadStatus(UploadStatus.FAILED);
    }

    pauseUpload(): void {
        this.updateFileUploadStatus(UploadStatus.PAUSE)
    }

    uploadChunk(chunkArray: FileChunk[]): Subject<boolean> {
        let obs$ = new Subject<boolean>();
        if (chunkArray.length > 0){
            let updatedChunkArray = chunkArray.slice();
            let slice = updatedChunkArray[0];
            this._fileApiOperationService.uploadChunks(slice.data, this.id, slice.chunkNumber, slice.size, this.fileName).subscribe({
                    next: (response: any) => {
                        slice.isChunkDataUploaded = true;
                        this.postChunkUpload();
                        updatedChunkArray.shift();
                        if(this.uploadStatus$.getValue() != UploadStatus.PAUSE){
                            this.uploadChunk(updatedChunkArray);
                        } else {
                            this.updateFileUploadStatus(UploadStatus.FAILED);
                        }
                    },
                    error: (error) => {
                        obs$.error(error);
                        this.handleError();
                    },
                    complete: () => { obs$.next(true); }
                });
        }
        return obs$;
    }

    checkMimeType(file: File): Promise<string | null> {
      return file.arrayBuffer().then(result => {
          let a: { ext: fileType.FileType, mime: fileType.MimeType };
          a = fileType(result);
          return a != undefined ? a.mime : null;
      });
    }

    allChunksUploaded() {
        return this._documentSlices.every(x => x.isChunkDataUploaded === true);
    }
}
