import { Injectable } from '@angular/core';
import { Observer, Subject, Subscription } from 'rxjs';

export enum Tabs {
  FilesTab = 0,
  ParticipantTab = 1,
  SettingsTab = 2,
  NotificationsTab = 3
}

export enum SysteTabs {
  System = 0,
  Details = 1
}

export enum ContactTabs {
  User = 0,
  System = 1
}

@Injectable({
  providedIn: 'root'
})
export class TabManagerService {

  public selectTab$: Subject<number>;

  constructor() { 
    this.selectTab$ = new Subject<number>();
  }

  public updateTab(value: number) {
    this.selectTab$.next(value);
  }

  public onTabUpdated(observe: Observer<number>): Subscription {
    return this.selectTab$.subscribe(observe);    
  }
}
