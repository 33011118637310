import { Subscription } from "rxjs";
import { FileFactoryService } from "../services/FileOperationFactory/file-factory.service";
import { IFile } from "./file-model";
import { IParticipant, Role } from "./participant.model";

export interface IShare {
   id: string;
   shareName: string;
   authorizationIndicators: string[];
   creationDateTime: string;
   expirationDate: string;
   isPermanent: boolean;
   shareSizeMB: string;
   shareType: ShareType;
   status: string;
   createdByName: string;
   createdByUserId: string;
   maxFileSizeMB: number;
   restrictedFileTypes: IFileType[];
   rightTypeOfCurrentUser: Role;
   participants: IParticipant[];
   tenantId: string;
   tenantName: string;
}

export interface IFileType {
   restrictionType: string,
   fileType: string,
   displayRestrictionType?: string,
}

export interface IShareWorkflow {
   share: IShare,
   workFlow: Workflow,
   selectedTabIndex: number,
   files: IFile[]
}

export class ShareWorkflow implements IShareWorkflow {
   share: IShare;
   workFlow: Workflow;
   selectedTabIndex: number;
   files: IFile[];
}

export enum Workflow {
   ADD = "ADD",
   EDIT = "EDIT"
}


export enum ShareType {
   QUICK = "QUICK",
   GROUP = "GROUP",
   P2P = "P2P"
}

export class IFileFactoryManager {
   shareId: string;
   fileFactoryService: FileFactoryService;
}

export class IFileUploadSubscription {
   shareId: string;
   subscription: Subscription;
}