<div class="title-container tab" (resized)="onResized($event)">
    <div class="searchContainer">
        <input #search type="text" name="searchKey" [(ngModel)]="searchKey"
            placeholder="{{'Contacts.Search_bar_system' | translate}}" autocomplete="off" maxlength="50" />
    </div>
    <div class="addContactContainer" *ngIf="isLoggedInUserIsAdmin()">
        <button class="addButton" [matMenuTriggerFor]="menu">
            <img src="assets/images/singlePlusIcon.png">
            <label>{{'Common.Add_btn'|translate}}</label>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
            <div class="optionWrapper">
                <div class="optionMenu" (click)="addSystem()">
                    <img src="assets/images/systemActiveIcon.png" class="optionIcon">
                    <span>{{'Common.System'| translate}}</span>
                </div>
            </div>
        </mat-menu>
    </div>
</div>
<div>
    <section class="table-container mat-elevation-z8" [ngStyle]="{'max-height': contentHeight }" infinite-scroll
        [scrollWindow]="false" (scrolled)="onScrollDown($event)">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="icon" class="iconColumn">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <img class="icon-container" src="assets/images/systemActiveIcon.png" matTooltip="{{'Common.System' | translate}}" (click)="openContact(element)">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Contacts.Table.Column_name' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer" (click)="openContact(element)">
                    <div textOverflow [textValue]="element.displayName">{{element.displayName}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Contacts.Table.Column_email' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer" (click)="openContact(element)">
                    <div textOverflow [textValue]="element.email">
                        {{element.email}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="entityNameOfParticipant">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Common.Organization' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer" (click)="openContact(element)">
                    <div textOverflow [textValue]="element.entityNameOfParticipant">
                        {{element.entityNameOfParticipant}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>{{'Contacts.Table.Column_status'| translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer">
                    <span class="statusContainer" [ngSwitch]="element.active">
                        <div *ngSwitchCase="true" class="statusWrapper">
                            <img src="assets/images/enableIcon.png"
                                class="smallIcon enableIcon"><span>{{'Contacts.Status.Active'
                                | translate}}</span>
                        </div>
                        <div *ngSwitchCase="false" class="statusWrapper">
                            <img src="assets/images/disabledIcon.png"
                                class="smallIcon disabledIcon"><span>{{'Contacts.Status.Disabled' | translate}}</span>
                        </div>
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <img class="icon-container" src="assets/images/options.png" mat-icon-button
                        [matMenuTriggerFor]="menu" *ngIf="enableOptions(element)"
                        matTooltip="{{'Images.Options' | translate}}" alt="{{'Images.Options'|translate}}">
                    <mat-menu #menu="matMenu" xPosition="before">
                        <div class="optionWrapper">
                            <div class="optionMenu" (click)="openDialog(element)">
                                <img src="assets/images/trash.png" alt="{{'Images.DeleteFiles' | translate}}"
                                    class="icons">
                                <span>{{'MyShare.DeleteShare'| translate}}</span>
                            </div>
                        </div>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="tableRows"></tr>
        </table>
    </section>
    <div class="loading" *ngIf="isLoadingData">{{'MyShare.Loading_txt' | translate}}</div>
</div>