import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpProcessorService } from 'src/app/http/http-processor.service';
import { UpdateNotificationPreferences, GetNotificationPreferences, DeleteNotificationPreferencesRequest } from '../../../http/request-urls/notificationRequestUrl';
import { TokenExchangeService } from 'src/app/services/token-exchange.service';
import { IPreferences } from '../model/notification.model';
import { NotificationHandlerService } from './notification-handler.service';
import { Role } from 'src/app/types/participant.model';

@Injectable({
  providedIn: 'root'
})
export class HttpApiNotificationService {

  constructor(private _httpProcessorService: HttpProcessorService,
    private _handlerService: NotificationHandlerService,
    private _tokenExchangeService: TokenExchangeService) {
  }

  updateDefaultNotificationPreferences(shareId: string, userId: string, rightTypeOfUser: Role, workRelationId: string): Subject<any> {
    let defaultPreferences = this._handlerService.getDefaultPreferences(shareId, userId, rightTypeOfUser, workRelationId);
    return this.updateNotificationPreferences(shareId, defaultPreferences);
  }

  updateNotificationPreferences(shareId: string, model: any): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new UpdateNotificationPreferences(shareId, model, this._tokenExchangeService);
    this._httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  getNotificationPreferences(shareId: string): Subject<IPreferences[]> {
    let obs$ = new Subject<IPreferences[]>();
    let request = new GetNotificationPreferences(shareId, this._tokenExchangeService);
    this._httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  deleteParticipantPreferences(shareId: string, participantId: string, workRelationId: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new DeleteNotificationPreferencesRequest(shareId, participantId, workRelationId, this._tokenExchangeService);
    this._httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }
}
