<!-- Slider -->
<div class="fileTypeContainer"> {{'Settings.File_types_accordian.message'| translate}}
  <app-slider class="sliderContainer" inactiveLable="{{'Common.No'| translate}}"
    activeLable="{{'Common.Yes'| translate}}" [checked]="active" (sliderChange)="onSliderChange($event)">
  </app-slider>
</div>

<!-- File extension container -->
<div class="extensionInputContainer">
  <form class="restrictionSelectbox">
    <mat-label class="nameInputBox">{{'Settings.File_types_accordian.restriction_lable' | translate}}</mat-label>
    <app-single-select [options]="restrictionOptions" [selected]="selectedRestrictionType" [width]="200"
      [disabled]="!active"
      placeholder="{{'Settings.File_types_accordian.restrictionSelectBoxPlaceholder' | translate }}"
      (selectedEvent)="onRestrictionSelected($event)"></app-single-select>
  </form>
  <form class="inputBox" [ngClass]="isShowError ? 'showError': '' ">
    <mat-label class="nameInputBox">{{'Settings.File_types_accordian.file_type_lable' | translate}}</mat-label>
    <mat-form-field #searchField appearance="outline" floatLabel="always" (click)="nameClicked($event)"
      [matMenuTriggerFor]="menu" [ngClass]="isRestrictionTypeSelected()? 'disableClass': ''">
      <input #search name="searchBox" matInput placeholder="{{'Settings.File_types_accordian.Name_placeholder' | translate }}"
        [(ngModel)]="searchKey" maxlength="20" autocomplete="off" />
    </mat-form-field>
    <!-- Error -->
    <mat-error class="errorMessage" *ngIf="isShowError">{{errorMessage}}</mat-error>
  </form>
</div>

<!-- Menu -->
<mat-menu #menu="matMenu">
  <div *ngIf="searchResult.length > 0; else NoRecordsFound" class="searchResult" [style.width.px]="width">
    <div *ngFor="let type of searchResult">
      <div class="fileType" (click)="addFileType(type.extension)">{{type.extension}}</div>
    </div>
  </div>
  <ng-template #NoRecordsFound>
    <div class="noResultFound" [style.width.px]="width">
      <mat-label style="padding-left:20px;">{{'Common.NoResultFound_txt' | translate}}</mat-label>
    </div>
  </ng-template>
</mat-menu>

<!-- File type table -->
<div class="tableWrapper" *ngIf="restrictedFileTypes.data.length > 0">
  <section class="container mat-elevation-z8">
    <table mat-table [dataSource]="restrictedFileTypes" matSort>
      <ng-container matColumnDef="restrictionType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Settings.File_types_accordian.restriction_type' |
          translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.displayRestrictionType}}</td>
      </ng-container>
      <ng-container matColumnDef="fileType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Settings.File_types_accordian.file_type_lable' |
          translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.fileType}}</td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div matTooltip="{{'Settings.File_types_accordian.disableTrash' | translate}}" [matTooltipDisabled]="!isAlreadyInShare(element)">
            <img src="assets/images/trash.png" [ngClass]="isAlreadyInShare(element) ? 'disableClass': ''" matTooltip="{{'Common.Trash_btn' | translate}}" class="trashIcon"
            mat-icon-button (click)="removeFileType(element)">

          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="fileTypeColumns; sticky: true"></tr>
      <tr mat-row class="pointer" *matRowDef="let row; columns: fileTypeColumns;"></tr>
    </table>
  </section>
</div>