export const environment = {
  production: true,
  auth0: {
    domain: 'https://authenticatie.vlaanderen.be/op/v1/auth',
    baseDomain: 'https://authenticatie.vlaanderen.be',
    clientId: 'f3244564-25c1-42b9-b22a-7facb735f954',
    state: 'Fheue34eg2hjsdehfk839edd HTTP/1.1',
    redirectUri: 'https://smft.vlaanderen.be/callback',
    clientIdBE: '72eadf9f-3465-4281-a72e-ac23e399fcdf'
  },
  basePath: 'https://988d9ac6-a102-441e-ab28-6a7cabf050d7.smft.vlaanderen.be/api/v1',
  basePathAuthorization: 'https://smft-authorization.apps.smftprdopenshift.westeurope.aroapp.io',
  baseParticipantPath: 'https://1675fee6-1197-11ee-be56-0242ac120002.smft.vlaanderen.be/api/v1',
  baseNotificationPath: 'https://smft-notifications.apps.smftprdopenshift.westeurope.aroapp.io/api/v1',
  baseExternalsPath: 'https://smft-externals.apps.smftprdopenshift.westeurope.aroapp.io/api/v1',
  defaultLocale: 'nl',
  locale: [
    { code: 'nl', name: 'NL' },
    { code: 'en', name: 'EN' }
  ],
  isTest: true,
  blobName: 'storsmftprd',
  blobAdress: 'storage.smft.vlaanderen.be',
  expiryDate: 14,
  emailRegistrationLink: 'https://www.burgerprofiel.be/',
  title: 'SMFT Vlaanderen',
  timeOutSeconds: 3600
};
