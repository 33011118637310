<div class="no-rights-wrapper">
  <app-toolbar></app-toolbar>
  <div class="content">
    <div class="title">
      {{'NoRights.Title' | translate}}
    </div>
    <div class="additional-information">
      {{'NoRights.Additional_information' | translate}}
    </div>
  </div>
</div>
