<div class="input-select-box-wrapper">
    <div class="organization-select-box" [matMenuTriggerFor]="menu" #organizationMenuTrigger="matMenuTrigger">
        <div textOverflow [textValue]="selectedOrganization">{{selectedOrganization}}</div>
        <span><img src="assets/images/downArrow.png" mat-icon-button class="downArrowButton"></span>
    </div>
    <form class="inputBox" [ngClass]="showError ? 'showError': '' ">
        <mat-form-field #searchField appearance="outline" floatLabel="always" [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger" (click)="nameClicked($event)">
            <mat-label class="floatingLabel">{{'Contacts.User_contact.Text_lable' | translate}}</mat-label>
            <input name="userSearchKey" matInput [(ngModel)]="userSearchKey" maxlength="20"
                placeholder="{{'Contacts.User_contact.New_contact_search_bar_placeholder' | translate }}" 
                (keyup)="keyUpUser($event)" autocomplete="off" />
        </mat-form-field>
    </form>
</div>
<!-- Organization search menu -->
<mat-menu #menu="matMenu">
    <div class="searchResult" (click)="$event.stopPropagation();">
        <div class="organizations-container">
            <div class="searchContainer">
                <input #searchOrganization type="text" name="organizationSearchKey" [(ngModel)]="organizationSearchKey"
                    (keyup)="keyup($event)"
                    placeholder="{{'Contacts.User_contact.Organization_search_bar_placeholder' | translate}}"
                    autocomplete="off" />
            </div>
            <div class="contentWrapper">
                <div class="entityName" *ngFor="let entity of organizations" (click)="onOragnizationSelected(entity)">
                    {{entity}}
                </div>
            </div>
            <div class="buttonContainer">
                <button class="buttonStyle" (click)="clearFilter($event)">
                    <label class="clearFilterButton">{{'Common.Clear_filter_button_text' | translate}}</label>
                </button>
            </div>
        </div>
    </div>
</mat-menu>
<!-- User search menu -->
<mat-menu #userMenu="matMenu">
    <div class="searchResult" (click)="$event.stopPropagation();">
        <div *ngIf="searchedUsers.length > 0; else NoRecordsFound">
            <table mat-table [dataSource]="searchedUsers" [style.width.px]="width">
                <ng-container matColumnDef="icon">
                    <td mat-cell *matCellDef="let element">
                        <div class="iconContainer">
                            <img class="icon-container" src="assets/images/activeParticipant.png"
                                matTooltip="{{'Common.User' | translate}}">
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <td mat-cell *matCellDef="let element">
                        <div textOverflow [textValue]="element.displayName">
                            {{element.displayName}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="emailId">
                    <td mat-cell *matCellDef="let element">
                        <div textOverflow [textValue]="element.email">
                            {{element.email}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="organization">
                    <td mat-cell *matCellDef="let element">
                        <div textOverflow [textValue]="element.entityNameOfParticipant">
                            {{element.entityNameOfParticipant}}
                        </div>
                    </td>
                </ng-container>
                <tr mat-row style="cursor: pointer;" *matRowDef="let row; columns: userSearchedTable;"
                    (click)="onUserSelected(row)">
                </tr>
            </table>
        </div>
        <ng-template #NoRecordsFound>
            <div class="noResultFound" [style.width.px]="width">
                <mat-label style="padding-left:2vh;">{{'Common.NoResultFound_txt' | translate}}</mat-label>
            </div>
        </ng-template>
    </div>
</mat-menu>