import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CompareUtility } from '../utilities/compare.utility';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  public getCreationDate(): string {
    return this.formatDate(new Date());
  }

  public getExpirationDate(): string {
    let date = new Date();
    date.setDate(date.getDate() + environment.expiryDate);
    return this.formatDate(date);
  }

  public formatDate(date: Date): string {
    let datePostFormatting = new DatePipe('en-US').transform(date, 'YYYY-MM-dd') as string; //type assertion
    return CompareUtility.isDefinedAndNotNull(datePostFormatting) ? datePostFormatting : '';
  }

  public readDateFromString(dateString: string): Date {
    return new Date(dateString);
  }
}


