import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOptions } from '../model/single-select.model';

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class SingleSelectComponent {

  @Input() public options: SelectOptions[] = [];
  @Input() public placeholder: string;
  @Input() public selected: string;
  @Input() public disabled: boolean;
  @Input() public width: number;
  @Output() public selectedEvent: EventEmitter<string> = new EventEmitter<string>();

  public selectControl = new FormControl(this.options);

  selectionChanged(): void {
    this.selectedEvent.emit(this.selected);
  }
}
