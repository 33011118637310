import { Injectable } from '@angular/core';
import { BehaviorSubject, Observer, Subject, Subscription } from 'rxjs';
import { IUser } from '../types/participant.model';
import { CompareUtility } from '../utilities/compare.utility';
import { HttpApiRequestService } from '../http/http-api-request.service';
import { Router } from '@angular/router';
import { TokenExchangeService } from './token-exchange.service';
import { VerifyTokenRequest } from '../http/request-urls/authorizationRequestUrl';
import { TokenExchangeRequestService } from './token-exchange-request.service';
import { HttpProcessorService } from '../http/http-processor.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { TokenManagerService } from './token-manager.service';
import { UserRole } from '../types/user-roles.model';

@Injectable({
  providedIn: 'root',
})
export class UserLoginService {

  public isLoggedInUserIsAdmin: boolean = false;
  public loggedInUserDetails: IUser;
  public userAuthenticated$: Subject<void>;
  public loadLandingPage$: BehaviorSubject<boolean>;
  public loadToolbar$: BehaviorSubject<boolean>;
  public userName: string;
  public entityId: string;
  public entityNameOfParticipant: string;
  public workRelationId: string;
  public isUserLoggedIn: boolean;
  public access: boolean;
  private _loggedInUserRole$: BehaviorSubject<UserRole>;


  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private _httpApiRequestService: HttpApiRequestService,
    private _tokenExchangeRequestService: TokenExchangeRequestService,
    private _tokenExchangeService: TokenExchangeService,
    private _tokenManagerService: TokenManagerService,
    private _httpProcessorService: HttpProcessorService) {
    this.userAuthenticated$ = new Subject<void>();
    this.loadLandingPage$ = new BehaviorSubject<boolean>(false);
    this.loadToolbar$ = new BehaviorSubject<boolean>(false);
    this._loggedInUserRole$ = new BehaviorSubject<UserRole>(UserRole.NONE);
  }

  changeRole(value: UserRole) {
    this._loggedInUserRole$.next(value);
  }

  onAdminRoleChanged(observer: Observer<UserRole>): Subscription {
    return this._loggedInUserRole$.subscribe(observer);
  }

  public loginSMFT() {
    const checkAuthSubscription = this.oidcSecurityService.checkAuth().subscribe(({ idToken }) => {
      console.log('checkAuth');
      this._tokenExchangeService.storeAccessTokenExpiration(this.getDecodedExpiration(idToken));
      this._tokenManagerService.getExpirationDate();

      if (environment.isTest) {
        let request = new VerifyTokenRequest(idToken, this._tokenExchangeService);
        this._httpProcessorService.handleRequest(request).subscribe({
          next: () => {
            this.exchangeToken();
          },
          error: (error) => { },
          complete: () => { }
        })
      } else {
        this.exchangeToken();
      }
    });
    this.subscriptions.push(checkAuthSubscription);
  }

  public getDecodedExpiration(idToken: string): string {
    let decoded = jwt_decode<JwtPayload>(idToken);
    return decoded.exp.toString();
  }

  public refreshBrowser() {
    this.getLoggedInUserDetails();
  }

  public initiateLandingPage(observer: Observer<boolean>): Subscription {
    return this.loadLandingPage$.subscribe(observer);
  }

  public initiateToolbar(observer: Observer<boolean>): Subscription {
    return this.loadToolbar$.subscribe(observer);
  }

  public logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => {
      this._tokenExchangeService.clearCookies();
      this.isUserLoggedIn = false;
    });
  }

  public getLoggedInUserDetails() {
    this._httpApiRequestService.getLoggedInUserDetails().subscribe({
      next: (result: IUser) => {
        this.loggedInUserDetails = result;
        console.log('/me request');
        this.loadLandingPage();
      },
      error: (error) => {
        this.navigateToNoRightsPage();
        },
      complete: () => { },
    });
  }

  public getUserId(): string {
    return this.loggedInUserDetails.id;
  }

  public getWorkRelationId(): string {
    return this.workRelationId;
  }

  public loggedInUserRole() {
    return this._loggedInUserRole$.value;
  }

  public navigateToNoRightsPage() {
    this.router.navigate(['norights']);
  }

  public isLoggedInUserIsExternalPerson(): boolean {
    let roleValue = this.loggedInUserDetails?.roles[0]?.value;
    if (CompareUtility.isDefinedAndNotNull(roleValue)) {
      return (
        roleValue.includes('vo') &&
        roleValue.includes('digitaalvlaanderen_smft') &&
        roleValue.includes('profile')
      );
    }
    return false;
  }

  private loadLandingPage() {
    const userDataSubscription = this.oidcSecurityService.getUserData().subscribe((userData) => {
      console.log('getUserData' + userData);
      this.checkUserRoles(userData);
      this.evaluateUserRights(userData);
      this._tokenExchangeService.storeUserData(JSON.stringify(userData));
      this.processUserData();
      this.loadToolbar$.next(true);
      if(this.access) {
        this.router.navigate(['shares']);
        this.loadLandingPage$.next(true);
        console.log('navigated to landing page');
      } else {
        this.navigateToNoRightsPage();
      }
      this.isUserLoggedIn = true;
    });
    this.subscriptions.push(userDataSubscription);
    this.oidcSecurityService.getAuthenticationResult().subscribe((authResult) => {
        this._tokenExchangeService.storeJwt(JSON.stringify(authResult.id_token));
    });
  }

  private processUserData() {
    let userData = this._tokenExchangeService.getStoredUserData();
    this.userName = (environment.isTest ? userData?.given_name + " " + userData?.family_name : userData?.name);
    this.workRelationId = this.loggedInUserDetails.workRelations[0].workRelationId;
    this.entityNameOfParticipant = this.loggedInUserDetails.workRelations[0].entityName;
    this.entityId = this.loggedInUserDetails.workRelations[0].entityId;
  }

  private exchangeToken() {
    this._tokenExchangeRequestService.exchangeToken().subscribe((token: string) => {
      console.log('exchange token is completed');
      this._tokenExchangeService.storeAccessToken(token);
      this.getLoggedInUserDetails();
    });
  }

  private evaluateUserRights(userData: any) {
    if (this.isLoggedInUserIsExternalPerson()) {
      if (CompareUtility.isDefinedAndNotNull(this._tokenExchangeService.getInviteToken())) {
        this.handleInvitedUserRole();
      } else {
        this.logout();
      }
    } else {
      this.handleGuestUserRole(userData.digitaalvlaanderen_smft_medewerker);
      this.handleAdminRole(userData.digitaalvlaanderen_smft_rol_3d);
    }
  }

  private handleInvitedUserRole() {
    this.isLoggedInUserIsAdmin = false;
    this.changeRole(UserRole.EXTERNAL_USER);
    this.access = true;
  }

  private handleAdminRole(rights: string[]) {
    if (CompareUtility.isDefinedAndNotNull(rights) && rights.length > 0) {
      rights.forEach(right => {
        const element = right
        if (element.includes('admin')) {
          this.changeRole(UserRole.ADMIN_AS_USER);
          this.isLoggedInUserIsAdmin = true;
        } else if (element.includes('user')) {
          if (this.isLoggedInUserIsAdmin === false) {
            this.changeRole(UserRole.USER);
          }
        }
      });
    }
  }

  private handleGuestUserRole(rights: string[]) {
    if (CompareUtility.isDefinedAndNotNull(rights) && rights.length > 0) {
      rights.forEach(right => {
        const element = right
        let guestUser = element.includes('Medewerker');
        if (guestUser === true) {
          this.isLoggedInUserIsAdmin = false;
          this.changeRole(UserRole.GUESTUSER);
        }
      });
    }
  }

  private checkUserRoles(rights: any) {
    if (CompareUtility.isDefinedAndNotNull(rights)) {
      if (rights.hasOwnProperty('digitaalvlaanderen_smft_rol_3d')) {
        this.access = rights.digitaalvlaanderen_smft_rol_3d.length > 0
      }
      if (rights.hasOwnProperty('digitaalvlaanderen_smft_medewerker')) {
        this.access = rights.digitaalvlaanderen_smft_medewerker.length > 0
      }
    }
  }
}
