import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ResizedEvent } from 'angular-resize-event';
import { fromEvent, map, debounceTime } from 'rxjs';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { HttpContactApiService } from 'src/app/contacts/http-contact-api.service';
import { UserDataService } from 'src/app/contacts/services/user-data.service';
import { UserLoginService } from 'src/app/services/login.service';
import { IContact } from 'src/app/types/participant.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';

@Component({
  selector: 'app-contact-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserContactComponent extends BaseAbstractComponent {

  @ViewChild('header') headerElementRef: ElementRef;
  @ViewChild('footer') footerElementRef: ElementRef;
  @ViewChild('search') filterElementRef: ElementRef;
  addedUserSearchKey: string = '';
  displayedColumns = ['icon', 'displayName', 'emailId', 'organization', 'options'];
  contentHeight: string;
  dataSource = new MatTableDataSource<IContact>([]);
  displayError: string = '';
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _userDataService: UserDataService,
    private _httpContactApiService: HttpContactApiService,
    private _loginService: UserLoginService) {
    super();
    this.setupSubscription();
  }

  ngAfterViewInit() {
    this.contentHeight = this.calculateHeight();
    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    // wait .5s between keyups to emit current value
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this.dataSource.filter = 'Filter';
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      }
      );
  }

  private getFilterPredicate() {
    return (row: IContact, filters: string) => {
      const matchFilter = [];
      if (CompareUtility.isNotEmpty(this.addedUserSearchKey)) {
        let displayNameFilter = row.displayName.toLowerCase().includes(this.addedUserSearchKey.toLowerCase().trim());
        matchFilter.push(displayNameFilter);
      }
      else {
        return true;
      }
      return matchFilter.every(Boolean);
    }
  }

  private setupSubscription() {
    const selectUser = this._userDataService.onUserSelected({
      next: (value) => {
        let data = this.dataSource.data;
        data.push(value);
        this.dataSource = new MatTableDataSource<IContact>(data);
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.dataSource.sort = this.sort;
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
    this.subscriptions.push(selectUser);

    const displayError = this._userDataService.onDisplayError({
      next: (value) => {
        this.displayError = '';
        if (value) {
          this.displayError = this.translate.instant('Contacts.User_contact.ErrorMessages.UserAlreadyExists');
        }
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
    this.subscriptions.push(displayError);
  }

  private calculateHeight() {
    return (parent.innerHeight - this.headerElementRef.nativeElement.offsetHeight - this.footerElementRef.nativeElement.offsetHeight) + 'px';
  }

  public onResized(event: ResizedEvent) {
    this.contentHeight = this.calculateHeight();
  }

  public removeContact(value: IContact) {
    let data = this.dataSource.data;
    let index = data.findIndex(x => x.participantId === value.participantId);
    if (index >= 0) {
      data.splice(index, 1);
      this.dataSource = new MatTableDataSource(data);
    }
  }

  public addPersons() {
    if (this.dataSource.data.length > 0) {
      this.dataSource.data.forEach(contact => {
        let dataModel = {
          entityId: this._loginService.entityId,
          entityName: this._loginService.entityNameOfParticipant,
          participantId: contact.participantId,
          workRelationId: contact.workRelationId,
          entityIdOfParticipant: contact.entityIdOfParticipant,
          entityNameOfParticipant: contact.entityNameOfParticipant,
          displayName: contact.displayName,
          email: contact.email,
          economicActor : contact.economicActor,
          type: contact.type
        }
        
        this._httpContactApiService.addUserContact(dataModel).subscribe({
          next: (value) => {
            this.dialog.closeAll();
            this._httpContactApiService.refreshUsers();
          },
          error: (err) => {
            this.handleError(err);
          },
          complete: () => { }
        });
      });
    }
  }

  public onClickEvent(event: any) {
    this._userDataService.showError(false);
  }
}

