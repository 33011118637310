import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IRoleDetails, roleInformation } from 'src/app/types/role-information.model';

@Component({
  selector: 'app-role-information',
  templateUrl: './role-information.component.html',
  styleUrls: ['./role-information.component.scss']
})
export class RoleInformationComponent implements OnInit {

  public displayedColumns = ['name', 'owner', 'contributor', 'reader'];
  public datasource: IRoleDetails[] = [];

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.datasource = roleInformation;
    this.datasource.forEach((role: IRoleDetails) => {
      role.name = this.translate.instant(role.translationString);
    });
  }
}
