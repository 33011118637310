import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CallbackComponent } from './callback/callback.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { ExchangeTokenGuard } from './auth/ExchangeTokenGuard';
import { environment } from 'src/environments/environment';
import { InviteCodeComponent } from './invite-code/invite-code.component';
import { NoRightsComponent } from './no-rights/no-rights.component';

@Injectable({ providedIn: 'root' })
export class CustomTitleResolver {
  resolve() {
    return Promise.resolve(environment.title);
  }
}

const routes: Routes = [
  { path: 'shares', component: LandingPageComponent, canActivate: [AutoLoginPartialRoutesGuard, ExchangeTokenGuard], pathMatch: 'full', title: CustomTitleResolver },
  { path: '', redirectTo: 'shares', pathMatch: 'full', title: CustomTitleResolver },
  { path: 'postlogout', redirectTo: 'shares', pathMatch: 'full', title: CustomTitleResolver },
  { path: 'callback', component: CallbackComponent, title: CustomTitleResolver },
  { path: 'inviteCode/:token', component: InviteCodeComponent, pathMatch: 'full', title: CustomTitleResolver },
  { path: 'norights', component: NoRightsComponent, pathMatch: 'full', title: CustomTitleResolver}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes), CommonModule],
  providers: [ExchangeTokenGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
