import { Injectable } from '@angular/core';
import { Observer, Subject, Subscription } from 'rxjs';
import { IContact } from 'src/app/types/participant.model';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private addUser$: Subject<IContact>;
  private displayError$: Subject<boolean>;

  constructor() {
    this.addUser$ = new Subject<IContact>();
    this.displayError$ = new Subject<boolean>();
  }
  
  addUser(value: IContact) {
    this.addUser$.next(value);
  }

  onUserSelected(observer: Observer<IContact>): Subscription {
    return this.addUser$.subscribe(observer);
  }

  showError(value: boolean) {
    this.displayError$.next(value);
  }

  onDisplayError(observer: Observer<boolean>): Subscription {
    return this.displayError$.subscribe(observer);
  }
}
