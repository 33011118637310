import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-upload-menu',
  templateUrl: './upload-file-menu.component.html',
  styleUrls: ['./upload-file-menu.component.scss']
})
export class UploadFileMenuComponent {

  @Output() public onFileSelected: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() public onFolderSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

  public fileSelected(event: any): void {
    const files: File[] = event.target.files;
    this.onFileSelected.emit(files);
    event.target.value = '';
  }

  public folderSelected(event: any): void {
    const files: File[] = event.target.files;
    this.onFolderSelected.emit(files);
    event.target.value = '';
  }
}
