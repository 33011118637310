import { Injectable } from '@angular/core';
import { Subject, Observer, Subscription } from 'rxjs';

export enum LandingPage {
  AllShares = 0,
  Contacts = 1,
  MyShares = 2
}

@Injectable({
  providedIn: 'root'
})
export class NavigationManagerService {

  public selectPage$: Subject<number>;

  constructor() { 
    this.selectPage$ = new Subject<number>();
  }

  public navigateToPage(value: number) {
    this.selectPage$.next(value);
  }

  public onPageNavigated(observe: Observer<number>): Subscription {
    return this.selectPage$.subscribe(observe);    
  }
}
