<div class="header-container" (resized)="onResized($event)">
  <div class="logoContainer">
    <img src="assets/images/logo.png" alt="{{'Image.LogoIcon' | translate}}" class="logoImg">
  </div>
  <div class="wrapper">
    <div class="userDetails" #userdetailscontainer [ngClass]="changeFlexDirection() ? 'flexUserDetails': ''">
      <div class="helloUsernameDiv" #hellocontainer>
        <div class="helloText">{{('Toolbar.Hello' | translate)}}</div>
        <div class="username" textOverflow [textValue]="name"
          [ngStyle]="{'max-width': changeFlexDirectionUsername() ? '50%' : '100%' }">{{name}}</div>
      </div>
      <div #entitycontainer textOverflow [textValue]="entityNameOfParticipant" class="entity" *ngIf="!isExternalPerson(); else external"
        [ngStyle]="{'max-width': changeFlexDirectionUsername() ? '50%' : '100%' }">({{entityNameOfParticipant}})</div>
      <ng-template #external>
        <div #entitycontainer textOverflow [textValue]="entityNameOfParticipant" class="entity"
          [ngStyle]="{'max-width': changeFlexDirectionUsername() ? '50%' : '100%' }">({{'Common.External' | translate}})
        </div>
      </ng-template>
    </div>
    <div class="loginOperations">
      <app-single-select class="langbox" *ngIf="access" [disabled]="!isLoggedInUserIsAdmin" [options]="adminRoles"
        [selected]="selectedRole" [width]="130" (selectedEvent)="onAdminRoleChanged($event)"></app-single-select>
      <app-single-select class="langbox" [options]="languages" [selected]="selectedLanguage" [width]="90"
        (selectedEvent)="onLanguageChanged($event)"></app-single-select>
      <button type="button" (click)="signOut()" class="signInBtn" *ngIf="isAuthenticated; else noAuth">
        {{'Toolbar.Sign_out'| translate}}
      </button>
    </div>
  </div>
  <ng-template #noAuth>
    <button type="button" (click)="signIn()" class="signInBtn">
      {{'Toolbar.Sign_in' | translate}}
    </button>
  </ng-template>
</div>