import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseAbstractComponent } from '../base-abstract/base-abstract.component';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent extends BaseAbstractComponent {

  constructor(private _dialog: MatDialog) { 
    super();
  }
}
