import { AuthorizationAbstractRequest, requestType } from "./requestUrl.model";
import { TokenExchangeService } from '../../services/token-exchange.service';
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class VerifyTokenRequest extends AuthorizationAbstractRequest {
    constructor(private _idtoken: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Post;
        this.body = this._idtoken;
        this.options = {
          responseType: 'text'
        }
        this.url = this.url.concat('/tokenverify');

    }
}

export class ACMIDMFlowRequest extends AuthorizationAbstractRequest {
    constructor(public token: string, public state: string, public codeChallenge: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        const headers = new HttpHeaders()
          .set('Authorization', 'Bearer ' + this.token)
          .set('Accept', 'text/plain, application/json, text/html, application/xhtml+xml, */*')
          .set('Content-Type', 'application/json, text/plain');
        this.options = {
          headers: headers,
          responseType: 'text'
        }
        const params = new HttpParams()
          .set('host', environment.auth0.baseDomain)
          .set('uri', "/op/v1/auth")
          .set('client_id', environment.auth0.clientId)
          .set('redirect_uri', environment.auth0.redirectUri)
          .set('response_type', "code")
          .set('scope', "vo profile digitaalvlaanderen_smft audience:server:client_id:" + environment.auth0.clientIdBE)
          .set('state', this.state)
          .set('code_challenge', this.codeChallenge)
          .set('code_challenge_method', "S256");
        this.url = this.url.concat("/proxy/direct?" + params.toString());
    }
}


export class TokenRequest extends AuthorizationAbstractRequest {
    constructor(public token: string, public code: string, public codeVerifier: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Post;
        this.body = new URLSearchParams();
        this.body.set("code", this.code);
        this.body.set("code_verifier", this.codeVerifier);
        this.body.set("grant_type", "authorization_code");
        this.body.set("redirect_uri", environment.auth0.redirectUri)
        this.body.set("client_assertion_type", "urn:ietf:params:oauth:client-assertion-type:jwt-bearer");
        this.body.set("client_assertion", this.token);
        this.body = this.body.toString();
        const headers = new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        this.options = {
            headers: headers,
            responseType: 'json'
        }
        this.url = this.url.concat("/proxy/direct?host=" + environment.auth0.baseDomain + "&"
        + "uri" + "=" + "/op/v1/token");
    }
}

export class GetTokenRequest extends AuthorizationAbstractRequest {
    constructor(public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers
        }
        this.url = this.url.concat('/token');
    }
}
