import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';

// manage all api calls 
@Injectable({
  providedIn: 'root'
})
export class FileApiOperationService {

  private _shareId: string;

  constructor(private _httpApiRequestService: HttpApiRequestService) { }


  public getFileId(fileName: string, fileSize: number, fileType: string): Subject<string> {
    let obs$: Subject<string> = new Subject<string>();
    this._httpApiRequestService.getFileIdToUpload(this._shareId, fileName, fileSize, fileType).subscribe({
      next: (id: any) => {
        obs$.next(id);
      },
      error: (error) => { obs$.error(error); },
      complete: () => { }
    });
    return obs$;
  }

  public uploadChunks(chunkData: any, fileId: string, chunkNumber: number, chunkSize: number, fileName: string): Subject<any> {
    let obs$: Subject<any> = new Subject<any>();
    this._httpApiRequestService.uploadChunk(chunkData, this._shareId, fileId, chunkNumber, chunkSize, fileName).subscribe({
      next: (res: any) => {
        obs$.next(res);
      },
      error: (error) => { obs$.error(error); },
      complete: () => { }
    });
    return obs$;
  }

  public chunkedFileUploadFinish(fileId: any, fileName: string): Subject<any> {
    let obs$: Subject<any> = new Subject<any>();
    this._httpApiRequestService.chunkedFileUploadFinish(this._shareId, fileId, fileName).subscribe({
      next: (res: any) => {
        obs$.next(res);
      },
      error: (error) => { obs$.error(error); },
      complete: () => { }
    });
    return obs$;
  }

  public set shareId(id: string) {
    this._shareId = id;
  }

  public getUploadedChunks(fileId: string): Subject<any> {
    let obs$: Subject<any> = new Subject<any>();
    this._httpApiRequestService.getUploadedChunks(this._shareId, fileId).subscribe({
      next: (res: any) => {
        obs$.next(res);
      },
      error: (error) => { obs$.error(error); },
      complete: () => { }
    });
    return obs$
  }
}
