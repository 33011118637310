<div>
    <ngx-file-drop (onFileDrop)="dropped($event)" dropZoneClassName="dropZone" contentClassName="contentDropZone"
        *ngIf="!isFileSelected(); else transfer">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <label class="addFilesLbl">{{'Sidebar.Add_File_txt_line1' | translate}}</label>
            <label class="addfiles">{{'Sidebar.Add_File_txt_line2'|translate}}</label>
            <span class="uploadTypesContainer">
                <img src="assets/images/addIcon.png" mat-icon-button [matMenuTriggerFor]="uploadMenu"
                    matTooltip="{{'Common.Upload' | translate}}">
            </span>
        </ng-template>
    </ngx-file-drop>
    <ng-template #transfer>
        <ngx-file-drop (onFileDrop)="dropped($event)" dropZoneClassName="transferDropZone" contentClassName="transferDropWrapper">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="dropZoneWrapper" [ngClass]="{'transfer--error': !validateFiles(), 'transfer--success': validateFiles()}">
                <div class="transferWrapper">
                    <div *ngFor="let transfer of files">
                        <div class="fileWrapper">
                            <span class="transfer">
                                <div class="fileName" #textElement textOverflow [textValue]="transfer.fileName" tooltipPosition="right">{{transfer.fileName}}
                                </div>
                                <div class="fileSize">{{transfer.size | customFileSize}}</div>
                                <div class="uploadIconContainer" *ngIf="isUploadInProgress(transfer)">
                                    <img src="assets/images/pauseButton.png" id="icon"
                                        (click)="pauseFileUpload(transfer)">
                                </div>
                                <div class="uploadIconContainer" *ngIf="isUploadFailed(transfer)">
                                    <img src="assets/images/resumeButton.png" id="icon"
                                        (click)="resumeFileUpload(transfer)">
                                </div>
                                <img src="assets/images/trash.png" alt="{{'Images.DeleteFiles' | translate}}"
                                    class="pointer icons" (click)="removeFile(transfer)">
                            </span>
                            <app-progress-bar [file]="transfer"></app-progress-bar>
                        </div>
                    </div>
                </div>
                <div mat-icon-button [matMenuTriggerFor]="uploadMenu">
                    <button class="addButtonWrapper">
                        <img src="assets/images/addFileIcon.png">
                        <label>{{'Sidebar.Add_Files_btn'|translate}}</label>
                    </button>
                </div>
            </div>
            </ng-template>
        </ngx-file-drop>
        <label *ngIf="!validateFiles()" class="fileLimitError">{{'ErrorMessages.FileLimitError' | translate}}</label>
    </ng-template>
</div>
<mat-menu #uploadMenu="matMenu">
    <app-upload-menu (onFileSelected)="onFileSelected($event)" (onFolderSelected)="onFolderSelected($event)"></app-upload-menu>
</mat-menu>
