<div class="outer-container">
    <div class="header">
        <div class="headerWrapper">
            <div class="titleContainer" style="padding-bottom: 0.5vh;">
                <span class="titleLbl">{{'Participant.AddParticipantsLbl' | translate}}</span>
            </div>
            <form class="inputBox" [ngClass]="disableNameField ? 'not-allowed-icon': ''">
                <mat-form-field #searchField appearance="outline" floatLabel="always" (click)="nameClicked($event)"
                    [matMenuTriggerFor]="menu" [ngClass]="disableNameField ? 'disableInput': ''">
                    <mat-label class="floatingLabel">{{'Participant.Name' | translate}}</mat-label>
                    <input name="participantid" matInput placeholder="{{'Participant.Name_placeholder' | translate }}"
                        [(ngModel)]="participantid" maxlength="20" (keyup)="keyup($event)" autocomplete="off" />
                </mat-form-field>
            </form>
            <mat-menu #menu="matMenu">
                <div *ngIf="searchedParticipants.length > 0; else NoRecordsFound">
                    <table mat-table [dataSource]="searchedParticipants" [style.width.px]="width"
                        class="participant-table">
                        <ng-container matColumnDef="searchIcon">
                            <td mat-cell *matCellDef="let element">
                                <div [ngSwitch]="element.type" class="iconContainer">
                                    <img *ngSwitchCase="'SYSTEM'" class="icon-container"
                                        src="assets/images/systemActiveIcon.png"
                                        matTooltip="{{'Common.System' | translate}}">
                                    <img *ngSwitchCase="'USER'" class="icon-container"
                                        src="assets/images/activeParticipant.png"
                                        matTooltip="{{'Common.User' | translate}}">
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="searchName">
                            <td mat-cell *matCellDef="let element as IContact">
                                <span>{{element.displayName}}</span>
                        </ng-container>
                        <ng-container matColumnDef="searchEmailAddress">
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.email}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="searchOrganization">
                            <td mat-cell *matCellDef="let element">
                                <span>{{element.entityNameOfParticipant}}</span>
                            </td>
                        </ng-container>

                        <tr mat-row style="cursor: pointer;" *matRowDef="let row; columns: displayedColumns;"
                            (click)="onParticipantSelected(row)">
                        </tr>
                    </table>
                </div>
                <ng-template #NoRecordsFound>
                    <div class="NoResultFound" [style.width.px]="width">
                        <mat-label style="padding-left:2vh;">{{'Common.NoResultFound_txt' | translate}}</mat-label>
                    </div>
                </ng-template>
            </mat-menu>
            <section class="searchBar">
                <div class="searchContainer">
                    <input #search type="text" name="searchKey" placeholder="{{'Participant.Search' | translate}}"
                        [(ngModel)]="searchKey" autocomplete="off" maxlength="25" />
                </div>
            </section>
            <section class="participants-container mat-elevation-z8" tabindex="0">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Icon Column -->
                    <ng-container matColumnDef="icon">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'Participant.Type' | translate}}
                            <app-filter class="typeFilter" [filterOption]="typeFilterOptions"
                                (selectAllEvent)="selectAll()" (applyFilter)="filterActions($event)"></app-filter>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div [ngSwitch]="element.participantType" class="iconContainer">
                                <img *ngSwitchCase="'SYSTEM'" class="icon-container"
                                    src="assets/images/systemActiveIcon.png"
                                    matTooltip="{{'Common.System' | translate}}">
                                <img *ngSwitchCase="'USER'" class="icon-container"
                                    src="assets/images/activeParticipant.png"
                                    matTooltip="{{'Common.User' | translate}}">
                            </div>
                        </td>
                    </ng-container>

                    <!-- Participant Name Column -->
                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Participant.Name' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            <div textOverflow [textValue]="element.displayName">
                                {{element.displayName}}
                            </div>
                        </td>
                    </ng-container>

                    <!-- Participant Email Column -->
                    <ng-container matColumnDef="emails">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Participant.Email_Address' |
                            translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div textOverflow [textValue]="element.email">
                                {{element.email}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="organization">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Common.Organization' |
                            translate}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div textOverflow [textValue]="element.entityName">
                                {{element.entityName}}
                            </div>
                        </td>
                    </ng-container>


                    <!-- Role Column -->
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>
                            <span class="alignCenter">{{'Participant.Role' | translate}}
                                <span class="alignCenter" mat-icon-button [matMenuTriggerFor]="menu">
                                    <img class="informationIcon" src="assets/images/informationIcon.png">
                                </span>
                                <app-filter [filterOption]="roleFilterOptions" (selectAllEvent)="selectAll()"
                                    (applyFilter)="filterActions($event)"></app-filter>
                                <mat-menu #menu="matMenu">
                                    <app-role-information class="role-information-container"></app-role-information>
                                </mat-menu>
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="roleContainer">
                                <span class="roleLable"> {{ getRole(element.rightType) }}
                                </span>
                                <div [ngClass]="disableEditRole ? 'not-allowed-icon' : ''">
                                    <img class="downArrow" *ngIf="isAllowedToChangeRole(element)"
                                        [ngClass]="disableEditRole ? 'disableIcon' : ''" [matMenuTriggerFor]="menu"
                                        #icon src="assets/images/downArrow.png">
                                </div>
                            </div>
                            <mat-menu #menu="matMenu" xPosition="before">
                                <div [ngSwitch]="systemOrAdditionalContact(element)">
                                    <div class="role-menu-container" *ngSwitchCase="false">
                                        <span class="role-menu-item"
                                            [ngClass]="item.displayValue === element.rightType ? 'highLightRole': ''"
                                            *ngFor="let item of roles" (click)="updateRole(item.id, element)">
                                            <mat-label class="roleDisplayValue">{{item.displayValue}}</mat-label>
                                        </span>
                                    </div>
                                    <div class="role-menu-container" *ngSwitchCase="true">
                                        <span class="role-menu-item"
                                            [ngClass]="item.displayValue === element.rightType ? 'highLightRole': ''"
                                            *ngFor="let item of systemRoles" (click)="updateRole(item.id, element)">
                                            <mat-label class="roleDisplayValue">{{item.displayValue}}</mat-label>
                                        </span>
                                    </div>
                                </div>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <!-- Options Column -->
                    <ng-container matColumnDef="options">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <div [ngClass]="element.disableTrashIcon ? 'not-allowed-icon' : ''">
                                <div class="buttonWrapper">
                                    <div class="trash-button">
                                        <img src="assets/images/trash.png"
                                            matTooltip="{{'Common.Trash_btn' | translate}}"
                                            *ngIf="isAllowedToChangeRole(element)" mat-icon-button
                                            (click)="deleteParticipant(element)"
                                            [ngClass]="element.disableTrashIcon ? 'disableIcon' : ''">
                                    </div>
                                    <div class="img-button" *ngIf="isParticipantSystem(element)">
                                        <img src="assets/images/copy_sftp_url.png"
                                            [ngClass]="element.disableTrashIcon? 'disableIcon' : ''" class="sftpIcon"
                                            matTooltip="{{'Common.Sftp_copy_button' | translate}}"
                                            (click)="copySftpUrl(element)">
                                    </div>
                                    <div class="optionIconContainer" *ngIf="element.externalContact">
                                        <img src="assets/images/options.png" mat-icon-button
                                            matTooltip="{{'Images.Options' | translate}}" [matMenuTriggerFor]="menu">
                                        <mat-menu #menu="matMenu" xPosition="before">
                                            <div class="optionWrapper">
                                                <div class="externalOptionMenu" (click)="copyToClipboard(element.token)">
                                                    <img src="assets/images/envelope.png" class="icons">
                                                    <span>{{'Common.Invite_code'| translate}}: {{element.token}}</span>
                                                </div>
                                                <div class="externalOptionMenu" (click)="copyToClipboard(element.participantId)">
                                                    <img src="assets/images/person.png" class="icons">
                                                    <span>{{'Participant.UserID'| translate}}: {{element.participantId}}</span>
                                                </div>
                                            </div>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="participantsColumns; sticky: true"></tr>
                    <tr mat-row class="pointer" *matRowDef="let row; columns: participantsColumns;"></tr>
                </table>
            </section>
        </div>
        <div class="error-wrapper">
            <app-error-message [displayError]="displayError"></app-error-message>
        </div>
    </div>
    
    <div class="dialogActionsContainer" *ngIf="isAddWorkflow()">
        <button class="closeButton" (click)="previousButtonClick()">
            {{'Common.Previous_btn'| translate}}</button>
        <button cdkFocusInitial class="activeButton" (click)="configureSettings()">
            {{'Participant.ConfigureSettings_btn'| translate}}</button>
    </div>
</div>