import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogModel } from '../../types/dialog-model';
import { UserLoginService } from 'src/app/services/login.service';
import { RefreshBackgroundService } from 'src/app/services/refresh-background.service';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogModel,
    private _loginService: UserLoginService,
    private _refreshBackgroundService: RefreshBackgroundService
  ) { }

  onCloseClick() {
    this.dialogRef.close();
  }

  logout() {
    this._loginService.logout();
  }

  refresh() {
    this._refreshBackgroundService.refreshBackground();
    this.dialogRef.close();
  }
}


