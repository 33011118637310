import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from 'src/environments/environment';
import { AllshareComponent } from '../allshare/component/allshare.component';
import { BaseAbstractComponent } from '../base-abstract/base-abstract.component';
import { ContactsComponent } from '../contacts/contacts.component';
import { DynamicComponentDirective } from '../directives/dynamic-component.directive';
import { MyshareComponent } from '../myshare/component/myshare.component';
import { UserLoginService } from '../services/login.service';
import { LandingPage, NavigationManagerService } from '../services/navigation-manager.service';
import { BackgroundName, RefreshBackgroundService } from '../services/refresh-background.service';
import { UserRole } from '../types/user-roles.model';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends BaseAbstractComponent implements AfterViewInit {

  @ViewChild(DynamicComponentDirective, { static: true }) public dynamicComponent: DynamicComponentDirective;
  sidebarCompleteLayout: boolean;

  constructor(public oidcSecurityService: OidcSecurityService,
    private _loginService: UserLoginService,
    private bnIdle: BnNgIdleService,
    private _refreshBackgroundService: RefreshBackgroundService,
    private _navigationManager: NavigationManagerService) {
    super();
  }

  ngAfterViewInit() {
    this.setupSubscription();
  }

  private setupSubscription() {
    const initiateLandingPage = this._loginService.initiateLandingPage({
      next: (value: boolean) => {
        if (value) {
          if (this._loginService.isLoggedInUserIsAdmin === true) {
            // import contact module
            import('../contacts/contacts.module').then(m => { m.ContactsModule });
          }
        }
        this.loadMyShareComponent();
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(initiateLandingPage);

    const navigation = this._navigationManager.onPageNavigated({
      next: (x: number) => {
        if (x === LandingPage.Contacts) {
          this.loadContactComponent();
        } else if (x === LandingPage.MyShares) {
          this.loadMyShareComponent();
        } else if (x === LandingPage.AllShares) {
          this.loadAllShareComponent();
        }
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(navigation);

    const onAdminRoleChanged = this._loginService.onAdminRoleChanged({
      next: (value) => {
        if (value === UserRole.ADMIN) {
          this.sidebarCompleteLayout = true;
          this.loadAllShareComponent();
        } else if (value === UserRole.GUESTUSER) {
          this.sidebarCompleteLayout = true;
          this.loadMyShareComponent();
        } else if (value === UserRole.ADMIN_AS_USER || value === UserRole.USER) {
          this.sidebarCompleteLayout = false;
          this.loadMyShareComponent();
        }
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(onAdminRoleChanged);

    const idleUser = this.bnIdle.startWatching(environment.timeOutSeconds).subscribe((res) => {
      if (res) {
        this._loginService.logout();
      }
    });
    this.subscriptions.push(idleUser);
  }

  public loadMyShareComponent() {
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent<MyshareComponent>(MyshareComponent);
    this._refreshBackgroundService.setSelectedBackgroundPage(BackgroundName.MyShares);
  }

  public loadAllShareComponent() {
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent<AllshareComponent>(AllshareComponent);
    this._refreshBackgroundService.setSelectedBackgroundPage(BackgroundName.AllShares);
  }

  public loadContactComponent() {
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent<ContactsComponent>(ContactsComponent);
    this._refreshBackgroundService.setSelectedBackgroundPage(BackgroundName.Contacts);
  }
}
