import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseAbstractComponent } from '../../base-abstract/base-abstract.component';
import { HttpProcessorService } from '../../http/http-processor.service';
import { TokenExchangeService } from '../../services/token-exchange.service';
import { DeleteShareRequest } from 'src/app/http/request-urls/shareAndFileRequestUrl';
import { ShareDetailsComponent } from '../../share-details/share-details.component';
import { IShare, ShareWorkflow, Workflow } from '../../types/group-share.model';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { DialogModel } from '../../types/dialog-model';
import { FileSizeManagerService } from '../../services/file-size-manager.service';
import { HttpApiRequestService } from '../../http/http-api-request.service';
import { ResizedEvent } from 'angular-resize-event';
import { IParticipant } from '../../types/participant.model';
import { CompareUtility } from '../../utilities/compare.utility';
import { debounceTime, fromEvent, map } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../common-ui/snack-bar/service/snack-bar.service';
import { ShareService } from '../services/share.service';
import { UserLoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-myshare',
  templateUrl: './allshare.component.html',
  styleUrls: ['./allshare.component.scss']
})
export class AllshareComponent extends BaseAbstractComponent implements OnInit, AfterViewInit {

  displayedColumns = ['shareName', 'creationDateTime', 'expirationDate', 'participants', 'shareSizeMB', 'options'];
  dataSource = new MatTableDataSource<IShare>([]);
  contentHeight: string = this.calculateHeight();
  isLoadingData: boolean = false;
  pageNumber: number = 1;
  searchKey: string = '';
  shareParticipantsColumns= ['participantIcon', 'displayName', 'entity'];
  shareParticipants : IParticipant[]=[];

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('search') filterElementRef: ElementRef;

  constructor(private httpProcessorService: HttpProcessorService,
    private _httpApiRequestService: HttpApiRequestService,
    private tokenExchangeService: TokenExchangeService,
    private _fileSizeManagerService: FileSizeManagerService,
    private _shareService: ShareService,
    private _loginService: UserLoginService,
    private _snackBarService: SnackBarService) {
    super();
    this.setupSubscription();
  }

  ngOnInit(): void {
    this._httpApiRequestService.refreshAllShares(this._loginService.entityId);
  }

  ngAfterViewInit() {
    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    // wait .5s between keyups to emit current value
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this.pageNumber = 0;
          this._httpApiRequestService.loadNextAllShares(this.pageNumber, result.trim(), this._loginService.entityId).subscribe({
            next: (result: IShare[]) => {
              this.dataSource.data = result;
              this.pageNumber = 1;
            },
            error: (error) => { this.handleError(error) },
            complete: () => { }
          });
        },
        error: (error) => { this.handleError(error) },
        complete: () => { }
      }
      );
  }

  onResized(event: ResizedEvent) {
    this.contentHeight = this.calculateHeight();
  }

  calculateHeight() {
    return (parent.innerHeight - 280) + 'px';
  }

  openShare(share: IShare) {
    this._httpApiRequestService.getShare(share.id).subscribe({
      next: (result: IShare) => {
        let shareModel: ShareWorkflow = new ShareWorkflow();
        shareModel.share = result;
        shareModel.workFlow = Workflow.EDIT;
        shareModel.files = [];
        shareModel.selectedTabIndex = 0;
        this.dialog.open(ShareDetailsComponent, {
          panelClass: 'dialog-container',
          height: '625px',
          width: '1000px',
          data: shareModel
        });
      },
      error: (error: HttpErrorResponse) => { this.handleError(error); },
      complete: () => { },
    });
  }

  public deleteShare(shareId: string) {
    let request = new DeleteShareRequest(shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        let index = this.dataSource.data.findIndex(x => x.id === shareId);
        if (index >= 0) {
          this.dataSource.data.splice(index, 1);
          this.refreshTableData(this.dataSource.data);
        }
      },
      error: (error) => { this.handleError(error) },
      complete: () => { }
    })
  }

  openDialog(shareId: string): void {
    let data = new DialogModel();
    this.translate.get('Dialogs.DeleteShareDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.DeleteShareDialog.Message').subscribe((x: string) => data.content = x);
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '225px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == true) {
        this.deleteShare(shareId);
      }
    });
  }

  downloadAllFiles(share: IShare): void {
    let snackbarData = this.translate.instant('Common.Downloading');
    this._snackBarService.openSnackBar(share.id, snackbarData.concat(' ', share.shareName, '.zip'));
    this._httpApiRequestService.dowloadAllFilesOfShare(share.id).subscribe({
      next: (result: any) => {
        let hyperlink = document.createElement('a');
        hyperlink.href = window.URL.createObjectURL(result as Blob);
        hyperlink.download = share.shareName.concat('.zip');
        hyperlink.click();
        this._snackBarService.closeSnackBarRequest(share.id);
      },
      error: (error) => {
        this._snackBarService.closeSnackBarRequest(share.id);
      },
      complete: () => { }
    });
  }

  private setupSubscription() {
    const refreshSharesSubscription = this._httpApiRequestService.onAllSharesRefreshed({
      next: (x) => {
        this.searchKey = '';
        this.dataSource.data = x;
        this.pageNumber = 1;
        this.refreshTableData(this.dataSource.data);
        this.dataSource.sort = this.sort;
      },
      error: err => { this.handleError(err); },
      complete() { },
    });
    this.subscriptions.push(refreshSharesSubscription);

    const refreshShareSubscription = this._httpApiRequestService.onShareRefreshed({
      next: (result: IShare) => {
        let index = this.dataSource.data.findIndex(x=> x.id === result.id);
        this.dataSource.data[index] = result;
        this.refreshTableData(this.dataSource.data);
      },
      error: err => { this.handleError(err); },
      complete() { },
    });
    this.subscriptions.push(refreshShareSubscription) 
  }

  formatFileSize(fileSizeMB: number): number {
    return this._fileSizeManagerService.formatFileSize(fileSizeMB);
  }

  isCurrentUserNotAnOwnerOfAShare(value: IShare): boolean {
    return this._shareService.isCurrentUserNotAnOwnerOfAShare(value);
  }

  getParticipantNames(shareModel: IShare) {
    let participants;
    if (CompareUtility.isDefinedAndNotNull(shareModel.id)) {
      this._httpApiRequestService.loadParticipants(shareModel.id, '').subscribe({
        next: (result: IParticipant[]) => {
          shareModel.participants = result;
        },
        error: (error) => { this.handleError(error) },
        complete: () => { }
      });
    }
    return participants;
  }

  openShareWithParticipantTab(share: IShare){
    this._httpApiRequestService.getShare(share.id).subscribe({
      next: (result: IShare) => {
        let shareModel: ShareWorkflow = new ShareWorkflow();
        shareModel.share = result;
        shareModel.workFlow = Workflow.EDIT;
        shareModel.selectedTabIndex = 1;
        this.dialog.open(ShareDetailsComponent, {
          panelClass: 'shared-details-dialog-container',
          height: '625px',
          width: '1000px',
          data: shareModel
        });
      },
      error: (error) => { this.handleError(error); },
      complete: () => { },
    });
  }

  openShareWithSettingsTab(share: IShare){
    this._httpApiRequestService.getShare(share.id).subscribe({
      next: (result: IShare) => {
        let shareModel: ShareWorkflow = new ShareWorkflow();
        shareModel.share = result;
        shareModel.workFlow = Workflow.EDIT;
        shareModel.selectedTabIndex = 2;
        this.dialog.open(ShareDetailsComponent, {
          panelClass: 'shared-details-dialog-container',
          height: '625px',
          width: '1000px',
          data: shareModel
        });
      },
      error: (error) => { this.handleError(error); },
      complete: () => { },
    });
  }

  onScrollDown(event: any) {
    this.isLoadingData = true;
    this._httpApiRequestService.loadNextAllShares(this.pageNumber, this.searchKey.trim(), this._loginService.entityId).subscribe({
      next: (result: IShare[]) => {
        this.appendRecords(result);
        setTimeout(() => {
          this.isLoadingData = false;
        }, 500);
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
  }
  appendRecords(result: IShare[]) {
    if (CompareUtility.isDefinedAndNotNull(result) && result.length > 0) {
      this.dataSource.data = this.dataSource.data.concat(result);
      this.pageNumber = this.pageNumber + 1;
    }
  }

  disableDownloadButton(share: IShare){
    return share.shareSizeMB == '0';
  }

  private refreshTableData(result: IShare[]) {
    this.dataSource.data = result;
  }
}
