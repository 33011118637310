import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, map } from 'rxjs';
import { AddPersonDialogComponent } from '../dialogs/add-person/add-person.component';
import { HttpApiRequestService } from '../http/http-api-request.service';
import { ContactOperation, IExternalContact } from '../types/participant.model';
import { CompareUtility } from '../utilities/compare.utility';
import { UserLoginService } from './login.service';

@Injectable()
export class PersonDataService {

  public personData: IExternalContact;
  public personForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddPersonDialogComponent>,
    private _httpApiRequestService: HttpApiRequestService,
    private _loginService: UserLoginService,
    private formBuilder: FormBuilder) {

  }

  initialiseValidators() {
    this.personForm = this.formBuilder.group({
      name: [
        this.personData.name,
        {
          validators: [Validators.required]
        }
      ],
      emailAddress: [
        this.personData.emailAddress,
        {
          validators: [Validators.required],
          asyncValidators: [this.userExistsValidator()]
        }
      ],
      languageCode: [
        this.personData.languageCode,
        {
          validators: [Validators.required]
        }
      ]
    });
  }

  userExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return this._httpApiRequestService.searchExternalContacts(0, control.value.trim(), this._loginService.entityId)
        .pipe(
          map(user => {
            if (CompareUtility.isDefinedAndNotNull(user) && user.length > 0 && this.personData.operation == ContactOperation.Add) {
              control.setErrors({ userExists: true });
              return { userExists: true };
            }
            return null;
          })
        );
    }
  }

  initialiseData(data: IExternalContact) {
    this.personData = data;
  }

  getSystemData(): IExternalContact {
    let data = this.personData;
    return data;
  }

  savePersonDetails(): Subject<any> {
    let obs$ = new Subject<any>;
    this.personForm.markAllAsTouched();
    if (!this.personForm.invalid) {
      if (this.personData.operation == ContactOperation.edit) {
        this._httpApiRequestService.updateExternalContact(this.personData).subscribe({
          next: (result) => {
            obs$.next(result);
          },
          error: (error) => obs$.error(error),
          complete: () => { }
        })
      } else if (this.personData.operation == ContactOperation.Add) {
        if (!this.personForm.invalid) {
          this._httpApiRequestService.inviteExternalContact(this.personData, this._loginService.entityId).subscribe({
            next: (result) => {
              obs$.next(result);
            },
            error: (error) => obs$.error(error),
            complete: () => { }
          })
        }
      }
    } else {
      obs$.next(false);
    }
    return obs$;
  }
}
