import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFileSize',
})
export class CustomFileSizePipe implements PipeTransform {

  transform(value: number): string {
    if (!+value) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(value) / Math.log(k));
    const size: number = (value / Math.pow(k, i));
    if ((size % 1) > 0.001) {
      return `${(size.toFixed(2))} ${sizes[i]}`;
    }
    else return `${(size).toFixed(0)} ${sizes[i]}`;
  }
}


