import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { IShare } from 'src/app/types/group-share.model';
import { SharedDetailsService } from '../shared-details.service';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { IActionLog, IEventType } from 'src/app/types/action-log.model';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { ArrayOperationsUtility } from 'src/app/utilities/arrayOperations.utility';
import { MatSort } from '@angular/material/sort';
import { debounceTime, fromEvent, map } from 'rxjs';
import { FilterOption } from 'src/app/common-ui/filter/model/filter-options.model';

@Component({
  selector: 'app-action-log',
  templateUrl: './action-log.component.html',
  styleUrls: ['./action-log.component.scss']
})
export class ActionLogComponent extends BaseAbstractComponent implements AfterViewInit {

  displayedColumns = ['icon', 'who', 'action', 'context', 'timestamp'];
  dataSource: MatTableDataSource<IActionLog>;
  shareDetails: IShare;
  displayNameFilterOptions: FilterOption[] = [];
  eventDescriptionFilterOptions: FilterOption[] = [];

  searchKey: string = '';

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('search') filterElementRef: ElementRef;

  constructor(public datePipe: DatePipe,
    private _sharedDetailsService: SharedDetailsService,
    private _httpApiRequestService: HttpApiRequestService) {
    super();
    this.dateFormat = this.translate.instant('DateFormat') + ' - h:mm';
  }

  ngAfterViewInit() {
    this.setupSubscription();

    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    // wait .5s between keyups to emit current value
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this._httpApiRequestService.filterActionLogs(this.shareDetails.id, result.trim()).subscribe({
            next: (result: IActionLog[]) => {
              this.dataSource.data = result;
            },
            error: (error) => { this.handleError(error); },
            complete: () => { }
          });
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      }
      );
  }

  public onDownloadClicked() {
    this._httpApiRequestService.downloadActionLogs(this.shareDetails.id).subscribe({
      next: (result: any) => {
        let hyperlink = document.createElement('a');
        hyperlink.href = window.URL.createObjectURL(result as Blob); 
        hyperlink.download = this.shareDetails.shareName.concat('-actionlog-', this.getTodate(), '.csv');
        hyperlink.click();
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
  }

  public getIconSourcePath(eventName: IEventType): string {
    let srcPath = '';
    switch (eventName) {
      case IEventType.CREATE_SHARE_EVENT:
        srcPath = 'assets/images/activeFolder.png';
        break; 
      case IEventType.UPDATE_SHARE_EVENT:
        srcPath = 'assets/images/shareSettings.png';
        break;
      case IEventType.DELETE_SHARE_EVENT:
        srcPath = 'assets/images/trash-active.png';
        break;
      case IEventType.CREATE_RIGHT_EVENT:
        srcPath = 'assets/images/activeParticipant.png';
        break;
      case IEventType.UPDATE_RIGHT_EVENT:
        srcPath = 'assets/images/participantRole.png';
        break;
      case IEventType.DELETE_RIGHT_EVENT:
        srcPath = 'assets/images/trash-active.png';
        break;
      case IEventType.ADD_FILE_EVENT:
        srcPath = 'assets/images/attachment-active.png';
        break;
      case IEventType.UPDATE_FILE_EVENT:
        srcPath = 'assets/images/attachment-active.png';
        break;
      case IEventType.DELETE_FILE_EVENT:
        srcPath = 'assets/images/trash-active.png';
        break;
      case IEventType.DOWNLOAD_FILE_EVENT:
        srcPath = 'assets/images/download.png';
        break;
      default:
        break;
    }
    return srcPath;
  }

  public getActionName(eventName: string) : string {
    let fullEventName = 'ActionLog.Actions.';
    return this.translate.instant(fullEventName.concat(eventName));
  }

  public shareContainsActionLogs(): boolean {
    return this.dataSource?.data?.length > 0;
  }

  public filterActions(filterOptions: FilterOption[]) {
    this.dataSource.filter = 'Filter';
  }

  public selectAll() {
    this.dataSource.filter = 'all';
  }

  private getTodate(): string {
    return this.datePipe.transform(new Date(), 'ddMMyy');
  }

  private initializeFilterActions() {
    this.displayNameFilterOptions = this.getFilterOptions(this.dataSource.data.map(x => x.displayName));
    this.eventDescriptionFilterOptions = this.getFilterOptions(this.dataSource.data.map(x => this.getActionName(x.eventType)));
  }

  private getFilterOptions(data: string[]) : FilterOption[] {
    let options: FilterOption[] = [];
    data = ArrayOperationsUtility.removeDuplicates(data);
    data.forEach(x => { options.push({ title: x, value: x, activated: false, isTitleTranslated: true }); });
    return options;
  }

  /* Apply filter on column */
  private getFilterPredicate() {
    return (row: IActionLog, filters: string) => {
      const matchFilter = [];

      // Fetch data from row
      const displayName = row.displayName;
      const eventDescription = this.getActionName(row.eventType);

      // Fetch activated filter options
      const displayNameFilterOptions = this.displayNameFilterOptions.filter(x => x.activated);
      const eventDescriptionFilterOptions = this.eventDescriptionFilterOptions.filter(x => x.activated); 

      if (displayNameFilterOptions.length === 0 && eventDescriptionFilterOptions.length === 0) {
        return true;
      }

      let customFilterDN = false;
      let customFilterED = false;

      if (displayNameFilterOptions.length > 0) {
        customFilterDN = displayNameFilterOptions.some(filter => filter.value === displayName);
        matchFilter.push(customFilterDN);
      }

      if (eventDescriptionFilterOptions.length > 0) {
        customFilterED = eventDescriptionFilterOptions.some(filter => filter.value === eventDescription)
        matchFilter.push(customFilterED);
      }
      return matchFilter.every(Boolean);
    };
  }

  private setupSubscription() {
    const showShareDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: (x) => {
        this.shareDetails = x;
        this._httpApiRequestService.refreshActionLogs(this.shareDetails.id);
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(showShareDetails);

    const actionLogsRefreshed = this._httpApiRequestService.onActionLogsRefreshed({
      next: (result: IActionLog[]) => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.initializeFilterActions();
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(actionLogsRefreshed);
  }
}
