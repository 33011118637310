export enum IEventType {
    CREATE_SHARE_EVENT= 'CREATE_SHARE_EVENT',
    UPDATE_SHARE_EVENT= 'UPDATE_SHARE_EVENT',
    DELETE_SHARE_EVENT= 'DELETE_SHARE_EVENT',
    CREATE_RIGHT_EVENT = 'CREATE_RIGHT_EVENT',
    UPDATE_RIGHT_EVENT = 'UPDATE_RIGHT_EVENT',
    DELETE_RIGHT_EVENT = 'DELETE_RIGHT_EVENT',
    ADD_FILE_EVENT = 'ADD_FILE_EVENT',
    UPDATE_FILE_EVENT = 'UPDATE_FILE_EVENT',
    DELETE_FILE_EVENT = 'DELETE_FILE_EVENT',
    DOWNLOAD_FILE_EVENT = 'DOWNLOAD_FILE_EVENT'
}

export interface IActionLog {
    id: string,
    shareId: string,
    displayName: string,
    eventType: IEventType,
    eventDescription: string,
    context: string,
    timestamp: string
}
