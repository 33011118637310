import { Injectable } from "@angular/core";
import { NotificationFactory } from "../model/abstractNotification";
import { Role } from "src/app/types/participant.model";

@Injectable({
    providedIn: 'root'
})
export class NotificationHandlerService {

    public getDefaultPreferences(shareId: string, userId: string, role: Role, workRelationId: string) {
        let _notificationFactory: NotificationFactory = new NotificationFactory();
        let _abstractNotification = _notificationFactory.getNotificationFactory(role);
        return _abstractNotification.getDefaultNotifications(shareId, userId, workRelationId);
    }
}