<div class="wrapper">
  <div class="outerContainer">
    <div class="titleContainer">{{data.title}}</div>
    <div class="contentContainer">
      <mat-label>{{data.content}}</mat-label>
    </div>
    <div mat-dialog-actions class="dialogActionsContainer">
      <button mat-dialog-close cdkFocusInitial class="stayOnPageButton">
        {{'Dialogs.SignOutDialog.StayOnPageButton'| translate}}</button>
      <button [mat-dialog-close]="true" cdkFocusInitial class="signOutButton">
        {{'Toolbar.Sign_out'| translate}}</button>
    </div>
  </div>
</div>