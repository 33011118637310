import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: 'https://dev-rkoy2ootafxv25eb.eu.auth0.com',
        redirectUrl: window.location.origin + "/callback",
        postLogoutRedirectUri: window.location.origin + "/postlogout",
        clientId: 'soiDsng2akoLYBbIAnX2xElc1Qr2MPCA',
        scope: 'openid profile offline_access ',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,

        customParamsRefreshTokenRequest: {
          scope: 'openid profile offline_access',
          client_assertion_type: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer'
        },
      }
  })],
})
export class AuthConfigDefaultModule {}
