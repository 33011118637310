import { Injectable } from '@angular/core';
import { HttpApiRequestService } from '../http/http-api-request.service';
import { UserLoginService } from './login.service';
import { HttpContactApiService } from '../contacts/http-contact-api.service';

export enum BackgroundName {
  MyShares,
  AllShares,
  Contacts
}

@Injectable({
  providedIn: 'root'
})
export class RefreshBackgroundService {

  private _selectedBackground: BackgroundName;

  constructor(private _httpApiRequestService: HttpApiRequestService,
    private _httpContactApiService: HttpContactApiService,
    private _loginService: UserLoginService) { }

  public setSelectedBackgroundPage(value: BackgroundName) {
    this._selectedBackground = value;
  }

  public refreshBackground() {
    switch (this._selectedBackground) {
      case BackgroundName.AllShares:
        this._httpApiRequestService.refreshAllShares(this._loginService.entityId);
        break;
      case BackgroundName.MyShares:
        this._httpApiRequestService.refreshMyShares();
        break;
      case BackgroundName.Contacts:
        this._httpContactApiService.refreshUsers();
        this._httpContactApiService.refreshSystems();
        break;
      default:
        break;
    }
  }
}
