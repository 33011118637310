<section class="mat-elevation-z8" tabindex="0">
    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef>{{'Participant.OWNER' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.allowedToOwner" src="assets/images/checkIcon.png">
            </td>
        </ng-container>
        <ng-container matColumnDef="contributor">
            <th mat-header-cell *matHeaderCellDef>{{'Participant.CONTRIBUTOR' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.allowedToContributor" src="assets/images/checkIcon.png">
            </td>
        </ng-container>
        <ng-container matColumnDef="reader">
            <th mat-header-cell *matHeaderCellDef>{{'Participant.READER' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.allowedToReader" src="assets/images/checkIcon.png">
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</section>