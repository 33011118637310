import { IShare } from "./group-share.model"

export enum ContactType {
    USER = "USER",
    SYSTEM = "SYSTEM"
}

export enum Role {
    OWNER = "OWNER",
    CONTRIBUTOR = "CONTRIBUTOR",
    READER = "READER",
    SHARECREATOR = "SHARECREATOR"
}

export enum ContactOperation{
    Add,
    edit
}

export interface IRole {
    id: string,
    value: string
}

export interface IEmail {
    id: string,
    value: string,
    type: string,
}

export interface IUser {
    participantId: string,
    name: IName,
    externalId: string,
    roles: IRole[],
    socialSecurityNumber: string,
    emails: IEmail[]
    entitlements: IEntitlements[],
    id: string,
    workRelations: IWorkRelation[],
}

export interface IEntitlements {
    id: string,
    value: string
}

export interface IParticipant {
    participantId: string,
    participantType: ContactType,
    displayName?: string,
    email: string
    shareId: string
    rightType: Role
    entityId: string,
    entityName: string,
    additionalContact: boolean,
    workRelationId: string,
    isLoggedInUser: boolean,
    sftpUrl:string,
    active: boolean
    disableTrashIcon: boolean
    externalContact: boolean,
    token: string
}

export interface ISystem {
    participantId: string,
    systemName: string,
    internalAccountName: string,
    entityId: string,
    entityName: string,
    contactperson: IContactPerson
    contactPersonName: string,
    contactPersonEmail: string,
    sshKey: string,
    description: string,
    systemOperation: ContactOperation,
    active: boolean,
    type: ContactType,
    azureId: string
}
export interface IRight {
    share: IShare,
    rightType: Role,
    workRelationId: string,
    entityName: string,
    entityId: string,
    additionalContact: boolean,
    owner: boolean
}

export interface IExternalRight {
    externalContactId: string,
    ownerName: string,
    shareId: string,
    shareName: string
}

export interface IDisplayRole {
    id: string,
    displayValue: string;
}

export interface IName {
    givenName: string,
    familyName: string,
}

export interface IWorkRelation {
    displayName: string,
    economicActor: boolean,
    entityId: string,
    entityName: string,
    workRelationEmails: IEmail[],
    workRelationId: string
}

export class System implements ISystem {
    active: boolean;
    contactPersonEmail: string;
    contactPersonName: string;
    contactperson: IContactPerson;
    description: string;
    entityId: string;
    entityName: string;
    systemName: string;
    internalAccountName: string;
    participantId: string;
    sshKey: string;
    systemOperation: ContactOperation;
    type: ContactType;
    azureId: string;
}

export interface IContact {
    participantId: string,
    workRelationId: string,
    active: boolean,
    displayName: string,
    email: string,
    entityIdOfParticipant: string,
    entityNameOfParticipant: string,
    type: ContactType,
    azureId: string,
    additionalContact: boolean,
    contactOperation: ContactOperation;
    economicActor: boolean,
    externalContactId: string
}

export interface IContactPerson {
    name: string,
    email: string
}



export class Participant implements IParticipant {
    participantId: string;
    participantType: ContactType;
    displayName?: string;
    email: string;
    shareId: string;
    rightType: Role;
    entityId: string;
    entityName: string;
    additionalContact: boolean;
    workRelationId: string;
    isLoggedInUser: boolean;
    sftpUrl:string;
    participantOperation: ContactOperation;
    active: boolean;
    disableTrashIcon: boolean;
    externalContact: boolean
    token: string
}

export class Contact implements IContact {
    participantId: string;
    workRelationId: string;
    active: boolean;
    displayName: string;
    email: string;
    entityIdOfParticipant: string;
    entityNameOfParticipant: string;
    type: ContactType;
    additionalContact: boolean;
    azureId: string;
    contactOperation: ContactOperation;
    economicActor: boolean;
    externalContactId: string;
    externalContact: boolean;
}

export class IExternalContact {
    name: string;
    emailAddress: string;
    operation: ContactOperation;
    externalContactId: string;
    languageCode: string
}