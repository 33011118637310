import { Injectable } from '@angular/core';
import { IShare } from 'src/app/types/group-share.model';
import { Role } from 'src/app/types/participant.model';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  isCurrentUserNotAnOwnerOfAShare(value: IShare): boolean {
    return value.rightTypeOfCurrentUser === Role.READER || value.rightTypeOfCurrentUser === Role.CONTRIBUTOR;
  }
}
