import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { UserLoginService } from './services/login.service';
import { BrowserOperationService } from './services/browser-operation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private router: Router,
    private _userLoginService: UserLoginService,
    private _browserOperationService : BrowserOperationService) {
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        // page is refreshed
        if (event.id === 1 && event.url === '/shares' && event.urlAfterRedirects === '/shares') {
          this._userLoginService.refreshBrowser();
        } else if (event.id === 1 && event.url === event.urlAfterRedirects) {
          this._userLoginService.loginSMFT();
        }
      })
  }

  // event of closing or reloading browser window/tab 
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: { preventDefault: () => void; returnValue: string; }) {
    if (this._browserOperationService.preventBroswserOperations()) {
      event.preventDefault();
      event.returnValue = '';
      return false;
    }
    return true;
  }
}
