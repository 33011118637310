import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { FilterOption } from '../model/filter-options.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements AfterViewInit {

  @ViewChild(MatMenuTrigger) filterMenu: MatMenuTrigger;
  @Input() public filterOption: FilterOption[];
  @Output() public applyFilter: EventEmitter<FilterOption[]> = new EventEmitter<FilterOption[]>();
  @Output() public selectAllEvent: EventEmitter<void> = new EventEmitter<void>();

  public selectAll: boolean;

  ngAfterViewInit() {
    this.filterMenu.menu.overlayPanelClass = 'filter-menu-panel';
  }

  public onOptionSelect() {
    this.selectAll = this.filterOption != null && this.filterOption.every(t => t.activated);
    this.applyFilter.emit(this.filterOption.filter(menuitem => menuitem.activated));
  }

  public onAllSelect(event: MatCheckboxChange) {
    this.filterOption.forEach(option => option.activated = event.checked);
    this.selectAllEvent.emit();
  }

  public someComplete(): boolean {
    return this.filterOption.filter(t => t.activated).length > 0 || this.selectAll;
  }
}
