import { Injectable } from '@angular/core';
import { CompareUtility } from '../utilities/compare.utility';

@Injectable({
  providedIn: 'root',
})
export class TokenExchangeService {

  /* TOKEN ITEM*/
  getAccessToken(): string {
    return sessionStorage.getItem(localStorageItem.ACCESS_TOKEN);
  }

  storeAccessToken(value: string) {
    sessionStorage.setItem(localStorageItem.ACCESS_TOKEN, value);
  }

  /* USERDATA ITEM*/
  getStoredUserData(): any {
    return JSON.parse(sessionStorage.getItem(localStorageItem.USERDATA));
  }

  storeUserData(value: string) {
    sessionStorage.setItem(localStorageItem.USERDATA, value);
  }

  /* JWT */
  storeJwt(value: string) {
    sessionStorage.setItem(localStorageItem.JWT, value);
  }

  getJwt(): string {
    return sessionStorage.getItem(localStorageItem.JWT);
  }

  /* EXPIRATION ITEM */
  getAccessTokenExpiration(): string {
    return sessionStorage.getItem(localStorageItem.ACCESS_TOKEN_EXPIRATION);
  }

  storeAccessTokenExpiration(value: string) {
    sessionStorage.setItem(localStorageItem.ACCESS_TOKEN_EXPIRATION, value);
  }

  /* EXTERNAL PERSON INVITE TOKEN ITEM */
  getInviteToken(): string {
    return sessionStorage.getItem(localStorageItem.INVITE_TOKEN);
  }

  storeInviteToken(value: string) {
    sessionStorage.setItem(localStorageItem.INVITE_TOKEN, value);
  }

  getInviteTokenParam(): string {
    return CompareUtility.isDefinedAndNotNull(this.getInviteToken()) ? this.getInviteToken() : '';
  }

  public clearCookies() {
    sessionStorage.removeItem(localStorageItem.USERDATA);
    sessionStorage.removeItem(localStorageItem.ACCESS_TOKEN);
    sessionStorage.removeItem(localStorageItem.ACCESS_TOKEN_EXPIRATION);
    sessionStorage.removeItem(localStorageItem.JWT);
    sessionStorage.removeItem(localStorageItem.INVITE_TOKEN);
  }
}

export enum localStorageItem {
  USERDATA = 'userData',
  ACCESS_TOKEN = 'accessToken',
  ACCESS_TOKEN_EXPIRATION = 'expiration',
  JWT = 'jwt',
  INVITE_TOKEN = 'inviteToken'
}

