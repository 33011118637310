import { Injectable } from '@angular/core';
import { Observer, Subject, Subscription } from 'rxjs';
import { HttpProcessorService } from '../http/http-processor.service';
import { TokenExchangeService } from '../services/token-exchange.service';
import { IActionLog } from '../types/action-log.model';
import { IFileExtension } from '../types/file-model';
import { IShare } from '../types/group-share.model';
import { IExternalContact, IExternalRight, IParticipant, IRight, IUser } from '../types/participant.model';
import { DeleteExternalRequest, GetExternalRightsRequest, InviteExternalContactsRequest, LoadExternalContactsRequest, SearchExternalContactsRequest, SendInviteRequest, UpdateExternalContactRequest } from './request-urls/externalAbstractRequestUrl';
import { GetMeRequest, SearchParticipantRequest } from './request-urls/participantRequestUrl';
import { ChunkedFileUploadFinishRequest, DeleteFileRequest, DeleteParticipantFromShareRequest, DownloadActionLogsRequest, DownloadAllFilesShareRequest, FileIdRequest, FilterActionLogsRequest, GetShareRequest, GetSystemRightsRequest, GetUploadedChunksRequest, LoadActionLogsRequest, LoadAllSharesRequest, LoadFileRequest, LoadFilesRequest, LoadMySharesRequest, LoadParticipantRequest, SearchFileExtensionRequest, SendAddExternalContactLogRequest, UpdateRoleParticipantsRequest, UpdateShareRequest, UploadChunkRequest, UploadFilesRequest } from './request-urls/shareAndFileRequestUrl';

@Injectable({
  providedIn: 'root'
})
export class HttpApiRequestService {
  refreshAllShares$: Subject<IShare[]>;
  refreshMyShares$: Subject<IShare[]>;
  refreshShare$: Subject<IShare>;
  refreshActionLogs$: Subject<IActionLog[]>;
  refreshExternal$: Subject<IExternalContact[]>;
  
  constructor(private httpProcessorService: HttpProcessorService,
    private tokenExchangeService: TokenExchangeService) {
    this.refreshAllShares$ = new Subject<IShare[]>();
    this.refreshMyShares$ = new Subject<IShare[]>();
    this.refreshShare$ = new Subject<IShare>();
    this.refreshActionLogs$ = new Subject<IActionLog[]>();
    this.refreshExternal$ = new Subject<IExternalContact[]>();
  }

  refreshAllShares(entityId: string) {
    let request = new LoadAllSharesRequest(this.tokenExchangeService, 0, '', entityId);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.refreshAllShares$.next(result);
      },
      error: (error) => console.log(error),
      complete: () => {}
    })
  }

  refreshShare(shareId: string) {
    let request = new GetShareRequest(shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.refreshShare$.next(result);
      },
      error: (error) => console.log(error),
      complete: () => {}
    })
  }

  loadNextAllShares(pageNumber: number, name: string, entityId: string) {
    let obs$ = new Subject<IShare[]>();
    let request = new LoadAllSharesRequest(this.tokenExchangeService, pageNumber, name, entityId);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => console.log(error),
      complete: () => {}
    });
    return obs$;
  }

  refreshMyShares() {
    let request = new LoadMySharesRequest(this.tokenExchangeService, 0, '');
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.refreshMyShares$.next(result);
      },
      error: (error) => console.log(error),
      complete: () => {}
    })
  }

  refreshExternals(entityId: string) {
    let request = new SearchExternalContactsRequest('', 0, this.tokenExchangeService, entityId);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.refreshExternal$.next(result);
      },
      error: (error) => console.log(error),
      complete: () => {}
    })
  }

  loadExternals(pageNumber: number, name: string, entityId: string) {
    let obs$ = new Subject<IExternalContact[]>();
    let request = new SearchExternalContactsRequest('', pageNumber, this.tokenExchangeService, entityId);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => console.log(error),
      complete: () => {}
    });
    return obs$;
  }

  deleteExternal(externalId: string) {
    let obs$ = new Subject<any>();
    let request = new DeleteExternalRequest(externalId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  loadNextMyShares(pageNumber: number, name: string) {
    let obs$ = new Subject<IShare[]>();
    let request = new LoadMySharesRequest(this.tokenExchangeService, pageNumber, name);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => console.log(error),
      complete: () => {}
    });
    return obs$;
  }

  refreshActionLogs(shareId: string) {
    let request = new LoadActionLogsRequest(shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        this.refreshActionLogs$.next(result);
      },
      error: (error) => this.refreshActionLogs$.error(error),
      complete: () => { }
    });
  }

  onAllSharesRefreshed(observe: Observer<IShare[]>): Subscription {
    return this.refreshAllShares$.subscribe(observe);
  }

  onMySharesRefreshed(observe: Observer<IShare[]>): Subscription {
    return this.refreshMyShares$.subscribe(observe);
  }

  onShareRefreshed(observe: Observer<IShare>): Subscription {
    return this.refreshShare$.subscribe(observe);
  }

  onActionLogsRefreshed(observe: Observer<IActionLog[]>): Subscription {
    return this.refreshActionLogs$.subscribe(observe);
  }

  onExternalsRefreshed(observe: Observer<IExternalContact[]>): Subscription {
    return this.refreshExternal$.subscribe(observe);
  }

  updateShareRequest(model: Object, shareId: string) {
    let obs$ = new Subject<IShare>();
    let request = new UpdateShareRequest(model, shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        this.refreshShare(shareId);
        this.refreshActionLogs(shareId);
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }

  searchParticipants(entityId: string, searchfield: string): Subject<any>{
    let obs$ = new Subject<any>();
    let request = new SearchParticipantRequest(entityId, searchfield, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }

  searchExternalContacts(pageNumber: number, searchfield: string, entityId: string): Subject<any>{
    let obs$ = new Subject<any>();
    let request = new SearchExternalContactsRequest(searchfield, pageNumber, this.tokenExchangeService, entityId);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }

  inviteExternalContact(externalContact: IExternalContact, entityId: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new InviteExternalContactsRequest(externalContact, this.tokenExchangeService, entityId);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }
  
  loadParticipants(shareId: string, filter: string): Subject<IParticipant[]>{
    let obs$ = new Subject<IParticipant[]>;
    let request = new LoadParticipantRequest(shareId, filter, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: IParticipant[]) => {
        obs$.next(result);
      },
      error: (error) => {obs$.error(error);},
      complete: () => {}
    })
    return obs$;
  }

  loadExternalContacts(shareId: string): Subject<IParticipant[]>{
    let obs$ = new Subject<IParticipant[]>;
    let request = new LoadExternalContactsRequest(shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: IParticipant[]) => {
        obs$.next(result);
      },
      error: (error) => {obs$.error(error);},
      complete: () => {}
    })
    return obs$;
  }

  updateRole(shareId: string, participantId: string, role: string, workRelationId: string): Subject<any>{
    let obs$ = new Subject<any>();
    let request = new UpdateRoleParticipantsRequest(shareId, participantId, role, workRelationId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }

  deleteParticipantFromShare(shareId: string, participantId: string, workRelationId: string): Subject<any>{
    let obs$ = new Subject<any>();
    let request = new DeleteParticipantFromShareRequest(shareId, participantId, workRelationId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }

  getLoggedInUserDetails(): Subject<IUser> {
    let obs$ = new Subject<IUser>();
    let request = new GetMeRequest(this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    })
    return obs$;
  }

  getShare(shareId: string): Subject<IShare> {
    let obs$ = new Subject<any>();
    let request = new GetShareRequest(shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    })
    return obs$;
  }

  dowloadAllFilesOfShare(shareId: string): Subject<IShare> {
    let obs$ = new Subject<any>();
    let request = new DownloadAllFilesShareRequest(shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    })
    return obs$;
  }

  // File operations: search file type, add, update, delete, download

  searchFileType(type: string): Subject<IFileExtension[]>{
    let obs$ = new Subject<IFileExtension[]>();
    let request = new SearchFileExtensionRequest(type, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    })
    return obs$;
  }

  loadSharedFiles(shareId: string, filter: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new LoadFilesRequest(shareId, filter, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  uploadFiles(files: File[], shareId: string): Subject<any> {
    let obs$ = new Subject<any>();
    files.forEach((file: File, index: number, array) => {
      let request = new UploadFilesRequest(file, shareId, this.tokenExchangeService);
      this.httpProcessorService.handleRequest(request).subscribe({
        next: (result: any) => {
          if (index === array.length - 1) {
            obs$.next(result);
          }
        },
        error: (error) => {
          obs$.error(error);
        },
        complete: () => { }
      })
    });
    return obs$;
  }

  deleteFile(shareId: string, fileId: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new DeleteFileRequest(shareId, fileId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  downloadFile(shareId: string, fileId: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new LoadFileRequest(shareId, fileId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  downloadActionLogs(shareId: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new DownloadActionLogsRequest(shareId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  uploadChunk(chunkData: any, shareId:string, fileId: string, chunkNumber:number, chunkSize: number, fileName: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new UploadChunkRequest(chunkData, shareId, fileId, this.tokenExchangeService, chunkNumber, chunkSize, fileName);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result:any) => {
        obs$.next(result)
      },
      error: (error) => {
        obs$.error(error);
      },
      complete: () => {}
    })
    return obs$;
  }

  getFileIdToUpload(shareId:string, fileName:string, fileSize: number, fileType: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new FileIdRequest(shareId, this.tokenExchangeService, fileName, fileSize, fileType);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete:() => { }
    });
    return obs$;
  }

  chunkedFileUploadFinish(shareId: string, fileId: any, fileName: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new ChunkedFileUploadFinishRequest(shareId, fileId, fileName, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete:() => { }
    });
    return obs$;
  }

  getUploadedChunks(shareId:string, fileId: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new GetUploadedChunksRequest(shareId, fileId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete:() => { }
    });
    return obs$;
  }

  filterActionLogs(shareId: string, filter: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new FilterActionLogsRequest(shareId, filter, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: any) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete:() => { }
    });
    return obs$;
  }

  getSystemRights(id: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new GetSystemRightsRequest(id, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: IRight[]) => {
        obs$.next(result.map(x => x?.share?.shareName))
      },
      error: (error) => obs$.error(error),
      complete:() => { }
    })
    return obs$;
  }

  sendInvite(shareId: string, participantId: string, ownerName: string): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new SendInviteRequest(shareId, participantId, ownerName, this.tokenExchangeService)
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result)
      },
      error: (error) => obs$.error(error),
      complete:() => { }
    }); 
    return obs$;
  }

  sendAddExternalContactLog(shareId: string, displayName: string, externalContactId: string) {
    let obs$ = new Subject<any>();
    let request = new SendAddExternalContactLogRequest(shareId, displayName, externalContactId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result)
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    }); 
    return obs$;
  }

  getExternalRights(externalId: string) {
    let obs$ = new Subject<any>();
    let request = new GetExternalRightsRequest(externalId, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result: IExternalRight[]) => {
        obs$.next(result.map(x => x?.shareName));
      },
      error: (error) => obs$.error(error),
      complete: () => { }
    });
    return obs$;
  }

  updateExternalContact(externalContact: IExternalContact): Subject<any> {
    let obs$ = new Subject<any>();
    let request = new UpdateExternalContactRequest(externalContact, this.tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (result) => {
        obs$.next(result);
      },
      error: (error) => obs$.error(error),
      complete: () => {}
    });
    return obs$;
  }
}

