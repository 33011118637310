import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { ContactType, IContact, System, ContactOperation } from 'src/app/types/participant.model';
import { UserLoginService } from 'src/app/services/login.service';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { HttpContactApiService } from '../http-contact-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { FilterOption } from 'src/app/common-ui/filter/model/filter-options.model';
import { RightsDialogModel } from 'src/app/types/dialog-model';
import { DeleteSystemDialogComponent } from 'src/app/dialogs/delete-system-dialog/delete-system-dialog.component';
import { debounceTime, fromEvent, map } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { ResizedEvent } from 'angular-resize-event';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';


@Component({
  selector: 'app-system-tab',
  templateUrl: './system-tab.component.html',
  styleUrls: ['./system-tab.component.scss']
})
export class SystemTabComponent extends BaseAbstractComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('search') filterElementRef: ElementRef;

  dataSource = new MatTableDataSource<IContact>([]);
  statusFilterOptions: FilterOption[] = [];
  isLoadingData: boolean = false;
  pageNumber: number = 1;
  contentHeight: string = this.calculateHeight();
  searchKey: string = '';
  displayedColumns = ['icon', 'displayName', 'email', 'entityNameOfParticipant', 'status', 'options'];

  constructor(private _loginService: UserLoginService,
    private _httpContactApiService: HttpContactApiService,
    private _httpApiService: HttpApiRequestService) {
    super();
    this.setupSubscription();
  }
  
  ngOnInit() {
    this._httpContactApiService.refreshSystems();
  } 

  ngAfterViewInit() {
    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this.pageNumber = 0;
          this._httpContactApiService.loadNextSystems(this.pageNumber, result.trim()).subscribe({
            next: (result: IContact[]) => {
              this.dataSource.data = result;
              this.pageNumber = 1;
            },
            error: (error) => {
              this.handleError(error);
            },
            complete: () => { }
          });
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      }
      );
  }

  addSystem() {
    let systemData = new System();
    systemData.systemOperation = ContactOperation.Add;
    systemData.entityName = this._loginService.entityNameOfParticipant;
    systemData.entityId = this._loginService.entityId;
    systemData.active = true;
    systemData.type = ContactType.SYSTEM;
    const dialogRef = this.dialog.open(AddContactComponent, {
      panelClass: 'dialog-container',
      data: systemData
    });
    dialogRef.afterClosed().subscribe((result: IContact) => {
      if (result) {
        //different response from BE, refresh all contacts instead adding one to list
        this._httpContactApiService.refreshSystems();
      }
    });
  }

  openContact(data: IContact) {
    if (this._loginService.isLoggedInUserIsAdmin) {
      let system = new System();
      this._httpContactApiService.getSystemDetails(data.participantId).subscribe({
        next: (result) => {
          system = result.system;
          system.type = result.type;
          system.active = result.active;
          this.processSystem(system);
          system.systemOperation = ContactOperation.edit;
          const dialogRef = this.dialog.open(AddContactComponent, {
            panelClass: 'dialog-container',
            data: system
          });
          dialogRef.afterClosed().subscribe((result: IContact) => {
            if (result) {
              this._httpContactApiService.refreshSystems();
            }
          });
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      });
    }
  }

  onResized(event: ResizedEvent) {
    this.contentHeight = this.calculateHeight();
  }

  openDeleteSystemDialog(contact: IContact, shareNames: string[]): void {
    let data = new RightsDialogModel();
    this.translate.get('Dialogs.DeleteSystemDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.DeleteSystemDialog.Message').subscribe((x: string) => data.content = x);
    data.shares = shareNames;
    let heightDialogBox: string = shareNames.length > 0 ? '285px' : '255px';
    const dialogRef = this.dialog.open(DeleteSystemDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: heightDialogBox,
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteSystem(contact);
      }
    });
  }

  openDialog(contact: IContact): void {
    this._httpApiService.getSystemRights(contact.participantId).subscribe({
      next: (result) => {
        this.openDeleteSystemDialog(contact, result);
      }
    })
  }

  public selectAll() {
    this.dataSource.filter = 'all';
  }

  public enableOptions(value: IContact): boolean {
    return this._loginService.isLoggedInUserIsAdmin;
  }

  public deleteSystem(contact: IContact) {
    this._httpContactApiService.deleteSystem(contact.participantId, contact.workRelationId).subscribe({
      next: (result) => {
        this.removeParticipant(contact.participantId, contact.workRelationId);
        this.refreshTableData(this.dataSource.data);
      },
      error: (error) => {
        this.handleError(error);
      },
      complete: () => { }
    });
  }

  public onScrollDown(event: any) {
    this.isLoadingData = true;
    this._httpContactApiService.loadNextSystems(this.pageNumber, this.searchKey.trim()).subscribe({
      next: (result: IContact[]) => {
        this.processSystem(result);
        this.appendRecords(result);
        this.refreshTableData(this.dataSource.data);
        setTimeout(() => {this.isLoadingData = false;}, 500);
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
  }

  private getFilterPredicate() {
    return (row: IContact, filters: string) => {
      const matchFilter = [];

      const status = row.active.toString();

      const statusFilterOptions = this.statusFilterOptions.filter(x => x.activated);

      if (statusFilterOptions.length === 0) {
        return true;
      }
      
      let customFilterST = false;

      if (statusFilterOptions.length > 0) {
        customFilterST = statusFilterOptions.some(filter => filter.value.toString() === status)
        matchFilter.push(customFilterST);
      }
      return matchFilter.every(Boolean);
    };
  }

  private initializeFilterActions() {
    this.statusFilterOptions = this.getActiveFilterOptions();
  }

  private getActiveFilterOptions(): FilterOption[] {
    let options: FilterOption[] = [];
    options.push({ title: 'Contacts.Status.Active', value: true, activated: false, isTitleTranslated: false });
    options.push({ title: 'Contacts.Status.Disabled', value: false, activated: false, isTitleTranslated: false });
    return options;
  }

  private refreshTableData(result: IContact[]) {
    this.dataSource.data = result;
  }

  private removeParticipant(id: string, workRelationId: string) {
    const index = this.dataSource.data.findIndex(x => x.participantId === id && x.workRelationId === workRelationId);
    if (index > -1) { // only splice array when item is found
      this.dataSource.data.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  private appendRecords(result: IContact[]) {
    if (CompareUtility.isDefinedAndNotNull(result) && result.length > 0) {
      this.dataSource.data = this.dataSource.data.concat(result);
      this.pageNumber = this.pageNumber + 1;
    }
  }

  private calculateHeight() {
    return (parent.innerHeight - 350) + 'px';
  }

  private processSystem(contact: any) {
    if (contact.type == ContactType.SYSTEM) {
      contact.azureId = contact.participantId.toString().split('-').join('');
    }
  }

  private setupSubscription() {
    const refreshSharesSubscription = this._httpContactApiService.onSystemsRefreshed({
      next: (result) => {
        this.dataSource.data = result;
        this.searchKey = '';
        this.pageNumber = 1;
        this.processSystem(this.dataSource.data);
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.initializeFilterActions()
        this.refreshTableData(this.dataSource.data);
        this.dataSource.sort = this.sort;
      },
      error: (error) => { this.handleError(error); },
      complete() { },
    });
    this.subscriptions.push(refreshSharesSubscription)
  }

  public isLoggedInUserIsAdmin() {
    return this._loginService.isLoggedInUserIsAdmin;
  }
}
