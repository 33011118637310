<div class="wrapper">
    <div class="outerContainer">
      <div class="titleContainer">{{data.title}}</div>
      <div class="contentContainer">
        <mat-label>{{data.content}}</mat-label>
      </div>
      <div mat-dialog-actions class="dialogActionsContainer">
        <button mat-dialog-close cdkFocusInitial class="activeButton refreshButton" (click)="refreshShares()">
          {{'Dialogs.UserNotAuthorizedDialog.Button'| translate}}</button>
      </div>
    </div>
  </div>