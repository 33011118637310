import { Injectable } from '@angular/core';

let bytes = require('bytes');

@Injectable({
  providedIn: 'root'
})
export class FileSizeManagerService {

  formatFileSize(fileSizeMB: number): number {
    let size: string = '';
    return bytes(size.concat(fileSizeMB.toString(), ' MB'));
  }

  isFileSizeLessThanGB(val: number): boolean {
    return val <= bytes('1 GB');
  }

  isFileSizeLessThanLimit(val: number, limitMB: number): boolean {
    let size: string = '';
    let limit =  bytes(size.concat(limitMB.toString(), ' MB'));
    return val > limit;
  }
}
