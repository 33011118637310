import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable()
export class SftpUrlService  {

    createSftpUrl(shareId: string, participantId: string){
        const blobName = environment.blobName;
        const blobAdress = environment.blobAdress
        const modParticipantId = participantId.split('-').join('');
        return blobName + '.' + shareId + '.' + modParticipantId + '@' + blobAdress;
    }
}