import { Role } from "src/app/types/participant.model";
import { IPreferences, IRoleNotification, Notification, NotificationType } from "./notification.model";
import { CompareUtility } from "src/app/utilities/compare.utility";

export class NotificationFactory {

    getNotificationFactory(role: Role): IRoleBasedNotifications {
        let factoryObject = new AbstractNotification();
        if (role === Role.OWNER || role === Role.SHARECREATOR) {
            factoryObject = new OwnerFactory();
        } else if (role === Role.READER) {
            factoryObject = new ReaderFactory();
        } else if (role === Role.CONTRIBUTOR) {
            factoryObject = new ContributorFactory();
        }
        return factoryObject;
    }
}

export interface IRoleBasedNotifications {
    shareId: string;
    userId: string;
    roleBasedValues: IRoleNotification[];
    processNotifications(values: IPreferences[]): IPreferences[];
    getDefaultNotifications(shareId: string, userId: string, workRelationId: string): IPreferences[];
}

export class AbstractNotification implements IRoleBasedNotifications {

    shareId: string;
    userId: string;
    roleBasedValues: IRoleNotification[];

    processNotifications(values: IPreferences[]): IPreferences[] {
        let finalList: IPreferences[] = [];
        this.roleBasedValues.forEach((notification) => {
            let preference: IPreferences = values.find(x => x.notificationType === notification.name);
            if (CompareUtility.isDefinedAndNotNull(preference)) {
                preference.type = notification.type;
                finalList.push(preference);
            }
        });
        return finalList;
    }

    getDefaultNotifications(shareId: string, userId: string, workRelationId: string): IPreferences[] {
        let defaultPreferences: IPreferences[] = [];
        this.roleBasedValues.forEach(value => {
            defaultPreferences.push(
                { shareId: shareId, notificationType: value.name, active: value.defaultValue, userId: userId, workRelationId: workRelationId},
            );
        });
        return defaultPreferences;
    }
}

export class OwnerFactory extends AbstractNotification {
    override roleBasedValues: IRoleNotification[] = [
        { name: Notification.SHARE_EXPIRED_ALERT, type: NotificationType.Share, defaultValue: false },
        { name: Notification.SHARE_EXPIRATION_REMINDER_ALERT, type: NotificationType.Share, defaultValue: true },
        { name: Notification.SHARE_DELETED_ALERT, type: NotificationType.Share, defaultValue: true },
        { name: Notification.DOWNLOAD_FILE_ALERT, type: NotificationType.Files, defaultValue: false },
        { name: Notification.UPLOAD_FILE_ALERT, type: NotificationType.Files, defaultValue: false },
        { name: Notification.ADD_RIGHT_ALERT, type: NotificationType.Participants, defaultValue: false },
        { name: Notification.CHANGE_RIGHT_ALERT, type: NotificationType.Participants, defaultValue: false },
    ];
    constructor() {
        super();
    }
}

export class ReaderFactory extends AbstractNotification {
    override roleBasedValues: IRoleNotification[] = [
        { name: Notification.SHARE_EXPIRED_ALERT, type: NotificationType.Share, defaultValue: false },
        { name: Notification.SHARE_EXPIRATION_REMINDER_ALERT, type: NotificationType.Share, defaultValue: true },
        { name: Notification.SHARE_DELETED_ALERT, type: NotificationType.Share, defaultValue: true },
        { name: Notification.UPLOAD_FILE_ALERT, type: NotificationType.Files, defaultValue: false }
    ];
    constructor() {
        super();
    }
}

export class ContributorFactory extends AbstractNotification {
    override roleBasedValues: IRoleNotification[] = [
        { name: Notification.SHARE_EXPIRED_ALERT, type: NotificationType.Share, defaultValue: false },
        { name: Notification.SHARE_EXPIRATION_REMINDER_ALERT, type: NotificationType.Share, defaultValue: true },
        { name: Notification.SHARE_DELETED_ALERT, type: NotificationType.Share, defaultValue: true },
        { name: Notification.UPLOAD_FILE_ALERT, type: NotificationType.Files, defaultValue: false },
        { name: Notification.DOWNLOAD_FILE_ALERT, type: NotificationType.Files, defaultValue: false }
    ];
    constructor() {
        super();
    }
}