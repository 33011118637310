import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { IFileType, IShare } from 'src/app/types/group-share.model';
import { ShareDetailsComponent } from '../share-details.component';
import { SharedDetailsService } from '../shared-details.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { IFile, IFileExtension, RestrictionType } from 'src/app/types/file-model';
import { ArrayOperationsUtility } from 'src/app/utilities/arrayOperations.utility';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectOptions } from 'src/app/common-ui/single-select/model/single-select.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { RemoveFileTypeRestrictionDialogComponent } from 'src/app/dialogs/remove-file-type-restriction-dialog/remove-file-type-restriction-dialog.component';
import { debounceTime, fromEvent, map } from 'rxjs';

@Component({
  selector: 'app-file-type',
  templateUrl: './file-type.component.html',
  styleUrls: ['./file-type.component.scss']
})
export class FileTypeComponent extends BaseAbstractComponent {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) searchMenu: MatMenuTrigger;
  @ViewChild('searchField') searchField: any;
  @ViewChild('search') elementRef: ElementRef;

  public width: number = parent.innerWidth;
  public searchResult: IFileExtension[] = [];
  public restrictedFileTypes = new MatTableDataSource<IFileType>([]);
  public fileTypeColumns: string[] = ['restrictionType', 'fileType', 'options'];
  public searchKey: string;
  public shareDetails: IShare;
  public restrictionOptions: SelectOptions[] = [];
  public selectedRestrictionType: string = '';
  public errorMessage: string;
  public isShowError: boolean = false;
  public active: boolean = false;

  private addedFileExtension: string[] = [];

  constructor(public dialogRef: MatDialogRef<ShareDetailsComponent>,
    private _sharedDetailsService: SharedDetailsService,
    private _httpApiRequestService: HttpApiRequestService) {
    super();
    this.setupSubscription();
  }

  ngAfterViewInit() {

    const keyup$ = fromEvent(this.elementRef.nativeElement, 'keyup');
    // wait .25s between keyups to emit current value
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(250)
      )
      .subscribe({
        next: (result: any) => {
          if (this.searchKey.trim().length > 1) {
            this.width = this.searchField._elementRef.nativeElement.offsetWidth - 2;
            this.searchFileType(this.filterSearchkey(this.searchKey));
          } else {
            this.searchMenu.closeMenu();
          }
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      }
      );
  }

  public updateShareDetails(types: IFileType[]) {
    let model = {
      "restrictedFileTypes": types
    };
    this._httpApiRequestService.updateShareRequest(model, this.shareDetails.id).subscribe({
      next: (result) => {
        this.shareDetails = result;
        this.restrictedFileTypes.data = result.restrictedFileTypes;
        this.searchKey = '';
        this.isShowError = false;
        this._sharedDetailsService.updateSharedDetails(result);
      },
      error: (error: any) => { this.handleError(error) },
      complete: () => { },
    });
  }

  public nameClicked(e: Event) {
    this.searchMenu.closeMenu();
    this.isShowError = false;
  }

  public displayError(translation: string) {
    this.isShowError = true;
    this.translate.get(translation).subscribe((x: string) => this.errorMessage = x);
  }

  public addFileType(type: string) {
    this.isShowError = false;
    let types = this.restrictedFileTypes.data.map(x => x.fileType);
    types.push(type);
    if (this.selectedRestrictionType == RestrictionType.DENY) {
      if (this.addedFileExtension.findIndex(x => x.toLocaleLowerCase() === type.toLocaleLowerCase()) >= 0) {
        this.displayError('Settings.File_types_accordian.ErrorMessages.DuplicateFileExtension');
        return;
      }
    }
    if (this.selectedRestrictionType == RestrictionType.ALLOW) {
      if (!this.addedFileExtension.every(x => types.includes(x.toUpperCase()))) {
        this.displayError('Settings.File_types_accordian.ErrorMessages.MoreFileExtensions');
        return;
      }
    }
    // validate if selected extension is already restricted
    if (this.restrictedFileTypes.data.findIndex(x => x.fileType.toLocaleLowerCase() === type.toLocaleLowerCase()) < 0) {
      let types = this.restrictedFileTypes.data;
      types.push({ restrictionType: this.selectedRestrictionType, fileType: type });
      this.updateShareDetails(types);
    } else {
      this.displayError('Settings.File_types_accordian.ErrorMessages.FileTypeRestricted');
    }
  }

  public removeFileType(element: IFileType) {
    let types = this.restrictedFileTypes.data;
    let index = types.findIndex(x => x.fileType === element.fileType);
    types.splice(index, 1);
    this.updateShareDetails(types);
    if (types.length === 0) {
      this.selectedRestrictionType = '';
    }
  }

  public isRestrictionTypeSelected() {
    return !CompareUtility.isNotEmpty(this.selectedRestrictionType);
  }

  public onSliderChange(value: boolean) {
    this.active = value;
    if (!value) {
      this.deleteAllFileTypes();
    }
  }

  public resetAllControls() {
    this.selectedRestrictionType = '';
    this.isShowError = false;
    this.searchKey = '';
  }

  public onRestrictionSelected(e: string) {
    this.selectedRestrictionType = e;
  }

  public isAlreadyInShare(element: IFileType) {
    return this.addedFileExtension.some(x => x.toLocaleLowerCase() === element.fileType.toLocaleLowerCase());
  }

  private deleteAllFileTypes() {
    if (CompareUtility.isDefinedAndNotNull(this.restrictedFileTypes.data) && this.restrictedFileTypes.data.length > 0) {
      this.openDeleteFileTypeDialog();
    } else {
      this.resetAllControls();
    }
  }

  private openDeleteFileTypeDialog(): void {
    const dialogRef = this.dialog.open(RemoveFileTypeRestrictionDialogComponent, {
      panelClass: 'custom-dialog-container',
      height: '225px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.updateShareDetails([]);
        this.resetAllControls();
      } else {
        this.active = true;
      }
    });
  }

  private searchFileType(field: string) {
    this.isShowError = false;
    this._httpApiRequestService.searchFileType(field).subscribe({
      next: (result: IFileExtension[]) => {
        this.searchResult = result;
        this.searchMenu.openMenu();
      },
      error: (error: any) => { this.handleError(error) },
      complete: () => { },
    });
  }

  private setRestrictionOptions() {
    this.restrictionOptions = [];
    this.restrictionOptions.push({ title: 'Settings.File_types_accordian.restrictionOptions.allow', value: RestrictionType.ALLOW, isTitleTranslated: false, disabled: this.disableOption(RestrictionType.ALLOW) });
    this.restrictionOptions.push({ title: 'Settings.File_types_accordian.restrictionOptions.deny', value: RestrictionType.DENY, isTitleTranslated: false, disabled: this.disableOption(RestrictionType.DENY) });
  }

  private getRestrictedType(): string {
    return this.restrictedFileTypes?.data[0]?.restrictionType?.toString();
  }

  private disableOption(option: string) {
    let type = this.getRestrictedType();
    if (CompareUtility.isDefinedAndNotNull(type)) {
      return (type !== option);
    } else {
      return false;
    }
  }

  private setSliderValue() {
    if (this.restrictedFileTypes.data.length > 0) {
      this.active = true;
    }
  }

  private filterSearchkey(value: string) {
    return value.charAt(0) === '.' ? value.substring(1) : value;
  }

  private processFileTypesData() {
    this.restrictedFileTypes.data.forEach(data => {
      let translationKey = data.restrictionType === RestrictionType.ALLOW ? 'Settings.File_types_accordian.restrictionOptions.allow' : 'Settings.File_types_accordian.restrictionOptions.deny';
      this.translate.get(translationKey).subscribe((x: string) => data.displayRestrictionType = x);
    });
  }

  private setupSubscription() {
    const showDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: (x: IShare) => {
        this.shareDetails = x;
        this.restrictedFileTypes.data = x.restrictedFileTypes;
        this.processFileTypesData();
        this.restrictedFileTypes.sort = this.sort;
        this.setSliderValue();
        this.setRestrictionOptions();
        this.selectedRestrictionType = this.getRestrictedType();
      },
      error: err => { this.handleError(err) },
      complete() { },
    });
    this.subscriptions.push(showDetails);

    const showFilesSubscription = this._sharedDetailsService.onSharedFilesUpdated({
      next: (result: IFile[]) => {
        let filenames = result.map(x => x.fileName.split('.').pop());
        this.addedFileExtension = ArrayOperationsUtility.removeDuplicates(filenames);
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(showFilesSubscription);
  }
}
