import { Component } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { FileFactoryService } from 'src/app/services/FileOperationFactory/file-factory.service';
import { UploadStatus } from 'src/app/services/FileOperationFactory/file-manager.model';
import { FileSizeManagerService } from 'src/app/services/file-size-manager.service';
import { IFile } from 'src/app/types/file-model';
import { TransferService } from '../service/transfer.service';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent extends BaseAbstractComponent {

  public files: IFile[] = [];
  private _fileFactoryService: FileFactoryService = new FileFactoryService();

  constructor(private _fileSizeManagerService: FileSizeManagerService,
    private _transferService: TransferService) {
    super();
    this.setupSubscription();
  }

  private getSizeUploaded() {
    let totalSize: number = 0;
    this.files.forEach((x) => {
      totalSize = totalSize + x.size;
    });
    return totalSize;
  }

  private fileInFolder(plainPath: string): boolean {
    return plainPath.split('/').length > 1;
  }

  public resumeFileUpload(file: IFile): void {
    file.resumeUpload();
  }

  public pauseFileUpload(file: IFile): void {
    file.pauseUpload();
  }

  public isFileSelected(): boolean {
    return this.files.length > 0;
  }

  public validateFiles(): boolean {
    return this._fileSizeManagerService.isFileSizeLessThanGB(this.getSizeUploaded()) && this.isFileSelected();
  }

  public isUploadFailed(file: IFile): boolean {
    let status = file.uploadStatus$.getValue();
    return status === UploadStatus.FAILED;
  }

  public isUploadInProgress(file: IFile): boolean {
    let status = file.uploadStatus$.getValue();
    return status === UploadStatus.INPROGRESS;
  }

  public onFileSelected(files: File[]): void {
    let filesToSend: IFile[] = []
    for (let i = 0; i < files.length; i++) {
      filesToSend.push(this._fileFactoryService.getFileManagerFromFile(files[i]));
    }
    this._transferService.updateFiles(filesToSend);
  }

  public onFolderSelected(files: File[]): void {
    for (let i = 0; i < files.length; i++) {
      this._fileFactoryService.createOrUpdateFolder(files[i]);
    };
    this._fileFactoryService.compressFolder();
  }

  public dropped(files: NgxFileDropEntry[]): void {
    let filesToSend: IFile[] = [];
    let count = 0;
    for (let i = 0; i < files.length; i++) {
      const droppedFile = files[i];
      // Is it a file?
      if (!this.fileInFolder(droppedFile.relativePath)) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          filesToSend.push(this._fileFactoryService.getFileManagerFromFile(file));
          count++;
          if (count === files.length) {
            this._transferService.updateFiles(filesToSend);
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this._fileFactoryService.createOrUpdateFolder(file);
          count++;
          if (count === files.length) {
            this._fileFactoryService.compressFolder();
          }
        });
      }
    }
  }

  public removeFile(file: IFile) {
    this._transferService.removeFile(file);
  }

  private setupSubscription() {
    const compressedFolderFinish = this._fileFactoryService.compressFolderFinish$.subscribe({
      next: (res) => {
        this._transferService.updateFiles([res]);
      },
    });
    this.subscriptions.push(compressedFolderFinish);

    const clearTranserSubscription = this._transferService.onClearTransfer({
      next: (files: boolean) => {
        this.files = [];
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(clearTranserSubscription);

    const updateFiles = this._transferService.onFilesSelected({
      next: (files: IFile[]) => {
        this.files = files;
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(updateFiles);
  }
}
