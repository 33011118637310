import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent
} from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of, concatMap, catchError } from 'rxjs';
import { UserLoginService } from 'src/app/services/login.service';
import { TokenExchangeRequestService } from 'src/app/services/token-exchange-request.service';
import { TokenExchangeService } from 'src/app/services/token-exchange.service';
import { TokenManagerService } from 'src/app/services/token-manager.service';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { environment } from 'src/environments/environment';
import { HttpProcessorService } from '../../http-processor.service';
import { VerifyTokenRequest } from '../../request-urls/authorizationRequestUrl';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  retrieveTokenRequest: boolean = false;

  constructor(private loginService: UserLoginService,
    private _tokenExchangeService: TokenExchangeService,
    private _tokenExchangeRequestService: TokenExchangeRequestService,
    private _httpProcessorService: HttpProcessorService,
    private oidcSecurityService: OidcSecurityService,
    private _tokenManagerService: TokenManagerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.loginService.isUserLoggedIn && !this.retrieveTokenRequest) {
      return of(this._tokenManagerService.isAccessTokenExpired()).pipe(
        concatMap((accTokenExpired: boolean): Observable<string> => {
          if (accTokenExpired === false) {
            return of(null);
          } else {
            // Access token is expired hence retrieve new access token
            this.retrieveTokenRequest = true;
            return this.retrieveNewAccessToken();
          }
        }),
        concatMap((newAccessToken) => {
          // Continue request if Access token is alive
          if (newAccessToken === null) {
            return next.handle(request);
          }
          else if (CompareUtility.isDefinedAndNotNull(newAccessToken) && CompareUtility.isNotEmpty(newAccessToken)) {
            this.retrieveTokenRequest = false;
            request.headers.set('Authorization', 'Bearer ' + newAccessToken);
            return next.handle(request);
          }
          // Both Access token and refresh token are expired
          else {
            return this.logout();
          }
        })
      )
    } else {
      return next.handle(request);
    }
  }

  retrieveNewAccessToken(): Observable<string> {
    // Returns id token if has valid refresh token else returns error
    return this.oidcSecurityService.checkAuthIncludingServer().pipe(
      concatMap(({ idToken }) => {
        this._tokenExchangeService.storeAccessTokenExpiration(this.loginService.getDecodedExpiration(idToken));
        this._tokenManagerService.getExpirationDate();
        return of(idToken);
      }),
      concatMap((idToken) => {
        if (environment.isTest) {
          let request = new VerifyTokenRequest(idToken, this._tokenExchangeService);
          return this._httpProcessorService.handleRequest(request);
        } else {
          return of(true);
        }
      }),
      concatMap((response) => {
        return this._tokenExchangeRequestService.exchangeToken();
      }),
      concatMap((token) => {
        this._tokenExchangeService.storeAccessToken(token);
        return of(token);
      }),
      catchError(err =>
        this.logout() // Refresh token is expired
      )
    );
  }

  logout(): Observable<any> {
    this.loginService.logout()
    return of('');
  }
}
