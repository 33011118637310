import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Directive({
  selector: '[textOverflow]',
  providers: [MatTooltip]
})
export class TextOverflowDirective {

  @Input() public textValue: string;
  @Input() public tooltipPosition: TooltipPosition = 'below';

  constructor(private el: ElementRef,
    private tooltip: MatTooltip) {
    this.el.nativeElement.style.textOverflow = 'ellipsis';
    this.el.nativeElement.style.overflow = 'hidden';
    this.el.nativeElement.style.whiteSpace = 'nowrap';
  }

  @HostListener('mouseover') onMouseEnter() {
    if (this.isEllipsisActive(this.el.nativeElement)) {
      this.tooltip.message = this.textValue;
      this.tooltip.position = this.tooltipPosition;
      this.tooltip.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.tooltip.hide();
  }

  private isEllipsisActive(e: HTMLElement): boolean {
    return e ? (e.offsetWidth < e.scrollWidth) : false;
  }
}
