import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RightsDialogModel } from 'src/app/types/dialog-model';

@Component({
  selector: 'app-delete-share-confirmation',
  templateUrl: './delete-external-dialog.component.html',
  styleUrls: ['./delete-external-dialog.component.scss']
})
export class DeleteExternalDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteExternalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RightsDialogModel) {
  }

  onCloseClick() {
    this.dialogRef.close();
  }

}
