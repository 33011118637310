import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRequest } from './request-urls/requestUrl.model';

@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {

  constructor(private http: HttpClient) { }

  /**
   * post
   */
  public post(req: IRequest): Observable<HttpErrorResponse> {
    return this.http.post<HttpErrorResponse>(req.url, req.body, req.options);
  }

  /**
   * get
   */
  public get(req: IRequest): Observable<HttpErrorResponse> {
    return this.http.get<HttpErrorResponse>(req.url, req.options);
  }

  /**
   * put
   */
  public put(req: IRequest): Observable<HttpErrorResponse> {
    return this.http.put<HttpErrorResponse>(req.url, req.body, req.options);
  }

  /**
   * delete
   */
  public delete(req: IRequest): Observable<HttpErrorResponse> {
    return this.http.delete<HttpErrorResponse>(req.url, req.options);
  }
}
