import { BehaviorSubject, Subject } from "rxjs";
import { UploadStatus } from "../services/FileOperationFactory/file-manager.model";

export interface IFile {

    // Return from BE
    id: string; // fileId
    fileName: string;
    creationDateTime: Date;
    fileSizeMB: number; // In MB
    status: IFileStatus;
    
    uniqueIdentifier: string; // ID generated by flowFile.js
    size: number; // In Bytes
    
    // setter and getter
    setShareId(id: string):void;

    // upload progress
    uploadStatus$: BehaviorSubject<UploadStatus>;
    progressCount$: Subject<number>;
    updateProgress(): void;

    // file methods
    startUpload(): void;
    preUpload(): Subject<any>;
    uploadFile(chunkArray: FileChunk[]): Subject<boolean>;
    postUpload(): Subject<any>;
    resumeUpload(): void;
    pauseUpload(): void;

    // file chunk methods
    postChunkUpload(): void;
}

export interface IFileExtension {
    id: string;
    fileType: string;
    extension: string;
}

export class FileChunk {
    chunkNumber: number;
    size: number;
    data: Blob;
    isChunkDataUploaded: boolean;
}

export class UploadedChunk {
    id: string;
    fileId: string;
    chunkNumber: number;
    chunkSize: number;
    chunkName: string;
}

export enum IFileStatus {
    CREATED = 'CREATED',
    SCANNED = 'SCANNED',
    INFECTED = 'INFECTED'
}

export enum RestrictionType {
    ALLOW = 'ALLOW',
    DENY = 'DENY'
}

