<div class="outer-container">
  <div class="header">
    <div class="headerWrapper">
      <div class="titleContainer">
        <div class="titleWrapper">
          <span class="titleLbl">{{'Files.Title' | translate}}</span>
          <div class="searchContainer">
            <input #search type="text" name="searchKey" [(ngModel)]="searchKey"
              placeholder="{{'Files.Search_bar' | translate }}" autocomplete="off" maxlength="25" />
          </div>
        </div>
        <div class="buttonWrapper">
          <i *ngIf="shareDetails?.restrictedFileTypes.length > 0" matTooltip="{{'Settings.File_types_accordian.restrictionOptions.' + shareDetails.restrictedFileTypes[0].restrictionType.toLocaleLowerCase()| translate}} {{restrictionString}} {{'Common.FileTypes' | translate}}" class="material-icons warning-icon">warning</i>
          <button class="buttonStyle font-typo downloadBtn" fmat-icon-button matTooltip="{{'Common.Download' | translate}}"
            (click)="onDownloadAllFilesClicked()"
            [ngClass]="disableDownloadButton() ? 'disableClass' : ''">{{'MyShare.Download_files' | translate}}</button>
          <span [ngClass]="isCurrentUserAReaderOfAShare() ? 'not-allowed-icon' : 'allowed-icon'" *ngIf="!isUserExternalPerson()">
            <button class="buttonStyle font-typo" fmat-icon-button [matMenuTriggerFor]="menu" (click)="onAddFileButtonClicked()"
              matTooltip="{{'Common.Upload' | translate}}"
              [ngClass]="disableAddFilesButton() ? 'disableIcon' : ''">{{'Files.Add_File_btn' |
              translate}}
            </button>
          </span>
        </div>
      </div>
      <mat-menu #menu="matMenu">
        <app-upload-menu (onFileSelected)="onFileSelected($event)"
          (onFolderSelected)="onFolderSelected($event)"></app-upload-menu>
      </mat-menu>
      <section class="shares-container mat-elevation-z8" tabindex="0">
        <table mat-table [dataSource]="dataSource" class="filesTable" multiTemplateDataRows="true" matSort>
  
          <!-- Icon Column -->
          <ng-container matColumnDef="safetyStatus">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <span class="safetyIcon">
                <img [src]="checkStatusReturnImgSource(element)" alt="{{'Images.SafetyIcon'|translate}}" [matTooltip]="checkStatusReturnLabel(element)">
              </span>
            </td>
          </ng-container>
  
          <!-- File Name Column -->
          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Files.FileName_Column' | translate}}</th>
            <td mat-cell *matCellDef="let element" #textElement [ngClass]="isInfectedFile(element)? 'disableClass' : ''">
              <div textOverflow [textValue]="element.fileName">{{element.fileName}}</div>
            </td>
          </ng-container>
  
          <!-- Date shared Column -->
          <ng-container matColumnDef="creationDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Files.DateShared_Column' | translate}}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="isInfectedFile(element)? 'disableClass' : ''">
              {{element.creationDateTime | date : dateFormat : undefined : locale }}
            </td>
          </ng-container>
  
          <!-- Size Column -->
          <ng-container matColumnDef="fileSizeMB">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Files.Size_Column' | translate}}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="isInfectedFile(element)? 'disableClass' : ''">
              {{formatFileSize(element.fileSizeMB) | customFileSize}}
            </td>
          </ng-container>
  
          <!-- Options Column -->
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" [ngClass]="isInfectedFile(element)? 'disableClass' : ''">
              <span class="options">
                <span class="uploadIconContainer" *ngIf="isUploadInProgress(element)">
                  <img src="assets/images/pauseButton.png" id="icon" (click)="pauseFileUpload(element)">
                </span>
                <span class="uploadIconContainer" *ngIf="isUploadFailed(element)">
                  <img src="assets/images/resumeButton.png" id="icon" (click)="resumeFileUpload(element)">
                </span>
                <span class="iconContainer" [ngClass]="isUploadInProgress(element) ? 'disableClass' : ''">
                  <img src="assets/images/download.png" id="icon" matTooltip="{{'Common.Download' | translate}}"
                    alt="{{'Common.Download' | translate}}" class="pointer" (click)="onDownloadClicked(element)">
                </span>
                <span class="iconContainer" *ngIf="showOptions()" [ngClass]="isUploadInProgress(element) ? 'disableClass' : ''">
                  <div [ngClass]="isCurrentUserAReaderOfAShare() ? 'not-allowed-icon' : ''">
                    <img src="assets/images/options.png" id="icon" matTooltip="{{'Images.Options' | translate}}"
                      alt="{{'Images.Options'|translate}}" class="optionsImg" mat-icon-button [matMenuTriggerFor]="menu"
                      [ngClass]="isCurrentUserAReaderOfAShare() ? 'disableIcon' : ''">
                  </div>
                </span>
                <mat-menu #menu="matMenu" xPosition="before">
                  <div class="optionWrapper">
                    <div class="optionMenu" (click)="openDialog(element)">
                      <img src="assets/images/trash.png" alt="{{'Images.DeleteFiles' | translate}}" class="icons">
                      <span>{{'MyShare.DeleteShare'| translate}}</span>
                    </div>
                  </div>
                </mat-menu>
              </span>
            </td>
          </ng-container>
  
          <!--progress bar -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="expandedDetail">
              <app-progress-bar *ngIf="getProgress(element)" [file]="element"></app-progress-bar>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="progress-bar-row"></tr>
        </table>
      </section>
    </div>
    <div class="error-wrapper">
      <app-error-message [displayError]="displayError"></app-error-message>
    </div>
  </div>
  
  <div class="dialogActionsContainer" *ngIf="isAddWorkflow()">
    <button [mat-dialog-close]="true" class="closeButton">
      {{'Files.Close_btn'| translate}}</button>
    <button cdkFocusInitial class="activeButton"
      [ngStyle]="{'visibility':isAddWorkflow() === true ? 'visible' : 'hidden' }" (click)="navigateToParticipantTab()">
      {{'Files.Add_participants_btn'| translate}}</button>
  </div>
</div>