<div class="outer-container">
    <div class="header">
        <div class="titleContainer">
            <div class="titleWrapper">
                <span class="titleLbl">{{'ActionLog.Title' | translate}}</span>
                <div class="searchContainer">
                    <input #search type="text" name="searchKey" [(ngModel)]="searchKey" placeholder="{{'ActionLog.Search_bar' | translate}}" autocomplete="off" maxlength="25"/>
                </div>
            </div>
            <button  [ngClass]="!shareContainsActionLogs() ? 'disableClass': ''"
            class="buttonStyle font-typo" (click)="onDownloadClicked()" >{{'Common.Download' | translate}}</button>
        </div>
        <section class="shares-container mat-elevation-z8" tabindex="0">
            <table mat-table [dataSource]="dataSource" matSort>

                <!-- Icon Column -->
                <ng-container matColumnDef="icon">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="iconContainer">
                            <img [src]="getIconSourcePath(element.eventType)">
                        </span>
                    </td>
                </ng-container>

                <!-- Who Column -->
                <ng-container matColumnDef="who">
                    <th mat-header-cell *matHeaderCellDef>{{'ActionLog.Who_column' | translate}} 
                        <app-filter [filterOption]="displayNameFilterOptions" (selectAllEvent)="selectAll()" (applyFilter)="filterActions($event)"></app-filter>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.displayName}}
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>{{'ActionLog.Action_column' | translate}}
                        <app-filter [filterOption]="eventDescriptionFilterOptions" (selectAllEvent)="selectAll()" (applyFilter)="filterActions($event)"></app-filter>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{getActionName(element.eventType)}}
                    </td>
                </ng-container>

                <!-- Context Column -->
                <ng-container matColumnDef="context">
                    <th mat-header-cell *matHeaderCellDef>{{'ActionLog.Context_column' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div textOverflow [textValue]="element.context">{{element.context}}</div>
                    </td>
                </ng-container>

                <!-- When Column -->
                <ng-container matColumnDef="timestamp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ActionLog.When_column' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.timestamp | date : dateFormat : undefined : locale }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </section>
    </div>
</div>
