import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileDialogModel } from 'src/app/types/dialog-model';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-download-file-dialog',
  templateUrl: './download-file-dialog.component.html',
  styleUrls: ['./download-file-dialog.component.scss']
})
export class DownloadFileDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileDialogModel) {

  }

  onCloseClick() {
    this.dialogRef.close();
  }

}
