import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { TokenExchangeService } from "../services/token-exchange.service";

@Injectable()
export class ExchangeTokenGuard implements CanActivate {
    constructor(private readonly tokenExchangeService: TokenExchangeService, private readonly router: Router){}

    canActivate( route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot)
        : Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
            if (!this.tokenExchangeService.getJwt()) {
                return false;
            } else {
                return true;
            }
        }
}
