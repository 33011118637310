<div class="outer-container">
    <div class="header">
        <div class="titleLable">{{'Notifications.Title' | translate}}</div>
        <div class="titleContainer">
            <div class="titleWrapper">
                <span class="registerEmailTitle">{{'Notifications.RegisterEmailTitle' | translate}}</span>
            </div>
            <button class="buttonStyle font-typo"
                (click)="goToEmailRegistration()">{{'Notifications.Buttons.RegisterButton' | translate}}</button>
        </div>
        <div class="grid-container">

            <!-- Share notification preferences -->
            <div *ngIf="getNotifications('Share').length > 0">
                <div class="titleLable">{{'Notifications.ShareNotifications.Title' | translate}}</div>
                <div class="notificationContainer" *ngFor="let option of getNotifications('Share')">
                    {{ getNotificationName(option.notificationType) }}
                    <app-slider class="slider" inactiveLable="{{'Common.No'| translate}}"
                        activeLable="{{'Common.Yes'| translate}}" [checked]="option.active"
                        (sliderChange)="onSliderChange($event, option)">
                    </app-slider>
                </div>
            </div>

            <!-- Files notification preferences -->
            <div *ngIf="getNotifications('Files').length > 0">
                <div class="titleLable">{{'Notifications.FilesNotifications.Title' | translate}}</div>
                <div class="notificationContainer" *ngFor="let option of getNotifications('Files')">
                    {{ getNotificationName(option.notificationType) }}
                    <app-slider class="slider" inactiveLable="{{'Common.No'| translate}}"
                        activeLable="{{'Common.Yes'| translate}}" [checked]="option.active"
                        (sliderChange)="onSliderChange($event, option)">
                    </app-slider>
                </div>
            </div>

            <!-- Share notification preferences -->
            <div *ngIf="getNotifications('Participants').length > 0">
                <div class="titleLable">{{'Notifications.ParticipantNotifications.Title' | translate}}</div>
                <div class="notificationContainer" *ngFor="let option of getNotifications('Participants')">
                    {{ getNotificationName(option.notificationType) }}
                    <app-slider class="slider" inactiveLable="{{'Common.No'| translate}}"
                        activeLable="{{'Common.Yes'| translate}}" [checked]="option.active"
                        (sliderChange)="onSliderChange($event, option)">
                    </app-slider>
                </div>
            </div>
        </div>
    </div>
    
    <div class="dialogActionsContainer" *ngIf="isAddWorkflow()">
        <button class="closeButton" (click)="previousButtonClick()">{{'Common.Previous_btn'| translate}}</button>
        <button cdkFocusInitial class="activeButton" (click)="shareFilesClick()">{{'Common.Share_file_btn'|
            translate}}</button>
    </div>
</div>