import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { IShare } from 'src/app/types/group-share.model';
import { environment } from 'src/environments/environment';
import { ShareDetailsComponent } from '../../share-details.component';
import { SharedDetailsService } from '../../shared-details.service';
import { IRoleBasedNotifications, NotificationFactory } from '../model/abstractNotification';
import { IPreferences, Notification, NotificationType } from '../model/notification.model';
import { HttpApiNotificationService } from '../services/http-api-notification-preferences.service';
import { TabManagerService, Tabs } from 'src/app/services/tab-manager.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends BaseAbstractComponent {

  public notificationActions: IPreferences[] = [];
  public shareDetails: IShare;

  private _notificationFactory: NotificationFactory = new NotificationFactory();
  private _abstractNotification: IRoleBasedNotifications;

  constructor(private _sharedDetailsService: SharedDetailsService,
    private _tabManagerService: TabManagerService,
    public dialogRef: MatDialogRef<ShareDetailsComponent>,
    private _httpApiService: HttpApiNotificationService) {
    super();
    this.setupSubscription();
  }

  public onSliderChange(value: boolean, option: IPreferences) {
    option.active = value;
    this.updateNotification(option);
  }

  public getNotificationName(type: Notification): string {
    return this.translate.instant('Notifications.Notification_action.' + type);
  }

  public goToEmailRegistration() {
    window.open(environment.emailRegistrationLink, "_blank")
  }

  public getNotifications(type: NotificationType | string): IPreferences[] {
    return this.notificationActions.filter(x => x.type === type);
  }

  public isAddWorkflow(): boolean {
    return this._sharedDetailsService.isAddWorkflow;
  }

  public previousButtonClick() {
    this._tabManagerService.updateTab(Tabs.SettingsTab);
  }

  public shareFilesClick() {
    this.dialogRef.close();
  }

  private setupSubscription() {
    const showDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: (x: IShare) => {
        this.shareDetails = x;
        this._abstractNotification = this._notificationFactory.getNotificationFactory(this.shareDetails.rightTypeOfCurrentUser);
        this.getNotificationPreferences();
      },
      error: (error: any) => { this.handleError(error); },
      complete: () => { }
    });
    this.subscriptions.push(showDetails);
  }

  private getNotificationPreferences() {
    this._httpApiService.getNotificationPreferences(this.shareDetails.id).subscribe({
      next: (result: IPreferences[]) => {
        this.notificationActions = this._abstractNotification.processNotifications(result);
      },
      error: (error: any) => { this.handleError(error); },
      complete: () => { },
    });
  }

  private updateNotification(model: IPreferences) {
    this._httpApiService.updateNotificationPreferences(this.shareDetails.id, [model]).subscribe({
      next: (result) => { },
      error: (error: any) => { this.handleError(error); },
      complete: () => { },
    });
  }
}
