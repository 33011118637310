import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { TabManagerService } from 'src/app/services/tab-manager.service';
import { ISystem } from 'src/app/types/participant.model';
import { SystemDataService } from '../system-data.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  providers: [SystemDataService]
})
export class AddContactComponent extends BaseAbstractComponent {

  selectedIndex: number;

  constructor(private _tabManagerService: TabManagerService,
    private _systemDataService: SystemDataService,
    public dialogRef: MatDialogRef<AddContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISystem) {
    super();
    this._systemDataService.initialiseData(data);
    this.setupSubscription();
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  private setupSubscription() {
    const tabSub = this._tabManagerService.onTabUpdated({
      next: (x: number) => { this.selectedIndex = x },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
    this.subscriptions.push(tabSub);
  }
}
