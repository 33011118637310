import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { IFile } from 'src/app/types/file-model';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent extends BaseAbstractComponent implements OnChanges {

  @Input() public file: IFile;
  width: number = 0;

  constructor(private _dialog: MatDialog) { 
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fileUploadProgress = this.file.progressCount$.subscribe({
      next: (value) => {
        this.width = value;
      }
    });
    this.subscriptions.push(fileUploadProgress);
  }
}
  