<div class="outer-container">
  <div class="title-container">
    <span class="title-wrapper">
      <img src="assets/images/contactsActive.png" class="contactIcon">
      {{'Contacts.Title' | translate}}
    </span>
  </div>
  <mat-tab-group [disableRipple]="true" animationDuration="0ms" [(selectedIndex)]="selectedIndex">
    <mat-tab label="{{'Contacts.Users' | translate}}">
        <app-user-tab></app-user-tab>
    </mat-tab>
    <mat-tab label="{{'Contacts.Systems' | translate}}">
        <app-system-tab></app-system-tab>
    </mat-tab>
    <mat-tab label="{{'Contacts.External' | translate}}">
      <app-external-contact-tab></app-external-contact-tab>
    </mat-tab>
</mat-tab-group>
