import { Injectable } from '@angular/core';
import { Observer, Subject, Subscription } from 'rxjs';
import { HttpApiRequestService } from '../http/http-api-request.service';
import { IFile } from '../types/file-model';
import { IShare } from '../types/group-share.model';
import { IParticipant } from '../types/participant.model';

@Injectable({
  providedIn: 'root'
})
export class SharedDetailsService {

  private loadSharedDetails$: Subject<IShare>;
  private loadSharedFiles$: Subject<IFile[]>;
  private shareNameChanged$: Subject<string>;
  private maxFileSizeBytes$: Subject<number>; // max file size value uploaded in the share
  fileToUpload: IFile[] = []; // Used only to store files from group share component
  isAddWorkflow: boolean;
  // api observables
  participants$: Subject<IParticipant[]>;
  externalContacts$: Subject<IParticipant[]>;
  private tabChanged$: Subject<number>;

  constructor(private _httpApiRequestService: HttpApiRequestService, 
    ) {
    this.loadSharedDetails$ = new Subject<IShare>();
    this.loadSharedFiles$ = new Subject<IFile[]>();
    this.participants$ = new Subject<IParticipant[]>();
    this.shareNameChanged$ = new Subject<string>();
    this.maxFileSizeBytes$ = new Subject<number>();
    this.externalContacts$ = new Subject<IParticipant[]>();
    this.tabChanged$ = new Subject<number>();

  }

  updateSharedDetails(details: IShare) {
    this.loadSharedDetails$.next(details);
  }

  onSharedDetailsUpdated(observe: Observer<IShare>): Subscription {
    return this.loadSharedDetails$.subscribe(observe);
  }

  updateSharedFiles(files: IFile[]) {
    this.loadSharedFiles$.next(files);
  }

  onSharedFilesUpdated(observe: Observer<IFile[]>): Subscription {
    return this.loadSharedFiles$.subscribe(observe);
  }

  loadParticipants(shareId: string) {
    this._httpApiRequestService.loadParticipants(shareId, '').subscribe({
      next: (result: IParticipant[]) =>{
        this.participants$.next(result);
      }, 
      error: (error) => {
        this.participants$.error(error);
      },
    });
  }

  loadExternalContacts(shareId:string) {
    this._httpApiRequestService.loadExternalContacts(shareId).subscribe({
      next: (result: IParticipant[]) =>{
        this.externalContacts$.next(result);
      }, 
      error: (error) => {
        this.externalContacts$.error(error);
      },
    });
  }

  onParticipantLoaded(obs: Observer<IParticipant[]>): Subscription {
    return this.participants$.subscribe(obs);
  }

  onExternalContactsLoaded(obs: Observer<IParticipant[]>): Subscription {
    return this.externalContacts$.subscribe(obs);
  }

  updateSharedName(val: string) {
    this.shareNameChanged$.next(val);
  }

  onShareNameUpdated(observe: Observer<string>): Subscription {
    return this.shareNameChanged$.subscribe(observe);
  }

  updateMaxFileSizeInBytes(val: number) {
    this.maxFileSizeBytes$.next(val);
  }

  onUpdatedMaxFileSize(observe: Observer<number>): Subscription {
    return this.maxFileSizeBytes$.subscribe(observe);
  }

  updateTabChnaged(val: number) {
    this.tabChanged$.next(val);
  }

  onTabChanged(observe: Observer<number>): Subscription {
    return this.tabChanged$.subscribe(observe);
  }
}
