<div class="wrapper" *ngIf="!isGuestUser">
    <img class="closeDialogButton" mat-icon-button (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
        alt="{{'Images.DialogCloseButton' | translate}}">
    <mat-tab-group [disableRipple]="true" animationDuration="0ms" [(selectedIndex)]="selectedIndex" (selectedTabChange)="selectedTabChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <label class="shareNameTab" #textElement textOverflow [textValue]="shareName">{{shareName}}</label>
            </ng-template>
            <app-shared-files></app-shared-files>
        </mat-tab>
        <mat-tab label="{{'MyShare.Participants' | translate}}">
            <app-shared-participants></app-shared-participants>
        </mat-tab>
        <mat-tab label="{{'MyShare.Settings_Tab' | translate}}" *ngIf="isCurrentUserAnOwnerOfAShareOrAdmin(shareModel)">
            <app-shared-settings></app-shared-settings>
        </mat-tab>
        <mat-tab label="{{'MyShare.Notifications_Tab' | translate}}" *ngIf="isCurrentUserAnOwnerOfAShareOrAdmin(shareModel)">
            <app-notifications></app-notifications>
        </mat-tab>
        <mat-tab label="{{'MyShare.ActionLog_Tab' | translate}}" *ngIf="isEditWorkflow()">
            <app-action-log></app-action-log>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="wrapper" *ngIf="isGuestUser">
    <img class="closeDialogButton" mat-icon-button (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
        alt="{{'Images.DialogCloseButton' | translate}}">
    <mat-tab-group [disableRipple]="true" animationDuration="0ms" [(selectedIndex)]="selectedIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                <label class="shareNameTab" #textElement textOverflow [textValue]="shareName">{{shareName}}</label>
            </ng-template>
            <app-shared-files></app-shared-files>
        </mat-tab>
    </mat-tab-group>
</div>