<div class="wrapper">
  <img class="closeDialogButton" (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
    alt="{{'Images.DialogCloseButton' | translate}}">
  <div class="outerContainer">
    <div class="titleContainer">{{data.title}}</div>
    <div class="contentContainer">
      <mat-label>{{data.content}}</mat-label>
    </div>
    <div mat-dialog-actions class="dialogActionsContainer">
      <button [mat-dialog-close]="true" cdkFocusInitial class="activeButton deleteButton">
        {{'Dialogs.DeleteButton'| translate}}</button>
    </div>
  </div>
</div>