import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogModel } from 'src/app/types/dialog-model';

@Component({
  selector: 'app-remove-file-type-restriction-dialog',
  templateUrl: './remove-file-type-restriction-dialog.component.html',
  styleUrls: ['./remove-file-type-restriction-dialog.component.scss']
})
export class RemoveFileTypeRestrictionDialogComponent {

  constructor(public dialogRef: MatDialogRef<RemoveFileTypeRestrictionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel) {
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
