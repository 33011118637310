import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { ContactOperation, ContactType, IContact, Participant } from 'src/app/types/participant.model';
import { AddUserContactComponent } from '../component/user/add-user/add-user.component';
import { MatTableDataSource } from '@angular/material/table';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { FilterOption } from 'src/app/common-ui/filter/model/filter-options.model';
import { UserLoginService } from 'src/app/services/login.service';
import { HttpContactApiService } from '../http-contact-api.service';
import { DialogModel } from 'src/app/types/dialog-model';
import { DeleteDialogComponent } from 'src/app/dialogs/delete-dialog/delete-dialog.component';
import { debounceTime, fromEvent, map } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'app-user-tab',
  templateUrl: './user-tab.component.html',
  styleUrls: ['./user-tab.component.scss']
})
export class UserTabComponent extends BaseAbstractComponent implements OnInit {

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('search') filterElementRef: ElementRef;

  dataSource = new MatTableDataSource<IContact>([]);
  statusFilterOptions: FilterOption[] = [];
  isLoadingData: boolean = false;
  pageNumber: number = 1;
  contentHeight: string = this.calculateHeight();
  searchKey: string = '';
  displayedColumns = ['icon', 'displayName', 'email', 'entityNameOfParticipant', 'status', 'options'];

  constructor(private _loginService: UserLoginService,
    private _httpContactApiService: HttpContactApiService) {
    super();
    this.setupSubscription();
   }

  ngOnInit() {
    this._httpContactApiService.refreshUsers();
  }

  ngAfterViewInit() {
    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this.pageNumber = 0;
          this._httpContactApiService.loadNextUsers(this.pageNumber, result.trim()).subscribe({
            next: (result: IContact[]) => {
              this.dataSource.data = result
              this.pageNumber = 1;
            },
            error: (error) => {
              this.handleError(error);
            },
            complete: () => { }
          });
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      }
      );
    }

  addParticipant() {
    let participantData = new Participant();
    participantData.participantOperation = ContactOperation.Add;
    participantData.active = true;
    participantData.participantType = ContactType.USER;
    const dialogRef = this.dialog.open(AddUserContactComponent, {
      panelClass: 'dialog-container',
      data: participantData
    });
    dialogRef.afterClosed().subscribe((result: IContact) => {
      if (result) {
        //different response from BE, refresh all contacts instead adding one to list
        this._httpContactApiService.refreshUsers();
      }
    });
  }

  openDeleteAdditionalContactDialog(contact: IContact): void {
    let data = new DialogModel();
    this.translate.get('Dialogs.DeleteUserDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.DeleteUserDialog.Message').subscribe((x: string) => data.content = x);
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '225px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteAdditionalContact(contact);
      }
    });
  }

  openDialog(contact: IContact): void {
    this.openDeleteAdditionalContactDialog(contact);
  }

  onResized(event: ResizedEvent) {
    this.contentHeight = this.calculateHeight();
  }

  public enableOptions(value: IContact): boolean {
    if (this._loginService.isLoggedInUserIsAdmin) {
      return value.additionalContact;
    } else {
      return false;
    }
  }

  public deleteAdditionalContact(contact: IContact) {
    this._httpContactApiService.deleteAdditionalContact(contact.participantId, contact.workRelationId).subscribe({
      next: (result) => {
        this.removeParticipant(contact.participantId, contact.workRelationId);
        this.refreshTableData(this.dataSource.data);
      },
      error: (error) => {
        this.handleError(error);
      },
      complete: () => { }
    });
  }
  
  public onScrollDown(event: any) {
    this.isLoadingData = true;
    this._httpContactApiService.loadNextUsers(this.pageNumber, this.searchKey.trim(), ).subscribe({
      next: (result: IContact[]) => {
        this.appendRecords(result);
        this.refreshTableData(this.dataSource.data);
        setTimeout(() => {this.isLoadingData = false;}, 500);
      },
      error: (error) => {
        this.handleError(error);
      },
      complete: () => { }
    });
  }

  public selectAll() {
    this.dataSource.filter = 'all';
  }

  public filterActions(filterOptions: FilterOption[]) {
    this.dataSource.filter = 'Filter';
  }

  private sortParticipants() {
    let records = this.dataSource.data;
    records.sort(this.compare);
    this.dataSource.data = records;
  }

  private compare(a: IContact, b: IContact) {
    if (CompareUtility.isDefinedAndNotNull(a.displayName) && CompareUtility.isDefinedAndNotNull(b.displayName)) {
      if (a.displayName.toLocaleLowerCase() < b.displayName.toLocaleLowerCase()) {
        return -1;
      }
      if (a.displayName.toLocaleLowerCase() > b.displayName.toLocaleLowerCase()) {
        return 1;
      }
    }
    return 0;
  }

  private getFilterPredicate() {
    return (row: IContact, filters: string) => {
      const matchFilter = [];

      const status = row.active.toString();

      const statusFilterOptions = this.statusFilterOptions.filter(x => x.activated);

      if (statusFilterOptions.length === 0) {
        return true;
      }

      let customFilterST = false;

      if (statusFilterOptions.length > 0) {
        customFilterST = statusFilterOptions.some(filter => filter.value.toString() === status)
        matchFilter.push(customFilterST);
      }
      return matchFilter.every(Boolean);
    };
  }

  private initializeFilterActions() {
    this.statusFilterOptions = this.getActiveFilterOptions();
  }

  private getActiveFilterOptions(): FilterOption[] {
    let options: FilterOption[] = [];
    options.push({ title: 'Contacts.Status.Active', value: true, activated: false, isTitleTranslated: false });
    options.push({ title: 'Contacts.Status.Disabled', value: false, activated: false, isTitleTranslated: false });
    return options;
  }

  private refreshTableData(result: IContact[]) {
    this.dataSource.data = result;
  }

  private removeParticipant(id: string, workRelationId: string) {
    const index = this.dataSource.data.findIndex(x => x.participantId === id && x.workRelationId === workRelationId);
    if (index > -1) { // only splice array when item is found
      this.dataSource.data.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  private appendRecords(result: IContact[]) {
    if (CompareUtility.isDefinedAndNotNull(result) && result.length > 0) {
      this.dataSource.data = this.dataSource.data.concat(result);
      this.pageNumber = this.pageNumber + 1;
    }
  }

  private calculateHeight() {
    return (parent.innerHeight - 350) + 'px';
  }

  private setupSubscription() {
    const refreshSharesSubscription = this._httpContactApiService.onUsersRefreshed({
      next: (result) => {
        this.dataSource.data = result;
        this.dataSource.sort = this.sort;
        this.searchKey = '';
        this.pageNumber = 1;
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.initializeFilterActions()
        this.refreshTableData(this.dataSource.data);
      },
      error: err => {
        this.handleError(err);
      },
      complete() { },
    });
    this.subscriptions.push(refreshSharesSubscription)
  }

  public isLoggedInUserIsAdmin() {
    return this._loginService.isLoggedInUserIsAdmin;
  }
}
