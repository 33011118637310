import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: { dateInput: 'input' }
};

@Injectable({
  providedIn: 'root'
})
export class PickDateAdapterService extends NativeDateAdapter {

  constructor(private translate: TranslateService) {
    super('');
  }

  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date, this.translate.instant('DateFormat'), this.locale);
    } else {
      return formatDate(date, 'MMMM yyyy', this.locale);
    }
  }
}
