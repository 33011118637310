<div class="wrapper">
    <img class="closeDialogButton" (click)="onCloseClick()" src="assets/images/closeDialogButton.png"
      alt="{{'Images.DialogCloseButton' | translate}}">
    <div class="outerContainer">
      <div class="titleContainer">{{data.title}}</div>
      <div class="contentContainer">
        <mat-label>{{data.content}}: </mat-label>
      </div>
      <div class="details-wrapper">
          <p class="user text">{{'Common.User' | translate}}: {{data.user}}</p>
          <p class="application text">{{'Dialogs.ErrorDialog.Application' | translate}}: {{data.application}}</p>
          <p class="code text">{{'Dialogs.ErrorDialog.ErrorCode' | translate}}: {{data.code}}</p>
          <p class="time text">{{'Dialogs.ErrorDialog.Time' | translate}}: {{data.time}}</p>
      </div>
      <div mat-dialog-actions class="dialogActionsContainer">
        <button (click)="refresh()" cdkFocusInitial class="closeButton dialogButtons">
          {{'Dialogs.ErrorDialog.Refresh'| translate}}</button>
        <button (click)="logout()" cdkFocusInitial class="activeButton dialogButtons">
          {{'Dialogs.ErrorDialog.Logout'| translate}}</button>
      </div>
    </div>
  </div>