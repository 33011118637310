import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileDialogModel } from 'src/app/types/dialog-model';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './sign-out-dialog.component.html',
  styleUrls: ['./sign-out-dialog.component.scss']
})
export class SignOutDialogComponent {

  constructor(public dialogRef: MatDialogRef<SignOutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileDialogModel) {
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
