import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject, debounceTime, fromEvent, map } from 'rxjs';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { HttpProcessorService } from 'src/app/http/http-processor.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { FileSizeManagerService } from 'src/app/services/file-size-manager.service';
import { TokenExchangeService } from 'src/app/services/token-exchange.service';
import { IFile } from 'src/app/types/file-model';
import { IShare, ShareType } from 'src/app/types/group-share.model';
import { Contact, ContactOperation, ContactType, IExternalContact, Role } from 'src/app/types/participant.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { CreateShareRequest, UpdateShareWithParticipantsRequest } from 'src/app/http/request-urls/shareAndFileRequestUrl';
import { FileFactoryService } from 'src/app/services/FileOperationFactory/file-factory.service';
import { TransferService } from '../transfer/service/transfer.service';
import { UserLoginService } from 'src/app/services/login.service';
import { AddPersonDialogComponent } from 'src/app/dialogs/add-person/add-person.component';
import { AddPersonDialogModel } from 'src/app/types/dialog-model';
import { IPreferences } from 'src/app/share-details/notifications/model/notification.model';
import { HttpApiNotificationService } from 'src/app/share-details/notifications/services/http-api-notification-preferences.service';

@Component({
  selector: 'app-quick-share',
  templateUrl: './quick-share.component.html',
  styleUrls: ['./quick-share.component.scss'],
  providers: [TransferService]
})

export class QuickShareComponent extends BaseAbstractComponent implements AfterViewInit {

  @ViewChild(MatMenuTrigger) participantsMenu: MatMenuTrigger;
  @ViewChild('search') elementRef: ElementRef;
  @ViewChild('searchField') searchField: any;
  private _fileFactoryService: FileFactoryService = new FileFactoryService();

  public files: IFile[] = [];
  public uploadFiles$: Subject<IFile[]>;
  searchedParticipants: Contact[] = [];
  searchResult: Contact[] = [];
  quickShareModel: IShare;
  participantName: string;
  participant: Contact;
  width: number = parent.innerWidth;
  displayedColumns = ['searchIcon', 'searchName', 'searchEmailAddress', 'searchOrganization'];
  uploadingFlag: boolean = false;
  public noResultFound: boolean = false;
  participantSelected: boolean = false;

  constructor(private httpProcessorService: HttpProcessorService,
    private _httpApiRequestService: HttpApiRequestService,
    private _tokenExchangeService: TokenExchangeService,
    private dateTimeService: DateTimeService,
    private _fileSizeManagerService: FileSizeManagerService,
    private _httpApiNotificationService: HttpApiNotificationService,
    private _loginService: UserLoginService,
    private _transferService: TransferService) {
    super();
    this.uploadFiles$ = new Subject<IFile[]>();
  }

  ngAfterViewInit() {
    this.setupSubscription();
    this.participantsMenu.menu.overlayPanelClass = 'share-with-panel';

    const keyup$ = fromEvent(this.elementRef.nativeElement, 'keyup');
    // wait .25s between keyups to emit current value
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(250)
      )
      .subscribe({
        next: (result: string) => {
          if (result.length > 2) {
            this.searchedParticipants = [];
            this.searchParticipants(result);
          } else {
            this.searchedParticipants = [];
            this.searchResult = [];
            this.participantsMenu.closeMenu();
          }
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      });
  }

  public isFileSelected(): boolean {
    return this.files.length > 0;
  }

  public validateFiles(): boolean {
    return this._fileSizeManagerService.isFileSizeLessThanGB(this.getSizeUploaded()) && this.isFileSelected();
  }

  private getSizeUploaded() {
    let totalSize: number = 0;
    this.files.forEach((x) => {
      totalSize = totalSize + x.size;
    });
    return totalSize;
  }

  private setupSubscription() {
    const uploadFinished = this._fileFactoryService.onUploadFinish({
      next: (status: boolean) => {
        if (this.participant.externalContact) {
          this.loadShare(this.quickShareModel);
          this.uploadingFlag = false;
          this.files = [];
          this._transferService.clearTransfer();
          this._httpApiRequestService.refreshMyShares();
        } else {
          let request = new UpdateShareWithParticipantsRequest(this.quickShareModel.id, this.participant.participantId, this.participant.entityNameOfParticipant, this.participant.entityIdOfParticipant, this.participant.additionalContact, this.participant.workRelationId, this._tokenExchangeService)
          this.httpProcessorService.handleRequest(request).subscribe({
            next: (result) => {
              this.loadShare(this.quickShareModel);
              this.uploadingFlag = false;
              this.files = [];
              this._transferService.clearTransfer();
              this._httpApiRequestService.refreshMyShares();
            },
            error: (error) => { this.handleError(error); },
            complete: () => { }
          })
        }
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(uploadFinished);

    const uploadInitiated = this._fileFactoryService.onUploadInitiated({
      next: (files: IFile[]) => {
        this.files = files;
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(uploadInitiated);

    const transferSubscription = this._transferService.onFilesSelected({
      next: (files: IFile[]) => {
        this.files = files;
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(transferSubscription);
  }

  public shareFiles() {
    this.uploadingFlag = true;
    let userData = this._tokenExchangeService.getStoredUserData();
    let nameString: string = userData.given_name;
    let model = {
      "shareName": nameString.concat(' ', this.translate.instant('Common.To'), ' ', this.participant.displayName),
      "expirationDate": this.dateTimeService.getExpirationDate(),
      "shareType": this.participant.externalContact ? ShareType.P2P : ShareType.QUICK
    };
    let request = new CreateShareRequest(model, this._tokenExchangeService);
    this.httpProcessorService.handleRequest(request).subscribe({
      next: (share: IShare) => {
        this.quickShareModel = share;
        if (this.participant.externalContact) {
          this._httpApiRequestService.sendInvite(share.id, this.participant.participantId, this._loginService.userName).subscribe({
            next: (result) => { }
          });
          this._httpApiRequestService.sendAddExternalContactLog(this.quickShareModel.id, this.participant.displayName, this.participant.participantId);
        } else {
          this._httpApiNotificationService.updateDefaultNotificationPreferences(share.id, this.participant.participantId, Role.READER, this.participant.workRelationId)
        }
        this.createDefaultNotifications(share);
        this.uploadFiles();
        this._httpApiRequestService.refreshMyShares();
      },
      error: (error) => {
        this.resetQuickModel();
        this.uploadingFlag = false;
        this.handleError(error);
      },
      complete: () => { }
    });
  }

  private createDefaultNotifications(share: IShare) {
    this._httpApiNotificationService.updateDefaultNotificationPreferences(share.id, this._loginService.getUserId(), share.rightTypeOfCurrentUser, this._loginService.getWorkRelationId()).subscribe({
      next: (result: IPreferences[]) => { },
      error: (error: any) => { this.handleError(error); },
      complete: () => { },
    });
  }

  addParticipant(participant: Contact) {
    this.participant = participant;
    this.participantName = this.participant.displayName;
    this.searchedParticipants = [];
    this.participantSelected = true;
  }

  nameClicked(e: Event) {
    this.participantsMenu.closeMenu();
  }

  searchParticipants(field: string) {
    this._httpApiRequestService.searchParticipants(this._loginService.entityId, field.trim()).subscribe({
      next: (result: Contact[]) => {
        if (result.length > 20) {
          result.forEach(element => {
            if (this.searchResult.length < 20) {
              this.searchResult.push(element);
            }
          });
        } else if (result.length > 0) {
          result.forEach(x => {
            let contact = x;
            x.externalContact = false;
            this.searchResult.push(contact);
          });
        }
        this.searchExternalContacts(field);
      }, error: (error) => { this.handleError(error); }
    });
  }

  searchExternalContacts(field: string) {
    this._httpApiRequestService.searchExternalContacts(0, field.trim(), this._loginService.entityId).subscribe({
      next: (result: IExternalContact[]) => {
        result.forEach(x => {
          let contact = new Contact();
          contact.displayName = x.name;
          contact.type = ContactType.USER;
          contact.email = x.emailAddress;
          contact.participantId = x.externalContactId;
          let external;
          this.translate.get('Common.External').subscribe(x => external = x)
          contact.entityNameOfParticipant = external;
          contact.externalContact = true;
          this.searchResult.push(contact);
        });
        this.refreshSearchTable();
        this.participantsMenu.openMenu();
      }, error: (error) => { this.handleError(error); }
    })
  }

  private refreshSearchTable() {
    this.searchedParticipants = [];
    this.searchedParticipants = this.searchResult;
    this.noResultFound = this.searchedParticipants.length === 0;
    if (this.noResultFound) {
      this.width = this.searchField._elementRef.nativeElement.offsetWidth - 30;
    }
    this.searchResult = [];
  }

  public isNameValid() {
    if (this.uploadingFlag) {
      return false;
    }
    return this.participant ? CompareUtility.isNotEmpty(this.participant.participantId) && this.validateFiles() : false;
  }

  private loadShare(share: IShare) {
    this._httpApiRequestService.refreshAllShares(this._loginService.entityId);
    this.uploadingFlag = false;
    this.resetQuickModel();
  }

  private resetQuickModel() {
    this.resetParticipant();
    this.files = [];
    this.quickShareModel = null;
  }

  resetParticipant() {
    this.participantSelected = false;
    this.participant = null;
    this.participantName = null;
  }

  uploadFiles() {
    this._fileFactoryService.initiateUpload(this.files, this.quickShareModel.id);
  }

  addPerson() {
    this.participantName = null;
    let data = new AddPersonDialogModel();
    data.operation = ContactOperation.Add;
    this.translate.get('Dialogs.AddPersonDialog.Title').subscribe((x: string) => data.title = x);
    const dialogRef = this.dialog.open(AddPersonDialogComponent, {
      panelClass: 'dialog-container',
      height: '400px',
      width: '450px',
      data: data
    });
    dialogRef.afterClosed().subscribe((result: Contact) => {
      if (result) {
        this.participant = result;
        this.participantName = result.displayName;
        this.participantSelected = true;
      }
    });
  }
}
