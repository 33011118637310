import { Injectable } from '@angular/core';
import { TokenExchangeService } from './token-exchange.service';
import { Observable, concatMap, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CompareUtility } from '../utilities/compare.utility';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {

  constructor(private _tokenExchangeService: TokenExchangeService,
    public datePipe: DatePipe,
    private oidcSecurityService: OidcSecurityService) { }

  public isAccessTokenExpired(): boolean {
    return Math.floor(new Date(Date.now() + 60000).getTime() / 1000) >= Number(this._tokenExchangeService.getAccessTokenExpiration());
  }

  public isRefreshTokenExpired(): Observable<boolean> {
    return this.oidcSecurityService.getRefreshToken().pipe(
      concatMap((token: string) => {
        return of(!CompareUtility.isDefinedAndNotNull(token));
      })
    );
  }

  public getExpirationDate() {
    const exp = Number(this._tokenExchangeService.getAccessTokenExpiration()) * 1000;
    const expDate = this.datePipe.transform(new Date(exp), 'long');
    console.log('Access token validity: ' + expDate);
  }
}
