export enum NotificationType {
    Share = 'Share',
    Participants = 'Participants',
    Files = 'Files'
}

export enum Notification {
    // SHARE
    SHARE_EXPIRED_ALERT = 'SHARE_EXPIRED_ALERT',
    SHARE_EXPIRATION_REMINDER_ALERT = 'SHARE_EXPIRATION_REMINDER_ALERT',
    SHARE_DELETED_ALERT = 'SHARE_DELETED_ALERT',

    // FILE
    DOWNLOAD_FILE_ALERT = 'DOWNLOAD_FILE_ALERT',
    UPLOAD_FILE_ALERT = 'UPLOAD_FILE_ALERT',

    // PARTICIPANTS
    ADD_RIGHT_ALERT = 'ADD_RIGHT_ALERT',
    CHANGE_RIGHT_ALERT = 'CHANGE_RIGHT_ALERT',
}

export interface IPreferences {
    id?: string;
    shareId?: string;
    notificationType?: Notification;
    active?: boolean;
    userId?: string;
    workRelationId?: string;
    updateDateTime?: string;
    title?: string;

    // UI variables
    isVisible?: boolean; // Handle by user role
    type?: NotificationType;
}

export interface IRoleNotification {
    name: Notification,
    type: NotificationType,
    defaultValue: boolean
}
